import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueQriously from 'vue-qriously'
import Vant from 'vant'
import 'vant/lib/index.css'
import wx from 'weixin-js-sdk'
import VCharts from 'v-charts'

Vue.use(VCharts)

global.wx = wx

Vue.config.productionTip = false
import Vuelazyload from 'vue-lazyload'

Vue.use(Vuelazyload, {

  loading: require('./assets/loading.gif')

})
// 引入vue-amap
import VueAMap from 'vue-amap';
Vue.use(VueAMap);
// 初始化vue-amap
VueAMap.initAMapApiLoader({
  // 高德的key
  key: 'a8f5450a3686b08cae8348c5de32a6b5',
  // 插件集合
  plugin: ['AMap.Autocomplete', 'AMap.Geolocation', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor'],
  // 高德 sdk 版本，默认为 1.4.4
  v: '1.4.4'
});
import VueTouch from 'vue-touch'
Vue.use(VueTouch, {name: 'v-touch'})

Vue.use(Vant)
Vue.use(VueQriously)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
