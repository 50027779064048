<template>
<div class="mall">
    <van-sticky>
        <van-nav-bar title="订单支付" left-text="乐乐主页" left-arrow @click-left="goBack" />
    </van-sticky>
    <template v-if="hasLoad">
        <van-cell icon="passed">
            <template slot="title">
                <span class="custom-title">订单号</span>
            </template>
            <template slot="right-icon">
                {{orderData.order_no}}
            </template>
        </van-cell>
        <van-cell icon="clock-o">
            <template slot="title">
                <span class="custom-title">创建时间</span>
            </template>
            <template slot="right-icon">
                {{orderData.create_time}}
            </template>
        </van-cell>
        <van-cell icon="logistics">
            <template slot="title">
                <span class="custom-title">配送方式</span>
            </template>
            <template slot="right-icon">
                现场取货
            </template>
        </van-cell>
        <van-cell icon="diamond-o">
            <template slot="title">
                <span class="custom-title">订单状态</span>
            </template>
            <template slot="right-icon">
                {{orderData.status_txt}}
            </template>
        </van-cell>
        <van-cell icon="diamond-o">
            <template slot="title">
                <span class="custom-title">支付方式</span>
            </template>
            <template slot="right-icon">
                {{orderData.order_type == 1?'微信支付':'纸币支付'}}
            </template>
        </van-cell>
        <van-divider content-position="left">商品信息</van-divider>
        <van-card class="list" v-for="item in saveData.list" :num="item.num" :price="item.price" :desc="item.sku_name" :title="item.goods_title" :thumb="item.path">
            <div slot="footer">
                小计 ￥{{item.num*item.price}}
            </div>
        </van-card>
        <van-cell icon="gold-coin-o">
            <template slot="title">
                <span class="custom-title">积分抵扣</span>
            </template>
            <template slot="right-icon">
                抵扣{{orderData.score/100}}元
            </template>
            <template slot="value">
                aa
            </template>
        </van-cell>

        <van-cell icon="gold-coin-o">
            <template slot="title">
                <span class="custom-title">金币抵扣</span>
            </template>
            <template slot="right-icon">
                抵扣{{orderData.coin}}元
            </template>
        </van-cell>
        <van-cell-group style="margin-top:10px;">
            <van-field v-model="saveData.mark" rows="1" autosize label="备注" type="textarea" :disabled='true' placeholder="暂无" />
        </van-cell-group>
        <van-submit-bar v-if="orderData.status==1" :price="orderData.pay_show" button-text="立即支付" @submit="onSubmit" />
        <van-submit-bar v-else :price="orderData.pay_show" disabled :button-text="orderData.status_txt" />
    </template>
</div>
</template>

<script>
import * as ajax from "@/api/common"
import util from '@/libs/util'
import wx from 'weixin-js-sdk';
export default {
    name: 'OffLineOrderPage',
    data() {
        return {
            user: 0,
            checked: false,
            hasLoad: false,
            use_score: false,
            in_use_score: 0,
            in_use_score_money: 0,
            use_coin: false,
            in_use_coin: 0,
            in_use_coin_money: 0,
            order_no: '',
            order_id: 0,
            cansubmit: true,
            saveData: {
                list: [],
                total: 0,
                points_id: 0,
                score: 0,
                coin: 0,
                discounts: 0,
                pay: 0,
                mark: '',
            },
            orderData: {},
            scoreInfo: {},
            userInfo: null,
        }
    },
    watch: {
        use_score(val) {
            this.checkUse()
        },
        use_coin(val) {
            this.checkUse()
        },
        post_way(val) {
            this.saveData.post_way = val
            this.checkUse()
        }
    },
    mounted() {
        this.order_no = this.$route.params.order_no ? this.$route.params.order_no : false

        this.getUserInfoF5()

        console.log("ffff", this.user)
        if (!this.order_no) {
            this.$toast('缺少订单号')
            return
        }
        this.getOrderInfo()
    },
    methods: {
        getUserInfoF5() {
            ajax.getUserInfo({
                showLoading: false
            }).then(async res => {
                util.cookies.set('uuid', res.id)
                util.cookies.set('token', res.token)
                this.$store.dispatch('lele/user/setUserInfo', res)
                this.userInfo = res
            })
        },
        getUserInfo() {
            let info = this.$store.dispatch('lele/user/load').then(res => {
                this.userInfo = res
            }).catch(e => {
                this.userInfo = false
            })
        },
        getOrderInfo() {
            ajax.get('/OffLineOrder/getOrderDetail', {
                order_no: this.order_no
            }).then(res => {
                this.orderData = res
                this.saveData.list = res.goods_info
                this.saveData.mark = res.mark
                this.saveData.seckill_id = 0
                this.saveData.discounts = 0
                this.saveData.total = res.total * 100
                this.saveData.pay = 0
                this.getScoreInfo()
                this.hasLoad = true
            }).catch(err => {
                // this.toPay()
                this.$toast(err.msg)
            })
        },
        checkUse() {
            //测试 账户总积分为10000
            this.scoreInfo.score = 10000
            //计算可使用积分的最大上限
            this.in_use_score = 0
            //不同配送方式 可使用的积分比例不同
            let per = 5
            //暂时以固定比例，计算当前订单可用积分数量
            //建议后端返回可用的积分数量
            let can_use_score = parseInt(this.saveData.total * per / 100)
            //判断最终可用多少积分
            let apply_score = this.scoreInfo.score >= can_use_score ? can_use_score : this.scoreInfo.score

            if (this.use_score) {
                this.in_use_score = apply_score
            }
            this.in_use_coin = 0
            if (this.use_coin) {
                if (!this.use_score && apply_score > 0) {
                    this.in_use_coin = this.scoreInfo.max_use.max_use_coin + apply_score / 100
                    if (this.in_use_coin > this.scoreInfo.coin) {
                        this.in_use_coin = this.scoreInfo.coin
                    }
                    var coin = (this.saveData.total - this.in_use_score) / 100
                    this.in_use_coin = coin > this.scoreInfo.max_use.max_use_coin ? this.scoreInfo.max_use.max_use_coin : coin
                } else {
                    var coin = (this.saveData.total - this.in_use_score) / 100
                    this.in_use_coin = coin > this.scoreInfo.max_use.max_use_coin ? this.scoreInfo.max_use.max_use_coin : coin
                }
            }
            this.saveData.pay = this.saveData.total - this.in_use_score - this.in_use_coin * 100
        },
        getScoreInfo() {
            // ajax.get('/Score/getMyScore',{total:this.saveData.total}).then(async res => {
            //   this.scoreInfo=res
            //   this.checkUse()
            // })
        },
        goBack() {
            this.$router.push('/')
        },
        onSubmit() {

            this.toPay()
        },
        toPay() {
            var _this = this
            ajax.post('/Pay/offlinePay', {
                user_id: this.userInfo.id,
                order_no: this.order_no,
                pay_way: 1,
                showTips: true,
                model_name: 'OffLineOrder'
            }).then(res => {
                const Data = JSON.parse(res)
                this.wexinPay(Data, function () {
                    _this.$toast('支付成功')
                }, function (r) {
                    _this.$toast('支付失败，请重试')
                }, function (r) {
                    _this.$toast('您放弃了支付')
                })
            }).catch(e => {
                this.$toast(e.msg)
                return
            })
        },
        wexinPay(data, cb, errorCb, cancelCb) {
            var _this = this
            //获取后台传入的数据
            let appId = data.appId;
            let timestamp = data.timeStamp;
            let nonceStr = data.nonceStr;
            let signature = data.signature;
            let packages = data.package;
            let paySign = data.paySign;
            let signType = data.signType;
            //下面要发起微信支付了
            wx.config({
                debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                appId: appId, // 必填，公众号的唯一标识
                timestamp: timestamp, // 必填，生成签名的时间戳
                nonceStr: nonceStr, // 必填，生成签名的随机串
                signature: signature, // 必填，签名，见附录1
                jsApiList: ['chooseWXPay'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
            });
            wx.ready(function () {
                wx.chooseWXPay({
                    timestamp: timestamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
                    nonceStr: nonceStr, // 支付签名随机串，不长于 32 位
                    package: packages, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=***）
                    signType: signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
                    paySign: paySign, // 支付签名
                    success: function (res) {
                        // 支付成功后的回调函数
                        cb(res);
                        _this.getOrderInfo()
                    },
                    cancel: function (res) {
                        cancelCb(res)
                    },
                    fail: function (res) {
                        //失败回调函数
                        errorCb(res);
                        this.getOrderInfo()
                    }
                });
            });
            wx.error(function (res) {
                _this.$toast('调起支付失败')
                console.log(res)
            })
        },
    }
}
</script>

<style scoped>
.list {
    background: #fff;
}

.tips {
    background: #fff;
    padding: 10px 15px;
    color: #666;
    font-size: 12px;
}

.tips p {
    margin: 0;
}

.tips span {
    color: #ff0000;
    padding: 0 5px;
}

.van-submit-bar__text {
    text-align: left;
    padding: 0;
    padding-left: 12px;
}

.goods_title .price_info {}

.goods_title .price_info .price {
    color: #ff0000;
    font-size: 30px;
}

.goods_title .price_info small {
    font-size: 14px !important;
}

.goods_title .price_info .price_del {
    font-size: 14px;
    color: #999;
    text-decoration: line-through;
}

.goods_title .star {
    font-size: 20px;
    color: #999;
}

.goods_title .star_act {
    color: #ff0000;
}

.sc {
    font-size: 10px;
    color: #666;
    text-align: center;
    line-height: 10px;
    float: right;
    width: 50px;
}

.goods_title h1 {
    font-weight: 300;
    color: #000;
    font-size: 20px;
}

.goods_title p {
    padding: 0px;
    margin: 0px;
    color: #666;
    font-size: 14px;
    line-height: 20px;
}

.goods_content {}

.goods_content p {
    margin: 0px;
    padding: 0;
}

.goods_content img {
    width: 100%;
    margin: 0;
    padding: 0;
}
</style>
