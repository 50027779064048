<template>
  <div>
    <template v-if="!hasLoad">
      <p><center>请稍后</center></p>
    </template>
    <template v-else>
        <van-nav-bar
          :title="page_title"
          left-text="返回"
          left-arrow
          @click-left="goBack"
        />
        <template>

          <template v-if="step==1">
            <!-- 按提货码查询 -->
            <van-cell-group>
              <van-field
                v-model="search.keyword"
                autosize
                label="取件码"
                type="text"
                placeholder="输入取件码"
                show-word-limit
              />
            </van-cell-group>
            <div style="text-align:center;margin-top: 10px;">
              <van-button type="primary" size="large" @click="toSearch">查询订单</van-button>
            </div>
          </template>
          <template v-else>
            <van-cell-group>
            <van-cell title="配送单号" :value="Delivery.del_no"/>
            <van-cell title="订单号" :value="Delivery.order_no"/>
            <van-cell title="订单状态" :value="Delivery.r_status_txt"/>
            <van-cell title="物品名称" :value="Delivery.goods_title"/>
            <van-cell title="规格数量" :value="Delivery.sku_name+' * '+Delivery.num"/>
            <van-cell title="收货人" :value="Delivery.address.name"/>
            <van-cell title="联系电话" :value="Delivery.address.phone"/>
            <van-cell title="地址" :value="Delivery.address.address"/>
            </van-cell-group>
            <div style="text-align:center;margin-top: 10px;" v-if="Delivery.r_status==3">
              <van-button type="primary" round size="large" @click="putOut">取件出库</van-button>
            </div>
            <div style="text-align:center;padding:0 20px;margin-top:10px;">
              <van-button type="default" round size="large" @click="goBack">返 回</van-button>
            </div>
          </template>
        </template>
    </template>
  </div>
</template>

<script>
import * as ajax from "@/api/common"
export default {
  name: 'inOutPage',
  data() {
    return {
      step:1,
      hasLoad:false,
      loading:false,
      family:false,
      Delivery:false,
      act:1,
      page_title:'用户取件',
      search:{
        showTips:true,
        table:'DeliverySum',
        keyword:''
      },
    }
  },
  mounted () {
    // this.search.keyword = this.$route.query.del_no?this.$route.query.del_no:false
    // if(!this.search.keyword){
    //   this.$toast('缺少配送单号')
    //   return false
    // }
    // this.getDetail()
    this.step=1
    this.hasLoad = true
  },
  methods:{
    goBack(){
      this.$router.push('/my')
    },
    toSearch(){
      if(!this.search.keyword){
        this.$toast('请输入取件码')
        return false
      }
      this.search.showTips=true
      ajax.get('/Base/searchDeliveryByCode',this.search).then(async res => {
        this.step=2
        this.Delivery=res
        this.hasLoad=true
      }).catch(err=>{
        this.step=1

        this.$toast(err.msg)
        // let _this=this
        // setTimeout(()=>{
        //   _this.goBack()
        // },1500)
      })
    },
    putOut(){
      this.$dialog.confirm({
        title: '确认',
        message: '确认用户已取件'
      }).then(() => {
        ajax.get('/Base/deliveryPutOut',{ms_code:this.Delivery.ms_code,table:'DeliverySum',showTips:true}).then(res=>{
          let _this = this
          setTimeout(()=>{
            _this.goBack()
          },1500)
        })
      }).catch(() => {
        this.$toast('放弃')
      });
    },
  }
}
</script>

<style scoped>
.user{background:#07c160;padding:20px 0;color:#fff;}
.van-panel__content{
  font-size:14px;
  line-height:24px;
  padding:15px;
}
.van-cell:not(:last-child)::after{left:0px!important;}
</style>
