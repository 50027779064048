<template>
  <div class="demo">
    <van-sticky>
    <van-nav-bar
      title="意见反馈"
      left-text="返回"
      right-text="我的反馈"
      left-arrow
      @click-left="goBack"
      @click-right="myFeedBack"
    />
    </van-sticky>
    <div style="margin:10px;">
    <van-cell-group>
      <van-field
          readonly
          clickable
          label="选择类别"
          v-model="save_data.typename"
          placeholder="请选择"
          right-icon="arrow"
          @click="showType = true"
        />
      <van-popup v-model="showType" position="bottom">
          <van-picker
            show-toolbar
            :columns="types"
            value-key="typename"
            @cancel="showType = false"
            @confirm="onTypeConfirm"
          />
        </van-popup>
      <van-field
        v-model="save_data.phone"
        label="联系电话"
        type="text"
        placeholder="建议填写"
      />
      <van-field
        v-if="showNeed"
        v-model="save_data.relation_id"
        label="订单号"
        type="text"
        placeholder="填写一个订单号"
      />
      <van-field
        v-model="save_data.content"
        rows="2"
        autosize
        label="反馈内容"
        type="textarea"
        maxlength="200"
        placeholder="反馈内容"
        show-word-limit
      />
      <van-field
        readonly
        label="相关图片"
        :placeholder="'最多上传'+maxCount+'张'"
      />
      <div class="upimg">
        <div class="img_list" v-for="(img,idx) in this.save_data.imgs">
          <van-image
            class="imgs"
            fit="contain"
            :src="img.url"
          />
          <van-icon class="delimg" @click="delImg(idx)" name="cross" />
        </div>
        <van-uploader :after-read="afterRead" v-model="files" v-if="save_data.imgs.length<maxCount" :preview-image="false" :before-read="beforeRead"/>
        <div style="clear: both;height: 1px;"></div>
      </div>
    </van-cell-group>

    <div style="text-align:center;margin-top: 10px;">
      <van-button type="primary" size="large" @click="onSubmit">提交反馈</van-button>
    </div>
    </div>
  </div>
</template>

<script>
import * as ajax from "@/api/common"
export default {
  name: 'backPage',
  data(){
    return {
      save_data:{
        content:'',
        imgs:[],
        typeid:'',
        typename:'',
        relation_id:'',
        phone:'',
      },
      showType:false,
      types:[],
      isLoading:false,
      files:[],
      maxCount:3,
      userInfo:false,
      showNeed:false,
    }
  },
  mounted () {
    this.getTypes()
    this.getUserInfo()
  },
  methods:{
    getUserInfo(){
      let info = this.$store.dispatch('lele/user/load').then(res=>{
        this.userInfo=res
        this.save_data.phone=res.phone
      }).catch(e=>{
        this.userInfo=false
      })
    },
    getTypes(){
      ajax.getList({
        table:'FeedbackType',
        scence:'mg_feedbacktype_list',
        page:1,
        page_size:100,
      }).then(res=>{
        this.types=res.data
      })
    },
    goBack(){
      this.$router.go(-1)
    },
    onTypeConfirm(value, index) {
      this.save_data.typeid = this.types[index].id
      this.save_data.typename = this.types[index].typename
      if(parseInt(this.types[index].need_relation)==1){
        this.save_data.relation_id=''
        this.showNeed=true
      }else{
        this.save_data.relation_id=''
        this.showNeed=false
      }
      this.showType=false
    },
    myFeedBack(){
      this.$router.push('/my/feedBack')
    },
    // 返回布尔值
    beforeRead(file) {
      if (file.type !== 'image/jpeg' || file.type !== 'image/png') {
        this.$toast('请上传 jpg/png 格式图片');
        return false;
      }
      return true;
    },
    // 返回 Promise
    asyncBeforeRead(file) {
      return new Promise((resolve, reject) => {
        if (file.type !== 'image/jpeg' || file.type !== 'image/png') {
          this.$toast('请上传 jpg/png 格式图片');
          reject();
        } else {
          resolve();
        }
      });
    },
    beforeRead(file){
      if(file.length>this.maxCount){
        this.$toast('最多上传'+this.maxCount+'张')
        return false
      }
      return true
    },
    afterRead(files) {
      if(files.length==undefined){
        files=[files]
      }
      let formData = new FormData()
      for(let f of files){
        formData.append('file_data', f.file)
        ajax.post('/Upload/toSave',formData).then(res=>{
            this.save_data.imgs.push({
              name:res.savename,
              url:res.path,
            })
        }).catch(e=>{
          console.log(e)
        })
      }
    },
    delImg(idx){
      this.save_data.imgs.splice(idx,1)
    },
    onSubmit(){
      this.save_data.table="FeedBack"
      this.save_data.validate="FeedBack"
      this.save_data.showTips=true
      //旧系统 添加预约订单
      ajax.post('/Fankui/apply',this.save_data).then(res=>{
      this.myFeedBack()
      }).catch(error=>{
        this.$toast(error.msg)
      })
    }
  }
}
</script>

<style scoped>
.van-panel__content{
  font-size:14px;
  line-height:24px;
  padding:15px;
}
.van-cell:not(:last-child)::after{left:0px!important;}
.upimg{padding:10px;}
.upimg .img_list{width:80px;height: 80px;float: left;position: relative;margin-right:6px;}
.upimg .img_list .imgs{border-radius: 5px;width:80px;height: 80px;}
.upimg .img_list .delimg{position: absolute;z-index: 2;right:5px;top:5px;font-size: 16px;}
</style>
