<template>
<div class="demo">
  <van-nav-bar
    :title="page_title"
    left-text="返回"
    left-arrow
    sticky
    @click-left="goBack"
  />
  <template v-if="hasLoad">
    <template v-if="vend_id>0">
      <van-pull-refresh v-model="isLoading" @refresh="getDetail">
        <van-sticky>
          <van-notice-bar
            v-if="showInfo"
            :text="info.mark"
            left-icon="location-o"
          />
        </van-sticky>
          <van-list
            v-model="loading"
            :finished="finished"
            :finished-text="finished_txt"
          >
              <van-card
              class="goodsList"
              v-for="(item,idx) in info.goods_list"
              :thumb="item.path"
            > 
              <div slot="title"><span style="font-size: 1rem">{{item.goods_title}}</span></div>
              <div slot="tag">
                <van-tag mark type="danger" v-if="item.status==1">货号 {{item.id}}</van-tag>
              </div>
              <div slot="tags" style="font-size: 14px;">
                库存 <span style="color: #ff0000;">{{item.storage}}</span> 件
              </div>
              <span slot="price">
                售价 ￥<span style="font-size: 1.2rem;color: #ff0000;">{{item.price}}</span>
              </span>
              <div slot="footer">
                <van-button type="danger" v-if="item.can_buy" size="mini" @click="toBuy(idx)">购买</van-button>
                <van-button type="default" v-else size="mini" @click="toBuy(idx)">已售罄</van-button>
              </div>
            </van-card>
          </van-list>
      </van-pull-refresh>

      <van-popup
          v-model="showBuy"
          round
          closeable
          position="bottom"
          v-if="info"
        >
        <p><center style="font-size:1.5rem;">确认支付方式</center></p>

        <van-cell icon="gold-coin-o">
          <template slot="title">
            <span class="custom-title">积分抵扣</span>
          </template>
          <template slot="right-icon">
            <van-switch size="18px" :disabled="scoreInfo.score==0" v-model="use_score" style="margin-left:10px;"/>
          </template>
          <template slot="value">
            aa
          </template>
        </van-cell>
        <div class="tips" v-if="use_score">
          <p>总积分<span>{{scoreInfo.score}}</span></p>
          <p>当前订单最多可使用<span>{{in_use_score}}</span>积分,抵扣<span>{{in_use_score/100}}</span>元</p>
        </div>

        <van-cell icon="gold-coin-o">
          <template slot="title">
            <span class="custom-title">金币抵扣</span>
          </template>
          <template slot="right-icon">
            <van-switch size="18px" :disabled="scoreInfo.coin==0" v-model="use_coin" style="margin-left:10px;"/>
          </template>
        </van-cell>
        <div class="tips" v-if="use_coin">
          <p>总金币<span>{{scoreInfo.coin}}</span></p>
          <p>当前订单最多可使用<span>{{in_use_coin}}</span>金币,抵扣<span>{{in_use_coin}}</span>元</p>
        </div>
        <van-cell icon="gold-coin-o">
          <template slot="title">
            <span class="custom-title">现金支付</span>
          </template>
          <template slot="right-icon">
            <span style="color: #ff0000">￥{{saveData.pay/100}}</span>
          </template>
        </van-cell>
        <div style="padding:15px;text-align: center;">
          <van-button type="danger" round size="large" @click="toBuyGo">确认支付</van-button>
        </div>
        </van-popup>

        <van-overlay :show="visShipmentTips" :z-index="1000">
          <div class="wrapper">
            <div class="block">
              <van-loading type="spinner" color="#fff" size="40" />
              请稍后，正在出货
            </div>
          </div>
        </van-overlay>
    </template>
    <template v-else>
      <div class="scans">
        <van-icon name="scan" @click="scanQRCode"/>
        <p>点击上访图标，对准箱体二维码扫码</p>
        <p @click="showVend=true">或者<span style="font-weight: 400;color: #ff6600;padding:0 4px;">点击这里</span>手动输入机器编码</p>
      </div>
      <van-popup v-model="showVend">
          <div style="padding: 15px;">
            <div style="color: #ff6600;line-height: 25px;text-align: center;">机器编号在机器上</div>
            <van-cell-group>
              <van-field v-model="tmp" placeholder="请填写机器编号" />
            </van-cell-group>
            <div style="margin: 16px;">
              <van-button round block type="info" size="mini" @click="submitVend">确定</van-button>
            </div>
          </div>
      </van-popup>
    </template>
  </template>
  <template v-else>
    <p><center>请稍后...</center></p>
  </template>
</div>
</template>

<script>
import * as ajax from "@/api/common"
import util from '@/libs/util'
export default {
  name: 'vendDetailPage',
  data(){
    return {
      vend_id:0,
      showInfo:true,
      info:false,
      hasLoad:false,
      loading:false,
      isLoading:false,
      page_title:'积分兑换',
      isLoading:false,
      showBuy:false,
      finished:true,
      finished_txt:'没有更多了',
      row:false,
      use_score:false,
      in_use_score:0,
      in_use_score_money:0,
      use_coin:false,
      in_use_coin:0,
      in_use_coin_money:0,
      cansubmit:true,
      saveData:{
        terminal_id:0,
        total:0,
        score:0,
        coin:0,
        pay:0,
      },
      scoreInfo:{
        score:0,
        coin:0
      },
      visShipmentTips:false,
      order_no:'',
      order_id:0,
      timer:false,
      max_check:5,
      tmp:'',
      showVend:false
    }
  },
  watch: {
    use_score(val){
      this.checkUse()
    },
    use_coin(val){
      this.checkUse()
    }
  },
  mounted () {
    this.vend_id=this.$route.params.vend_id?this.$route.params.vend_id:0
    if(!this.vend_id){
      this.hasLoad=true
      return
    }
    this.getDetail()
  },
  methods:{
    submitVend(){
      if(!this.tmp){
        this.$toast('请填写机器编号')
        return
      }
      this.vend_id=this.tmp
      this.getDetail()
    },
    goBack(){
      this.$router.push({path:'/mall/index'})
    },
    checkUse(){
      //计算可使用积分的最大上限
      this.in_use_score=0
      if(this.use_score){
        this.in_use_score=this.scoreInfo.max_use.max_use_score
      }
      this.in_use_coin=0
      if(this.use_coin){
        if(!this.use_score && this.scoreInfo.max_use.max_use_score>0){
          this.in_use_coin=this.scoreInfo.max_use.max_use_coin + this.scoreInfo.max_use.max_use_score/100
          if(this.in_use_coin>this.scoreInfo.coin){
            this.in_use_coin=this.scoreInfo.coin
          }
          var coin=(this.saveData.total - this.in_use_score)/100
          this.in_use_coin= coin>this.scoreInfo.max_use.max_use_coin?this.scoreInfo.max_use.max_use_coin:coin
        }else{
          var coin=(this.saveData.total - this.in_use_score)/100
          this.in_use_coin= coin>this.scoreInfo.max_use.max_use_coin?this.scoreInfo.max_use.max_use_coin:coin
        }
      }
      this.saveData.pay = this.saveData.total - this.in_use_score - this.in_use_coin*100
      this.saveData.score = this.in_use_score
      this.saveData.coin = this.in_use_coin
    },
    getScoreInfo(){
      ajax.get('/Score/getMyScore',{total:this.saveData.total}).then(async res => {
        this.scoreInfo=res
        this.checkUse()
      })
    },
    toBuy(idx){
      this.row = this.info.goods_list[idx]
      if(!this.row.can_buy){
        this.$toast('当前商品已售罄')
        return
      }
      this.saveData.terminal_id=this.info.id
      this.saveData.aisle=this.row.id
      this.saveData.total=this.row.price*100
      this.saveData.score=0
      this.saveData.coin=0
      this.saveData.pay=0
      this.showBuy=true
      this.getScoreInfo()
    },
    toBuyGo(idx){
      let data={...this.saveData}
      data.showTips=true
      ajax.post('/Vend/toBuy',{...data}).then(res=>{
        this.showBuy=false
        this.use_coin=this.use_score=false
        this.order_no=res.order_no
        this.order_id=res.order_id
        if(res.status==2){
          //无需再支付现金
          this.$toast(res.msg)
          //出货提示
          this.showShipmentTips()
        }else{
          //需要支付现金
          this.$dialog.confirm({
            title: '提示',
            message: '订单创建成功，是否立即付款'
          }).then(() => {
            this.toPay()
          }).catch(() => {
            this.$toast('您放弃了付款')
          })
        }
      }).catch(e=>{
        this.$toast(e.msg)
      })
    },
    showShipmentTips(){
      this.visShipmentTips=true
      let _this = this
      //发送出货指令
      ajax.post('/Vend/shipMent',{order_no:this.order_no}).then(res=>{
        // this.visShipmentTips=false
        //指令已发送，轮询出货状态
        this.$toast(res.msg)
        this.max_check=5
        this.timer = setInterval(function(){
          _this.checkStatus()
        },3000)
      }).catch(e=>{
        this.visShipmentTips=false
        //指令发送失败
        this.$dialog.confirm({
          title: '提示',
          message: e.msg,
          cancelButtonText:'我要退款',
          confirmButtonText:'再试一次',
        }).then(() => {
          //重新出货
          this.showShipmentTips()
        }).catch(() => {
          //选择退款
          this.cancleOrder()
        })
      })
    },
    cancleOrder(){
      this.visShipmentTips=false
      ajax.post('/Vend/cancleOrder',{order_no:this.order_no,showTips:true}).then(res=>{
        //指令已发送，轮询出货状态
      }).catch(e=>{
        //退款失败
        this.$toast(e.msg)
      })
    },
    checkStatus(){
      //指令已发送，轮询出货状态
      if(this.max_check<1){
        //不再轮询
        console.log('轮询次数达到上限')
        clearInterval(this.timer)
        this.visShipmentTips=false
        //检测到状态为失败
        this.$dialog.confirm({
          title: '提示',
          message: '经过多次尝试，出货失败，您可以选择如下操作',
          cancelButtonText:'我要退款',
          confirmButtonText:'再试一次',
        }).then(() => {
          //重新出货
          this.showShipmentTips()
        }).catch(() => {
          //选择退款
          this.cancleOrder()
        })
        return
      }else{
        console.log('检测='+this.max_check)
      }
      ajax.post('/Vend/checkShipMent',{order_no:this.order_no,showLoading:false}).then(res=>{
        console.log(res)
        //状态为出货中或出货成功
        if(res.status==1){
          //正在出货中
          this.max_check-=1
        }else if(res.status==2){
          clearInterval(this.timer)
          this.visShipmentTips=false
          //出货成功
          this.$dialog.confirm({
            title: '提示',
            message:res.msg,
            cancelButtonText:'不需要',
            confirmButtonText:'再看看',
          }).then(() => {
            this.getDetail()
          }).catch(() => {
            this.vend_id=0
            this.info=false
          })
        }
      }).catch(e=>{
        clearInterval(this.timer)
        //检测到状态为失败
        this.$dialog.confirm({
          title: '提示',
          message: e.msg,
          cancelButtonText:'我要退款',
          confirmButtonText:'再试一次',
        }).then(() => {
          //重新出货
          this.showShipmentTips()
        }).catch(() => {
          //选择退款
          this.cancleOrder()
        })
      })
    },
    isWeiXin(){ 
      return navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1
    },
    // 扫一扫
    scanQRCode() {
      let _this=this
      if(!this.isWeiXin()){
        this.$toast('请在微信客户端使用')
        return
      }
      const toast1 = this.$toast.loading({
        mask: true,
        message: '请稍后...'
      })
      wx.scanQRCode({
        needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
        scanType: ["qrCode","barCode"], // 可以指定扫二维码还是一维码，默认二者都有, "barCode"
        success:  res=> {
          toast1.clear()
          let tmp = res.resultStr.split('detail/')
          _this.$toast.success('扫码成功')
          _this.vend_id = tmp[1]
          alert(tmp[1])
          _this.getDetail()
        },
        fail: err=>{
          _this.$toast({message: err.errMsg , duration: 1500});
        }
      })
    },
    toPay(){
      var _this=this
      ajax.post('/Pay/toPay',{order_no:this.order_no,pay_way:1,showTips:true,model_name:'VendOrder'}).then(res=>{
          const Data = JSON.parse(res)
          this.wexinPay(Data,function(){
            _this.$toast('支付成功')
            _this.showShipmentTips()
          },function(r){
            _this.$toast('支付失败')
          },function(r){
            _this.$toast('您放弃了支付')
          })
      }).catch(e=>{
        this.$toast(e.msg)
        return
      })
    },
    wexinPay(data, cb, errorCb,cancelCb) {
      var _this=this
        //获取后台传入的数据
        let appId = data.appId;
        let timestamp = data.timeStamp;
        let nonceStr = data.nonceStr;
        let signature = data.signature;
        let packages = data.package;
        let paySign = data.paySign;
        let signType = data.signType;
        console.log('发起微信支付')
        //下面要发起微信支付了
        wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: appId, // 必填，公众号的唯一标识
            timestamp: timestamp, // 必填，生成签名的时间戳
            nonceStr: nonceStr, // 必填，生成签名的随机串
            signature: signature, // 必填，签名，见附录1
            jsApiList: ['chooseWXPay'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
        });
        wx.ready(function () {
          wx.chooseWXPay({
            timestamp: timestamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
            nonceStr: nonceStr, // 支付签名随机串，不长于 32 位
            package: packages, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=***）
            signType: signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
            paySign: paySign, // 支付签名
            success: function (res) {
                // 支付成功后的回调函数
                cb(res);
            },
            cancel: function (res) {
              cancelCb(res)
            },
            fail: function (res) {
                //失败回调函数
                errorCb(res);
            }
          });
        });
        wx.error(function (res) {
          _this.$toast('调起支付失败')
          console.log(res)
        })
    },
    getDetail(){
      this.isLoading=true
      this.loading=true
      ajax.getDetail({table:'Vend',id:this.vend_id,field:'id,mark,exclusive_encoding,terminal_id,config goods_list,status,run_status,status status_txt,run_status run_status_txt',showTips:true}).then(res=>{
        this.showBuy=false
        this.use_score=false
        this.use_coin=false
        this.isLoading=false
        this.loading=false
        this.info = {...res}
        this.hasLoad=true
        this.finished=true
      }).catch(e=>{
        this.vend_id=0
        this.showVend =false
        this.info = false
        this.loading=false
        this.hasLoad=true
        this.finished=true
        this.$toast(e.msg)
      })
    },
  }
}
</script>
<style scoped>
.scans{text-align: center;margin-top:50px; font-size: 14px!important;color: #333;}
.scans .van-icon-scan{font-size: 100px!important;}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.block {
  width: 120px;
  height: 120px;
  text-align: center;
  color: #fff;
}
.tips{padding:0 10px;font-size: 12px;}
.tips span{color: #ff0000;margin: 0 5px;}
.goodsList{
  background: #fff;
  margin-bottom: 10px;
}
.goods_title{
  font-size:14px;color:#000;display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.orderCon{padding:10px;}
.goods_title{font-size: 14px;color: #333;}
.goods_stitle{font-size: 12px;color: #666;margin:5px 0;}

.price{color:#ff0000;}
.price b{font-size:16px;font-weight:200;}
.price small{font-size:12px;color: #666;}

.price_del{color:#666;text-decoration: line-through;font-weight:200;font-size:12px;}
</style>
