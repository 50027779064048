<template>
  <div>
    
    <template v-if="!hasLoad">
      <p><center>请稍后</center></p>
    </template>
    <template v-else>
      <template v-if="family">
        <van-nav-bar
          title="我的家庭组"
          left-text="返回"
          left-arrow
          style="display:none;"
          @click-left="goBack"
        />
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <van-tabs sticky v-model="active">
          <van-tab title="家庭组信息">
            <van-cell-group>
              <van-cell title="小区名" :value="family.city_title?family.city_title:'--'" />
              <van-cell title="户主名" :value="family.name"/>
              <van-cell title="户主电话" :value="family.phone"/>
              <van-cell title="所在地址" :value="family.address"/>
              <van-cell title="创建时间" :value="family.create_time"/>
              <van-cell title="成员数量" :value="family.member_count+'人'" @click="active=1"/>
            </van-cell-group>
            <div style="text-align:center;padding:0 20px;margin-top:10px;"><van-button type="primary" round size="large" @click="showQr">邀请新成员</van-button></div>
            <div style="text-align:center;padding:0 20px;margin-top:10px;"><van-button type="default" round size="large" @click="quitOut">退出家庭组</van-button></div>
          </van-tab>
          <van-tab title="家庭成员">
            <van-list
              v-model="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="getUserInfo"
            >
              <van-panel v-for="member in list">
                <van-row>
                  <van-col span="6">
                    <van-image
                      round
                      width="4rem"
                      height="4rem"
                      :src="member.headimgurl"
                    />
                  </van-col>
                  <van-col span="16">
                    <van-icon name="user-o" /> {{member.name}}<br/>
                    <van-icon name="phone-o" /> {{member.phone}}<br/>
                    <van-icon name="clock-o" /> {{member.create_time}}
                  </van-col>
                </van-row>
              </van-panel>
            </van-list>
          </van-tab>
          <van-tab title="积分规则">
            <div class="rules">
              <div v-html="score_rules"></div>
            </div>
          </van-tab>
        </van-tabs>
        

        </van-pull-refresh>

        <van-popup v-model="showMyQrcode">
            <div style="text-align: center;padding:10px;">
              <qriously level="H" :value="qrCodeUrl" :size="220" />
              扫该二维码 加入家庭组
            </div>
        </van-popup>
      </template>

      <template v-else>
        <van-nav-bar
          title="查找家庭组"
          left-text="返回"
          left-arrow
          @click-left="goBack"
        />
        <van-cell-group>
          <van-field
            v-model="search.name"
            label="户主名"
            placeholder="请输入户主姓名"
          />
          <van-field
            v-model="search.phone"
            label="手机号"
            placeholder="请输入户主手机号"
          />
        </van-cell-group>
        <!-- 不存在家庭组 -->
        <div style="text-align:center;padding:0 20px;margin-top:10px;"><van-button type="primary" round size="large" @click="searchFamily">查 找</van-button></div>
        
        <van-panel v-for="item in list">
          <van-row>
            <van-col span="16">
              <van-icon name="hotel-o" /> {{item.city_title}}<br/>
              <van-icon name="user-o" /> {{item.name}}<br/>
              <van-icon name="phone-o" /> {{item.phone}}<br/>
              <van-icon name="location-o" /> {{item.address}}<br/>
              <van-icon name="clock-o" /> {{item.create_time}}
            </van-col>
            <van-col span="8">
              <van-button type="primary" width="100%" @click="applyTo(item)">加入</van-button>
            </van-col>
          </van-row>
        </van-panel>

      </template>
    </template>
  </div>
</template>

<script>
import * as ajax from "@/api/common"
import util from '@/libs/util'
export default {
  name: 'scoreListPage',
  data() {
    return {
      hasLoad:false,
      showMyQrcode:false,
      active:0,
      list:[],
      loading:false,
      finished:false,
      activeNames:1,
      userInfo:false,
      isLoading:false,
      family:false,
      score_rules:'',
      search:{
        name:'',
        phone:''
      },
      qrCodeUrl:''
    }
  },
  mounted () {
    this.getUserInfo()
  },
  methods:{
    goBack(){
      this.$router.push('/my')
    },
    showQr(){
      this.showMyQrcode=true
      let url = process.env.NODE_ENV === 'production' ? 'http://wx.lelehuanbao.com' : 'http://wx.demo.lelehuanbao.com'
      this.qrCodeUrl=url+'/my/family_qr?qd='+this.family.id
    },
    getUserInfo(){
      ajax.getUserInfo().then(async res => {
        util.cookies.set('uuid', res.id)
        util.cookies.set('token', res.token)
        this.$store.dispatch('lele/user/setUserInfo',res)
        this.family = res.family
        this.score_rules=res.score_rules
        this.list = res.family.member_list
        this.loading = false;
        this.finished = true;
        this.hasLoad=true;
      })
    },
    searchFamily(){
      this.search.showTips=true
      ajax.get('/Family/searchFamily',this.search).then(async res => {
        this.list=res
      }).catch(err=>{
        
      })
    },
    applyTo(family){
      this.$dialog.confirm({
        title: '确认',
        message: '确认加入【'+family.name+'】家庭组吗'
      }).then(() => {
        ajax.get('/Family/applyFamily',{f_id:family['id']}).then(res=>{
          this.getUserInfo()
        })
      }).catch(() => {
        this.$toast('放弃')
      });
    },
    quitOut(){
      this.$dialog.confirm({
        title: '确认',
        message: '确认退出【'+this.family.name+'】家庭组吗'
      }).then(() => {
        ajax.get('/Family/quitFamily',{f_id:this.family.id}).then(res=>{
          this.getUserInfo()
        }).catch(e=>{
          console.log(e)
        })
      })
    },
    onRefresh() {
      this.list=[]
      this.isLoading = false;
      this.loading = true;
      this.finished = false;
      this.getUserInfo()
      this.$toast('刷新成功')
    }
  }
}
</script>

<style scoped>
.user{background:#07c160;padding:20px 0;color:#fff;}
.van-panel__content{
  font-size:14px;
  line-height:24px;
  padding:15px;
}
.van-cell:not(:last-child)::after{left:0px!important;}
.rules{margin:10px;}
.rules p{text-indent: 2em!important;line-height: 1.5rem;padding:0px;margin:0px;color: #666;font-size: 14px!important;margin-block-start:0px!important;margin-block-end:0px!important;}
</style>
