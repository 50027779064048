<template>
  <div>
    <van-nav-bar
      title="商城订单"
      left-text="返回"
      left-arrow
      style="display:none"
      @click-left="goBack"
    />
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
      <van-tabs sticky v-model="active" @click="onRefresh">
        <van-tab :title="type.title" v-for="(type,idx) in status_arr">
          <van-list
            v-if="idx==active"
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <van-panel v-for="item in list" :title="item.order_no" :status="item.status_txt" style="margin-bottom:20px!important;font-size:12px;" @click="viewOrder(item)">
              <div><van-tag type="danger">{{item.pointsTitle}}</van-tag></div>
              <div class="orderCon">
                  <van-row class="goods" v-for='(gd,idx) in item.goods_info' v-if="idx<2">
                    <van-col span="4">
                      <van-image
                        width="3rem"
                        height="3rem"
                        fit="cover"
                        :src="gd.path"
                      />
                    </van-col>
                    <van-col span="16" offset="4">
                      <div class="goods_title">{{gd.goods_title}}</div>
                      <div>{{gd.sku_name}}*{{gd.num}}</div>
                    </van-col>
                  </van-row>
                  <div v-if="item.goods_info.length>2">更多</div>
              </div>
              <div style='background: #F8F8F8;font-size: 12px;' v-if='item.is_kuaidi'>{{item.kuaidi}}</div>
<!--               <div style="margin-top: 10px;" v-if='item.status == 3'><van-button type='primary'  @click.stop='confirmOrder(item)' size="small">确认收货</van-button></div> -->
              <div slot="footer">
                  创建时间 {{item.create_time}}<br/>
                  共件{{item.total_num}}商品 实付：<span style="color:#ff0000">￥{{item.pay}}</span>
                <van-button class="bt" size="small">详情</van-button>
              </div>
            </van-panel>
          </van-list>
        </van-tab>
      </van-tabs>
    </van-pull-refresh>
    <van-popup v-model="infoVis">
    <van-steps direction="vertical" :active="logAct">
      <van-step v-for="item in logInfo">
        【{{item.type_txt}}】{{item.content}}<br/>
        <span v-if="item.user_info">By {{item.user_info.name}}<br/></span>
        {{item.create_time}}
      </van-step>
    </van-steps>
    </van-popup>
    
  </div>
</template>

<script>
import * as ajax from "@/api/common"
import util from '@/libs/util'
export default {
  name: 'mallOrderPage',
  data() {
    return {
      filter:{
        status:-1,
      },
      page:{
        current_page:0,
        page_total:1,
        count:0,
        page_size:10,
      },
      list:[],
      logInfo:[],
      active:0,
      logAct:0,
      loading:false,
      finished:false,
      activeNames:1,
      userInfo:false,
      isLoading:false,
      infoVis:false,
      tmpArr:[],
      status_arr:[
      {
        status:0,
        title:'全部',
      },{
        status:0,
        title:'待付款',
      },{
        status:0,
        title:'待发货',
      },{
        status:0,
        title:'待收货',
      },{
        status:0,
        title:'已签收',
      }]
    }
  },
  mounted () {
    this.tmpArr=this.status_arr.filter((el, index) => {
      el.list=[]
      return el
    })
    this.active=this.$route.query.status?this.$route.query.status:-1
    this.onLoad()
  },
  methods:{
    goBack(){
      this.$router.go(-1)
    },
    viewOrder(row){
     this.$router.push({
      path:'/my/viewMallOrder',
      query:{
        id:row.id
      }
     })
    },
    confirmOrder(item){
      this.$dialog.confirm({
        title: '确认',
        message: '确认设置为收货？'
      }).then(() => {
        ajax.get('/Base/confirmShouhuo',{order_id:item.id,table:'Delivery',showTips:true}).then(res=>{
          let _this = this
          this.onRefresh()
        })
      }).catch(() => {
        this.$toast('放弃')
      });    
    },
    makeList(){
      this.list = this.tmpArr[this.active].list
    },
    onLoad(){
      this.filter.status=this.active
      this.loading = true
      this.page.current_page=parseInt(this.page.current_page) + 1
      ajax.getList({
        table:'MallOrder',
        scence:'mg_mallorder',
        page:this.page.current_page,
        filter:this.filter
      }).then(res=>{
        this.loading = false
        this.page.page_total = res.last_page
        this.page.current_page = res.current_page
        this.page.count = res.total
        for (var i = 0; i <res.data.length; i++) {
          this.tmpArr[this.active].list.push(res.data[i])
        }
        this.makeList()
        if(parseInt(res.last_page)<=parseInt(res.current_page)){
          this.finished = true;
        }
      })
    },
    onRefresh() {
      this.list=[]
      this.tmpArr=[]
      this.tmpArr=this.status_arr.filter((el, index) => {
        el.list=[]
        return el
      })
      this.isLoading = false;
      this.page.current_page = 0
      this.loading = true;
      this.finished = false;
      this.onLoad()
      this.$toast('刷新成功')
    }
  }
}
</script>

<style scoped>
.goods{padding:5px 0;}
.goods_title{
  font-size:12px;color:#000;display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.bt{
  position: absolute;
      z-index: 1;
      right: 16px;
      top: 10px;
}
.van-panel__content{padding:10px 16px!important;}
</style>
