<template>
  <div>
    <van-nav-bar
      title="我的反馈"
      left-text="返回"
      left-arrow
      @click-left="goBack"
    />
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
      <van-tabs sticky v-model="active" @click="onRefresh">
        <van-tab :title="type.title" v-for="(type,idx) in status_arr">
          <van-list
            v-if="idx==active"
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <van-panel v-for="item in list" :title="item.feed_no" :status="item.status_txt" style="margin-bottom:20px!important;font-size:12px;" @click="viewOrder(item)">
              <div>【{{item.typename}}】{{item.content}}</div>
              <div class="orderCon">
                  <van-row class="goods">
                    <van-col span="4" v-for='(gd,idx) in item.imgs'>
                      <van-image
                        width="3rem"
                        height="3rem"
                        fit="cover"
                        :src="gd.url"
                      />
                    </van-col>
                  </van-row>
              </div>
              <div slot="footer" style="height: 34px;">
                  创建时间 {{item.create_time}}<br/>
                <van-button class="bt" size="small">详情</van-button>
              </div>
            </van-panel>
          </van-list>
        </van-tab>
      </van-tabs>
    </van-pull-refresh>
    <van-popup v-model="infoVis">
    <van-steps direction="vertical" :active="logAct">
      <van-step v-for="item in logInfo">
        【{{item.type_txt}}】{{item.content}}<br/>
        <span v-if="item.user_info">By {{item.user_info.name}}<br/></span>
        {{item.create_time}}
      </van-step>
    </van-steps>
    </van-popup>
    
  </div>
</template>

<script>
import * as ajax from "@/api/common"
import util from '@/libs/util'
export default {
  name: 'mallOrderPage',
  data() {
    return {
      filter:{
        status:-1,
        user_type:1,
      },
      page:{
        current_page:0,
        page_total:1,
        count:0,
        page_size:10,
      },
      list:[],
      logInfo:[],
      active:0,
      logAct:0,
      loading:false,
      finished:false,
      activeNames:1,
      userInfo:false,
      isLoading:false,
      infoVis:false,
      tmpArr:[],
      types:[],
      status_arr:[{
        status:1,
        title:'待处理',
      },{
        status:2,
        title:'处理中',
      },{
        status:3,
        title:'待反馈',
      },{
        status:4,
        title:'已完成',
      }]
    }
  },
  mounted () {
    this.tmpArr=this.status_arr.filter((el, index) => {
      el.list=[]
      return el
    })
    this.active=this.$route.query.status?this.$route.query.status:0
    this.onRefresh()
  },
  methods:{
    goBack(){
      this.$router.go(-1)
    },
    viewOrder(row){
     this.$router.push({
      path:'/my/viewFeedBack',
      query:{
        id:row.id
      }
     })
    },
    makeList(){
      this.list = this.tmpArr[this.active].list
    },
    onLoad(){
      this.filter.status=this.status_arr[this.active].status
      this.loading = true
      this.page.current_page=parseInt(this.page.current_page) + 1
      ajax.getList({
        table:'FeedBack',
        scence:'mg_feedback',
        page:this.page.current_page,
        filter:this.filter
      }).then(res=>{
        this.loading = false
        this.page.page_total = res.last_page
        this.page.current_page = res.current_page
        this.page.count = res.total
        for (var i = 0; i <res.data.length; i++) {
          this.tmpArr[this.active].list.push(res.data[i])
        }
        this.makeList()
        if(parseInt(res.last_page)<=parseInt(res.current_page)){
          this.finished = true;
        }
      })
    },
    onRefresh() {
      this.list=[]
      this.tmpArr=[]
      this.tmpArr=this.status_arr.filter((el, index) => {
        el.list=[]
        return el
      })
      this.isLoading = false;
      this.page.current_page = 0
      this.loading = true;
      this.finished = false;
      this.onLoad()
    }
  }
}
</script>

<style scoped>
.goods{padding:5px 0;}
.goods_title{
  font-size:12px;color:#000;display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.bt{
  position: absolute;
      z-index: 1;
      right: 16px;
      top: 10px;
}
.van-panel__content{padding:10px 16px!important;}
</style>
