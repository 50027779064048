import {Toast} from 'vant'
import * as ajax from "@/api/common"
// import wx from 'weixin-js-sdk';

let wechat={}

wechat.getsignature = function (url,success_Fun,err_Fun) {
    ajax.get('/Publics/getWxSign',{input_url:url}).then(async res => {
        wx.config({
          debug: false,
          appId: res.appId, // 必填，公众号的唯一标识
          timestamp: res.timestamp, // 必填，生成签名的时间戳
          nonceStr: res.nonceStr, // 必填，生成签名的随机串
          signature: res.signature,// 必填，签名，见附录1
          success:e=>{
            Toast({message: e.errMsg , duration: 1500})
          },
          fail:e=>{
          console.log(e)
            // Toast({message: e.errMsg , duration: 1500})
        },
          jsApiList: [
            'scanQRCode',
            'openLocation'
          ]
        })
        //处理验证失败的信息
        wx.error(function (res) {
          console.log(res)
          // Toast({message: res.errMsg , duration: 1500})
        })
    }).catch(res=>{
    // Toast({message: res.msg , duration: 1500});
  })
}
wechat.scanQRCode = function (success_Fun,err_Fun) {
    wx.scanQRCode({
      needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
      scanType: ["qrCode","barCode"], // 可以指定扫二维码还是一维码，默认二者都有, "barCode"
      success: res=>{
        success_Fun(res)
    },
      fail:error=>{
        err_Fun(error)
    }
    })
}

export default wechat;