<template>
  <div id="underway_page">
    <div v-if="!show_answer" class="van-cell van-panel__header" style="position: fixed;bottom: 0px;display: flex;justify-content: flex-end;"><van-tag  size="large" plain type="success">{{callinTime}}</van-tag></div>
    <div v-if="!show_answer" class="van-cell van-panel__header" style="display: flex;justify-content: space-between;"><span>单选题(共{{question.length}}题，每题{{per}}分，满分{{fullmark}}分)</span><span v-if='info'>{{(nowIdx+1)+'/'+info.total}}</span></div>
    <van-nav-bar v-else title="答题结果" />
    <div class="cell_space"></div>
    <template v-if="show">
          <van-panel :title="(nowIdx+1)+'、'+item.question" class="cus-panel">
            <div class="opt">
              <!-- <img :src="item.path" v-if="item.path!=''"> -->
              <van-radio-group v-model="item.answer">
                <van-radio v-for="(opt,idx) in item.options" @click="choose(idx)" :name="opt.id">{{opt.title}}-{{opt.text}}</van-radio>
              </van-radio-group>
            </div>
          </van-panel>
          <div style="display: flex;justify-content: space-between;background: none" class="van-cell van-panel__header">
            
            <van-button  type="primary" @click="prevQues" plain>上一题</van-button>
            <van-button  v-if="!showSubmit" @click="toCancel" type='danger'>我要换题</van-button>
            <van-button v-if="showSubmit" type="primary" @click="submitExam" :disabled='submitButtonDisable'>提交我的答案</van-button>
            <van-button  type="primary" @click="nextQues"  plain>下一题</van-button>
          </div>
          <div class="score_rule">
            <div v-html="rule.content"></div>
          </div>
    </template>

    <template v-if="show_answer">
          <van-panel v-for="(item,idx) in question" :title="(idx+1)+'、'+item.question" class="cus-panel" style="margin-bottom:10px;">
            <div class="opt">
              <!-- <img :src="item.path" v-if="item.path!=''"> -->
              <van-radio-group v-model="item.answer" disabled>
                <van-radio v-for="(opt,idx) in item.options" :name="opt.id">{{opt.title}}-{{opt.text}}</van-radio>
              </van-radio-group>
            </div>
            <div slot="footer">
              正确答案 {{item.options[item.right].title}}
              您选择的是 {{item.options[item.answer].title}}
              <van-tag round v-if="item.result==1" type="success">正确</van-tag>
              <van-tag round v-else type="danger">错误</van-tag>
            </div>
          </van-panel>
          <div class="repeat" @click="repeat">再来一次</div>
    </template>

<van-action-sheet v-model="showForm" title="请填写基本信息" :closeable='false' :close-on-click-overlay='false'>
      <van-form @submit="onSubmit">
  <van-field
    v-model="name"
    name="name"
    label="姓名"
    placeholder="请填写您的姓名"
    :rules="[{ required: true, message: '请填写您的姓名' }]"
  />
  <van-field
    v-model="phone"
    name="phone"
    label="手机"
    placeholder="请填写您的手机号"
    :rules="[{ required: true, message: '请填写您的手机号' },{ pattern: /^1[3456789]\d{9}$/, message: '手机号码格式错误'}]"
  />
  <van-field
    v-model="xiaoqu"
    name="xiaoqu"
    label="小区"
    placeholder="请填写小区，如：幸福里小区"
    :rules="[{ required: true, message: '请填写您所在的小区' }]"
  />
  <van-field
    v-model="loudong"
    name="xiaoqu"
    label="楼栋"
    placeholder="请填写楼栋"
    :rules="[{ required: true, message: '请填写楼栋' }]"
  />
  <van-field
    v-model="menpai"
    name="xiaoqu"
    label="门牌"
    placeholder="请填写门牌号"
    :rules="[{ required: true, message: '请填写门牌号' }]"
  />
  <div style="margin: 16px;margin-bottom: 30px;">
    <van-button round block type="primary" native-type="submit">
      提交
    </van-button>
  </div>
</van-form>
</van-action-sheet>

  </div>
</template>

<script>
import * as ajax from "@/api/common"
export default {
  name: 'examapage',
    data() {
      return {
        showForm:true,
        rule:[],
        question:[],
        item:false,
        show:false,
        showSubmit:false,
        nowIdx:0,
        num:0,
        info:false,
        show_answer:false,
        result:[],
        sub_question:[],
        userInfo:[],
        name:'',
        phone:'',
        xiaoqu:'',
        loudong:'',
        menpai:'',
        per:0,
        callinTime:'0时0分0秒',
        secondCount:0,
        submitButtonDisable:false,
        fullmark:0,
        selfRight:0
      }
    },
    mounted() {
      this.makeExam()
    },
    methods: {
      onSubmit(){
        this.showForm=false
        this.start(true)
      },
      goHome(){
        this.$router.push('/');
      },
      toCancel(){
        this.$dialog.confirm({
          title:'温馨提示',
          message: '确定要重新换题吗?',
          cancelButtonText: '点错了',
          confirmButtonText: '重新换题'
        }).then(() => {
          this.repeat()
        }).catch(() => {
          return
        });
      },
      repeat(){
        this.show=true
        this.show_answer=false
        this.showSubmit=false
        this.question = []
        this.nowIdx = 0
        this.num = 1
        this.makeExam()
        this.reset()
        this.submitButtonDisable=false
      },
      makeExam(){
        this.nowIdx = 0
        //查找+进入
        ajax.get('/Score/getExam',{catalog_path:'score_question'}).then(res => {
          this.question = res.question
          this.info = {
            total:res.total,
            per:res.per,
            grade:res.grade,
            yes:res.yes,
            err:res.err
          }
          this.per = res.per
          this.rule=res.rule
          this.item = this.question[0]
          this.show = true
          this.show_answer = false
          this.num = this.nowIdx+1
          this.fullmark = this.per*this.question.length
          let sp = this.$route.query.sp
          if(sp != 'undefined' && parseInt(sp) > 0)
          {
            if(sp >this.fullmark)
            {
              this.$toast('本次修改成绩不生效，分数大于了'+this.fullmark+'分')
            }else{
            let selfRight = parseInt(sp)/this.per
            this.selfRight = parseInt(selfRight.toFixed(0))
            }
          }
        }).catch(error=>{
          this.$toast({message:error , duration: 1500})
          return
        })
      },
      prevQues(){
        this.nowIdx--
        this.showSubmit=false

        if(this.nowIdx<0){
          this.nowIdx=0
          this.$toast({message: '已经是第一题了' , duration: 1500})
          return
        }
        this.item = this.question[this.nowIdx]
      },
      nextQues(){
        let current_ans = this.question[this.nowIdx].answer
        if(current_ans === '')
        {
          this.$toast({message: '本题您还没作答！' , duration: 1500})
          return           
        }
        this.nowIdx++
        if(this.nowIdx==this.question.length){
          this.nowIdx--
          this.showSubmit=true
          this.$toast({message: '已经是最后一题了' , duration: 1500})
          return 
        }
        this.item = this.question[this.nowIdx]
      },
      choose(idx){
        this.question[this.nowIdx].answer = idx
        this.question[this.nowIdx].result = idx==this.question[this.nowIdx].right?1:0
        let _this=this
        let msg = '请稍后~'
        if(this.nowIdx+1 == this.info.total){
          this.showSubmit=true
          return
        }
        // this.nextQues()
        // this.$toast.loading({message: msg,duration:500,loadingType:'spinner'})
        // setTimeout(function(){
        // },500)
      },
      start (bolean) {
        let _this = this
        let hour, minute, second
        hour = minute = second = 0
        if (bolean === true) {
          _this.timer = setInterval(function () {
            if (second >= 0) {
              second = second + 1
              _this.secondCount ++
            }
            if (second >= 60) {
              second = 0
              minute = minute + 1
            }
            if (minute >= 60) {
              minute = 0
              hour = hour + 1
            }
            _this.callinTime = hour + '时' + minute + '分' + second + '秒'
          }, 1000)
        } else {
          window.clearInterval(_this.timer)
        }
    },
    reset()
    {
      window.clearInterval(this.start(false))
      this.secondCount=0
      this.callinTime = '0时0分0秒'
      this.start(true)
    },
      submitExam(){
        this.submitButtonDisable=true
        let err=0
        let yes=0
        let sub_question=[]
        for (var i = 0; i < this.question.length; i++) {
          var a = this.question[i]
          if(a.result==1){
            yes++
          }else{
            err++
          }
          sub_question.push({
            id:a.id,
            answer:a.answer,
            right:a.right,
            result:a.result
          })
        }

        if(this.selfRight>0)
        {
          yes = this.selfRight
          err = this.question.length-yes
        }
        
        let grade = (yes / this.question.length)*100
        this.info.grade = grade.toFixed(2)
        this.info.mark = this.per*yes.toFixed(0)

        this.info.yes = yes
        this.info.err = err
        this.result.question = sub_question
        this.result.info = this.info 
        let tmp={...this.result}
        let user = {
          name:this.name,
          phone:this.phone,
          xiaoqu:this.xiaoqu,
          loudong:this.loudong,
          menpai:this.menpai
        }
        let time = this.secondCount
          ajax.post('/Score/postExam',{exam:tmp,from:'new_wx',user,time}).then(res => {
            this.start(false)
            let message='正确 '+yes+' 题'+'，错误 '+err+' 题，正确率 '+this.info.grade+'%，用时 '+this.callinTime
            if(res.msg&&res.msg!='提交成功'){
              message+=','+res.msg
            }
            this.$dialog.confirm({
              title:'您好，'+this.name+'，答题结束，成绩'+this.info.mark+'分！',
              message: message,
              cancelButtonText: '查看结果',
              confirmButtonText: '再来一次'
            }).then(() => {
              this.nowIdx=0
              this.showSubmit=false
              this.show_answer = false
              this.makeExam()
            }).catch(() => {
              this.show=false
              this.show_answer=true
            });
          }).catch(error=>{
            this.$toast({message:error.msg , duration: 1500})
            this.submitButtonDisable=false

            return
          })
        
      },
    }
  }
</script>

<style scoped>
  .repeat{
    position: fixed;
    z-index: 100000;
    right: 10px;
    top: 5px;
    border-radius: 6px;
    background: rgba(103, 194, 58, 0.78);
    color: #fff;
    width: 80px;
    line-height: 30px;
    height: 30px;
    text-align: center;
  }
  .van-radio__label{display:inline!important;}
  .score_rule{padding:15px 20px;}
  /*.opt{padding:10px 20px;}*/
  .opt img{width:100%;margin-bottom:10px;}
  .opt .van-radio{margin:10px 0px;}
  .logout_btn{ padding:20px; }
  .logout_btn .van-checkbox--round{ margin-bottom:10px; }
  .logout_btn h4{ margin-bottom:10px; }
  .yuyue_form .vux-x-input-right-full { line-height:280%;padding-right:.4em; }
  #underway_page .weui-upimg img{ width:2em;height:2em;margin-right:.2em; }
  .cell_space{ display:block;width:100%;height:10px; }
  #underway_page .weui-cells{ margin-top:0 !important; }
  .list{ padding:10px 40px;line-height:40px;text-align:right;border-bottom:1px solid #dedede; }
  .list label{ float:left; }
  .list:last{border-bottom:none;}
  .qrcode{
    width: 50%;
    border-radius: 10px;
    border: 1px solid #dedede;
    background: #fff;
    padding: 10px;
  }
</style>