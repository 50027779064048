<template>
  <div>
    <template v-if="!hasLoad">
      <p><center>请稍后</center></p>
    </template>
    <template v-else>
        <van-nav-bar
          :title="page_title"
          left-text="返回"
          left-arrow
          sticky
          @click-left="goBack"
        />
        <template v-if="step==1">
          <!-- 按提货码查询 -->
          <van-cell-group>
            <van-field
              v-model="search.keyword"
              autosize
              label="提货码"
              type="text"
              placeholder="输入提货码"
              show-word-limit
            />
          </van-cell-group>
          <div style="text-align:center;margin-top: 10px;">
            <van-button type="primary" size="large" @click="toSearch">查询订单</van-button>
          </div>
        </template>
        <!-- 查询结果 -->
        <template v-if="step==2">
          <van-tabs sticky v-model="active">
            <van-tab title="配送单信息">
              <van-cell-group>
              <van-cell title="配送单号" :value="result_data.del_no"/>
              <van-cell title="配送状态" :value="result_data.status_txt"/>
              <van-cell title="订单号" :value="result_data.order_no"/>
              <van-cell title="提货码" :value="result_data.ms_code"/>
              <van-cell title="物品数量" :value="result_data.num"/>
              <van-cell title="订单状态" :value="result_data.r_status_txt"/>
              <van-cell title="退换数量">
              <van-stepper sloat='default' v-model="save_data.num" min="1" :max="result_data.num" />
              </van-cell>
              </van-cell-group>


              <van-cell-group title="异常情况说明">
                <van-field
                  v-model="save_data.content"
                  rows="2"
                  autosize
                  label="反馈内容"
                  type="textarea"
                  maxlength="200"
                  placeholder="反馈内容"
                  show-word-limit
                />
                <van-field
                  readonly
                  label="相关图片"
                  :placeholder="'最多上传'+maxCount+'张'"
                />
                <div class="upimg">
                  <div class="img_list" v-for="(img,idx) in this.save_data.imgs">
                    <van-image
                      class="imgs"
                      fit="contain"
                      :src="img.url"
                    />
                    <van-icon class="delimg" @click="delImg(idx)" name="cross" />
                  </div>
                  <van-uploader :after-read="afterRead" v-model="files" v-if="save_data.imgs.length<maxCount" :preview-image="false" :before-read="beforeRead"/>
                  <div style="clear: both;height: 1px;"></div>
                </div>
              </van-cell-group>

              <div style="text-align:center;margin-top: 10px;">
                <van-button type="primary" size="large" @click="toSave">换货</van-button>
              </div>
            </van-tab>
            <van-tab title="操作记录">
              <van-steps direction="vertical" :active="result_data.log.length-1">
                <van-step v-for='item in result_data.log'>
                  <div>【{{item.type_txt}}】{{item.content}}</div>
                  <div>{{item.create_time}}</div>
                  <div v-if="item.imgs">
                      <div class="img_list" v-for="(img,idx) in item.imgs">
                        <van-image
                          class="imgs"
                          fit="contain"
                          :src="img.url"
                          @click="openImg(img.url)"
                        />
                      </div>
                  </div>
                </van-step>
              </van-steps>
              <van-overlay :show="showImg" :z-index="100000" @click="show = false">
                <div class="wrapper" @click="show = false">
                  <div class="block">
                    <van-image
                    :src="imgUrl"
                    @click="showImg = false"
                    />
                  </div>
                </div>
              </van-overlay>
            </van-tab>
          </van-tabs>
          
        </template>
    </template>
  </div>
</template>

<script>
import * as ajax from "@/api/common"
export default {
  name: 'abnormalPage',
  data() {
    return {
      hasLoad:false,
      loading:false,
      family:false,
      result_data:false,
      step:1,
      active:0,
      showImg:false,
      imgUrl:'',
      page_title:'异常订单',
      search:{
        table:'DeliverySum',
        keyword:''
      },
      save_data:{
        table:'DeliverySum',
        num:1,
        ms_code:'',
        content:'',
        imgs:[]
      },
      isLoading:false,
      files:[],
      maxCount:3,
    }
  },
  mounted () {
    this.hasLoad=true
    this.step=1
  },
  methods:{
    // 返回布尔值
    beforeRead(file) {
      if (file.type !== 'image/jpeg' || file.type !== 'image/png') {
        this.$toast('请上传 jpg/png 格式图片');
        return false;
      }
      return true;
    },
    // 返回 Promise
    asyncBeforeRead(file) {
      return new Promise((resolve, reject) => {
        if (file.type !== 'image/jpeg' || file.type !== 'image/png') {
          this.$toast('请上传 jpg/png 格式图片');
          reject();
        } else {
          resolve();
        }
      });
    },
    beforeRead(file){
      if(file.length>this.maxCount){
        this.$toast('最多上传'+this.maxCount+'张')
        return false
      }
      return true
    },
    afterRead(files) {
      if(files.length==undefined){
        files=[files]
      }
      let formData = new FormData()
      for(let f of files){
        formData.append('file_data', f.file)
        ajax.post('/Upload/toSave',formData).then(res=>{
            this.save_data.imgs.push({
              name:res.savename,
              url:res.path,
            })
        }).catch(e=>{
          console.log(e)
        })
      }
    },
    delImg(idx){
      this.save_data.imgs.splice(idx,1)
    },
    goBack(){
      if(this.step==2){
        this.search.keyword=''
        this.result_data=false
        this.step=1
        return
      }
      this.$router.push('/my')
    },
    toSearch(){
      if(!this.search.keyword){
        this.$toast.fail('请输入提货码')
        return
      }
      //特殊查询
      this.search.special=1
      ajax.get('/Base/searchDeliveryByCode',{...this.search}).then(res=>{
        this.result_data = res
        this.save_data.num = 1
        this.save_data.ms_code = res.ms_code
        this.step = 2
      })
    },
    toSave(){
      this.save_data.showTips=true
      this.$dialog.confirm({
        title: '确认',
        message: '确认提交退/换货吗'
      }).then(() => {
        ajax.post('/Base/reback',{...this.save_data}).then(res=>{
          this.goBack
        }).catch(e=>{
          this.$toast.fail(e.msg)
        })
      }).catch(() => {
        this.$toast('放弃')
      });
    },
    openImg(url){
      this.showImg=true
      this.imgUrl=url
    }
  }
}
</script>

<style scoped>
.user{background:#07c160;padding:20px 0;color:#fff;}
.van-panel__content{
  font-size:14px;
  line-height:24px;
  padding:15px;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.block {
  width: 100%;
  height: auto;
  background-color: #fff;
}
.van-cell:not(:last-child)::after{left:0px!important;}
.upimg{padding:10px;}
.img_list{width:80px;height: 80px;float: left;position: relative;margin-right:6px;}
.img_list .imgs{border-radius: 5px;width:80px;height: 80px;}
.img_list .delimg{position: absolute;z-index: 2;right:5px;top:5px;font-size: 16px;}
</style>
