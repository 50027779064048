<template>
  <div>
    <van-sticky>
    <van-nav-bar
      :title="page_title"
      left-text="返回"
      left-arrow
      @click-left="goBack"
    />
    </van-sticky>
      <!-- 新绑定手机号 -->
      <van-cell-group style="height:100%;" v-if="step==1">
        <van-field
          v-model="bindData.phone"
          clearable
          label="新号码"
          placeholder="请输入用于绑定的手机号"
        />
        <van-field
            v-model="bindData.code"
            center
            clearable
            label="短信验证码"
            placeholder="请输入短信验证码"
          >
          <van-button slot="button" size="small" type="primary" @click="getVerCode(bindData.phone,2)" v-if="canSend">发送验证码</van-button>
          <van-button slot="button" size="small" type="default" disabled v-else>等待{{time_max}}秒</van-button>
        </van-field>
        <div style="text-align:center;padding:0 20px;margin-top:10px;"><van-button type="primary" round size="large" @click="toSave">确认绑定</van-button></div>
      </van-cell-group>
      <!-- 验证原手机号 -->
      <van-cell-group style="height:100%;" v-if="step==2">
        <van-field
          v-model="userInfo.phone_hide"
          clearable
          label="原手机号"
          disabled
        />
        <van-field
            v-model="bindData.code"
            center
            clearable
            label="短信验证码"
            placeholder="请输入短信验证码"
          >
          <van-button slot="button" size="small" type="primary" @click="getVerCode(userInfo.phone,1)" v-if="canSend">发送验证码</van-button>
          <van-button slot="button" size="small" type="default" disabled v-else>等待{{time_max}}秒</van-button>
        </van-field>
        <div style="text-align:center;padding:0 20px;margin-top:10px;"><van-button type="primary" round size="large" @click="toNext">下一步</van-button></div>
      </van-cell-group>
  </div>
</template>

<script>
import * as ajax from "@/api/common"
import util from '@/libs/util'
export default {
  name: 'scoreListPage',
  data() {
    return {
      list:[],
      page_title:'',
      show:false,
      loading:false,
      finished:false,
      activeNames:1,
      userInfo:false,
      score:'',
      money:'',
      isLoading:false,
      step:1,
      bindData:{
        phone:'',
        code:''
      },
      timer:false,
      time_max:60,
      canSend:true,
    }
  },
  watch:{
    step(val){
      this.page_title=val==1?'绑定手机':'验证原手机'
    }
  },
  mounted () {
    this.getUserInfo()
  },
  methods:{
    goBack(){
      this.$router.go(-1)
    },
    toSave(){
      let query={
        phone:this.bindData.phone,
        code:this.bindData.code,
        showTips:true
      }
      ajax.post('/User/bindPhoneSave',query).then(async res => {
        clearInterval(this.timer)
        this.canSend=true
        util.cookies.set('needPull', true)
        this.goBack()
      }).catch(e=>{
        console.log(e)
        this.$toast(e.msg)
      })
    },
    getVerCode(phone,type){
      if(!this.canSend){
        this.$toast('请稍后再发送')
        return false
      }
      if(!phone){
        this.$toast('请输入手机号')
        return
      }
      //获取短信验证码
      let query={
        phone:phone,
        type:type,
        showTips:true
      }
      ajax.get('/Publics/getVerCode',query).then(async res => {
        this.setTime()
      }).catch(e=>{
        console.log(e)
      })
    },
    setTime(){
      let _this=this
      clearInterval(_this.timer)

      _this.timer = setInterval(function(){
        if(_this.time_max==0){
          clearInterval(_this.timer)
          _this.time_max=60
          _this.canSend=true
          return
        }else{
          _this.canSend=false
          _this.time_max--
        }
      },1000)
    },
    toNext(){
      //验证原手机号
      let query={
        phone:this.bindData.phone,
        code:this.bindData.code,
        showTips:true
      }
      ajax.get('/Publics/checkVerCode',query).then(async res => {
        this.userInfo = res
        this.step=1
        this.bindData={
          phone:'',
          code:''
        }
        clearInterval(this.timer)
        this.canSend=true
      }).catch(e=>{
        console.log(e)
      })
    },
    getUserInfo(){
      let info = this.$store.dispatch('lele/user/load').then(res=>{
        this.userInfo=res
        this.bindData={
          phone:'',
          code:''
        }
        this.page_title = this.userInfo.phone?'更换手机':'绑定手机'
        this.step = this.userInfo.phone?2:1
        this.bindData.phone=this.userInfo.phone
      }).catch(e=>{
        this.userInfo=false
      })
    },
  }
}
</script>

<style scoped>
.tinfo{color:#fff;}
.scoreList{border-bottom:1px solid #f3f3f3;background:#fff;padding:10px;}
.scoreList .number{font-size:20px;text-align:right;color:#ff6600;}
</style>
