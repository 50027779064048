<template>
  <div>
    <van-nav-bar
      title="箱体授权登陆"
    />
    <template v-if='hasLoad'>
      <section class="login">
        <van-cell-group>
          <van-field
            v-model="boxInfo.b_name"
            clearable
            label="箱体名称"
            disabled
          />
        </van-cell-group>
        <van-cell-group>
          <van-field
            v-model="boxInfo.b_addressnote"
            clearable
            label="所在位置"
            disabled
          />
        </van-cell-group>
        <p style="display: none;"><center><van-button class="login_bt" type="primary" @click="toLogin" round>确认登陆箱体</van-button></center></p>
      </section>
    </template>
    <template v-else>
      <p><center>请稍后...</center></p>
    </template>
</div>
</template>

<script>
import * as ajax from "@/api/common"
import util from '@/libs/util'
// import { mapActions } from 'vuex'
export default {
  name: 'authLogin',
  data(){
    return {
      boxId:false,
      hasLoad:false,
      qr_code:false,
    }
  },
  mounted () {
    this.boxId=this.$route.query.b_id?this.$route.query.b_id:false
    this.qr_code=this.$route.query.qr_code?this.$route.query.qr_code:false
    this.box_type=this.$route.query.box_type?this.$route.query.box_type:'old'
    if(!this.boxId||!this.qr_code){
      this.$toast('参数丢失')
      var _this=this
      setTimeout(function(){
        _this.$router.push('/')
      },1000)
      return
    }
    this.getBoxInfo()
  },
  methods:{
    getBoxInfo(){
      ajax.get('/Box/getBoxConfById',{box_id:this.boxId,box_type:this.box_type}).then(res=>{
        this.boxInfo = res
        this.hasLoad=true
        this.toLogin()
      }).catch(e => {
        this.$toast(e.msg)
        // console.log(e)
      })
    },
    toLogin () {
      if(!this.boxId){
        this.$toast('缺少参数')
        return
      }
        ajax.post('/User/login',{
          box_id: this.boxId,
          login_type:'qrcodeAuth',
          qr_code:this.qr_code,
          sys_type:this.box_type=='old'?11:2,
          showTips:true
        })
        .then(() => {
          const token = util.cookies.get('token')
          const uuid = util.cookies.get('uuid')
          var _this=this
           setTimeout(function(){
            _this.$router.push('/')
          },1000)
        }).catch((e) => {
          this.$toast(e.msg)
          // console.log(e)
        })
      
    }
  }
}

</script>

<style scoped>
.login{margin:10px 0;}
.login_bt{margin:10px 0;width:90%;}
</style>