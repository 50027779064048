<template>
  <div class="mall">
    <van-sticky>
    <van-nav-bar
      title="提交订单"
      left-text="返回"
      left-arrow
      @click-left="goBack"
    />
    </van-sticky>
    <template v-if="hasLoad">
      <van-cell icon="location-o" value="更换" is-link @click="showList=true" v-if="saveData.address">
        <template slot="title">
          <span class="custom-title">{{saveData.address.name}}</span>
          <van-tag type="danger" v-if="saveData.address.is_def">默认</van-tag>
        </template>
        <template slot="label">
          <div style="width:200px">{{saveData.address.phone}}<br/>{{saveData.address.ssq}}-{{saveData.address.address}}</div>
        </template>
      </van-cell>
      <van-cell icon="location-o" is-link @click="onAdd" v-else>
        <template slot="title">
          <span class="custom-title">没有收货人</span>
        </template>
        <template slot="label">
          <p style="text-align:center"><van-button type="default" @click="onAdd" round>新增地址</van-button></p>
        </template>
      </van-cell>
      <template>
        <van-radio-group v-model="saveData.points_id">
          <van-cell-group>
            <van-cell><van-tag round type="danger">选择提货点</van-tag></van-cell>
            <van-cell :title="p.title" v-for="p in points" clickable v-if="points.length">
              <template slot="label">
                <div style="width:200px">{{p.address}}</div>
              </template>
              <van-radio slot="right-icon" :name="p.id" />
            </van-cell>
            <van-cell v-if="!points.length"><span style="color: red">该区域暂无提货点</span></van-cell>
            <van-cell><van-tag round type="danger">快递寄送</van-tag></van-cell>
            <van-cell :title="s.title" v-for="s in kuaidi" clickable>
              <template slot="label">
                <div style="width:200px">{{s.address}}</div>
              </template>
              <van-radio slot="right-icon" :name="s.id" />
            </van-cell>
          </van-cell-group>
        </van-radio-group>
        <van-cell icon="shop-collect-o" title="配送时间" :value="'预计'+saveData.post_date+'送达'"></van-cell>
      </template>
      <van-divider content-position="left">商品信息</van-divider>
      <van-card
        class="list"
        v-for="item in saveData.list"
        :num="item.num"
        :price="item.price"
        :desc="item.content.sku_name"
        :title="item.goods_title"
        :thumb="item.path"
      >
      <div slot='footer' v-if='!is_vip'>该商品可使用积分抵扣 <span style="color:red;font-size: 18px">{{item.content.score == 0?5:item.content.score}}%</span></div>

      <div slot="footer">
        小计 ￥{{item.num*item.content.price}}
      </div>
      </van-card>
      <div style="padding: 12px 0px;">

        <div v-if='vip_start'>
        <div v-if="is_vip" class="vip-desc">
          <div>亲爱的{{name}}，您是乐乐一家VIP用户，该订单享受批发采购价优惠！</div>
          <div style="font-size: 12px;text-align: right;margin-top: 10px;"><!-- 订单原价 <span class="bold">￥{{total_price}}</span> 元  |   -->VIP价 <span class="bold">￥{{vip_total}}</span> 元 </div>
        </div>
        <div v-else class="not-vip-desc" @click="kownVip">您还不是乐乐一家VIP用户，不能享受批发采购价优惠，点击了解乐乐一家VIP？</div>
      </div>
      </div>


      <van-cell icon="gold-coin-o" v-if='!is_vip'>
        <template slot="title">
          <span class="custom-title">积分抵扣</span>
        </template>
        <template slot="right-icon">
          <van-switch size="18px" :disabled="scoreInfo.score==0" v-model="use_score" style="margin-left:10px;"/>
        </template>
      </van-cell>
      <div class="tips" v-if="use_score">
        <p>总积分<span>{{scoreInfo.score}}</span></p>
        <p>当前订单最多可使用<span>{{in_use_score_computed}}</span>积分,抵扣<span>{{in_use_score_computed/100}}</span>元</p>
      </div>

      <van-cell icon="gold-coin-o">
        <template slot="title">
          <span class="custom-title">金币抵扣</span>
        </template>
        <template slot="right-icon">
          <van-switch size="18px" :disabled="scoreInfo.coin==0" v-model="use_coin" style="margin-left:10px;"/>
        </template>
      </van-cell>
      <div class="tips" v-if="use_coin">
        <p>总金币<span>{{scoreInfo.coin}}</span></p>
        <p>当前订单最多可使用<span>{{in_use_coin_computed}}</span>金币,抵扣<span>{{in_use_coin_computed}}</span>元</p>
      </div>
      <van-cell-group style="margin-top:10px;">
        <van-field
          v-model="saveData.mark"
          rows="1"
          autosize
          label="备注"
          type="textarea"
          placeholder="如果有其他要求，请在此输入"
        />
      </van-cell-group>
      <van-submit-bar v-if='useButton'
        :price="saveData.pay"
        button-text="提交订单"
        @submit="onSubmit"
      />
      <van-submit-bar
      v-else
        disabled
        :price="saveData.pay"
        button-text="已提交"
      />
      <van-popup v-model="showList" position="bottom">
        <van-cell-group>
          <van-cell
          v-for="(item,idx) in address"
          :title="item.name"
          @click="onSelect(item)"
          >
          <template slot="title">
            <span class="custom-title">{{item.name}}</span>
            <van-tag type="danger" v-if="item.is_def">默认</van-tag>
          </template>
          <template slot="label">
            {{item.phone}}<br/>{{item.ssq}}-{{item.address}}
          </template>
            <van-tag slot="right-icon" style="height:16px;" type="default">选择</van-tag>
          </van-cell>
        </van-cell-group>
        <p style="text-align:center"><van-button type="default" @click="onAdd" round>新增地址</van-button></p>
      </van-popup>
    </template>
    <template v-if="showAudio">
      <audio style="display: none;" autoplay @ended="audioEnd">
          <source type="audio/mpeg" :src="'http://tts.baidu.com/text2audio/text2audio?lan=zh&amp;ie=UTF-8&amp;spd=5&amp;text='+tmpTxt">
      </audio>
    </template>
  </div>
</template>

<script>
import * as ajax from "@/api/common"
import util from '@/libs/util'
import wx from 'weixin-js-sdk';
export default {
  name: 'MallPage',
  data(){
    return {
      is_vip:false,
      vip_start:false,
      name:'',
      useButton:true,
      tmpTxt:'',
      showAudio:false,
      checked:false,
      showList:false,
      hasLoad:false,
      use_score:false,
      in_use_score:0,
      in_use_score_money:0,
      use_coin:false,
      in_use_coin:0,
      in_use_coin_money:0,
      order_no:'',
      order_id:0,
      points_arr:[],
      cansubmit:true,
      post_date:'',
      way_arr:[{
        id:1,
        title:'指定时间',
        desc:'最多可使用积分抵扣10%订单金额'
      },{
        id:2,
        title:'次日达',
        desc:'最多可使用积分抵扣20%订单金额'
      }],
      saveData:{
        list:[],
        total:0,
        points_id:0,
        score:0,
        coin:0,
        discounts:0,
        pay:0,
        mark:'',
        post_way:''
      },
      post_way:'',
      current_data:null,
      address:[],
      orderData:{},
      scoreInfo:{},
      points:[],
      kuaidi:[],
      total_price:0,
      vip_total:0,
    }
  },
  computed:
  {
    in_use_score_computed(){
      return this.in_use_score
    },
    in_use_coin_computed(){
      return this.in_use_coin
    }
  },
  watch: {
    use_score(val){
      this.checkUse()
    },
    use_coin(val){
      this.checkUse()
    },
    post_way(val){
      this.saveData.post_way=val
      this.checkUse()
    }
  },
  mounted () {
    this.getAddress()
    this.load()
  },
  methods:{
    audioEnd(){
        this.tmpTxt=''
        this.showAudio=false
    },
    kownVip()
    {
      this.$router.push({path:"/my/index",query:{from:1}})
    },
    toPlay(txt){
      this.tmpTxt=txt?txt:"你好"
        this.showAudio=true
    },
    selectPoint(val){

    },
    checkUse(){
      //计算可使用积分的最大上限
      this.in_use_score=0
      //不同配送方式 可使用的积分比例不同
      let per = 5
      //计算当前订单可用积分数量
      let can_use_score = 0

      let g_data = this.current_data.list

      for(let g in g_data)
      {
        let score_ratio = g_data[g].content.score == 0?5:g_data[g].content.score
        can_use_score = g_data[g].content.price*g_data[g].num*score_ratio + can_use_score
      }

      //判断最终可用多少积分
      let apply_score = this.scoreInfo.score>=can_use_score?can_use_score:this.scoreInfo.score

      if(this.use_score){
        this.in_use_score=parseInt(apply_score.toFixed(0))
      }
      this.in_use_coin=0
      if(this.use_coin){
        if(!this.use_score && apply_score>0){
          this.in_use_coin=this.scoreInfo.max_use.max_use_coin + apply_score/100
          if(this.in_use_coin>this.scoreInfo.coin){
            this.in_use_coin=this.scoreInfo.coin
          }
          var coin=(this.saveData.total - this.in_use_score)/100
          this.in_use_coin= coin>this.scoreInfo.max_use.max_use_coin?this.scoreInfo.max_use.max_use_coin:coin
        }else{
          var coin=(this.saveData.total - this.in_use_score)/100
          this.in_use_coin= coin>this.scoreInfo.max_use.max_use_coin?this.scoreInfo.max_use.max_use_coin:coin
        }
      }
      // js 浮点数不好处理
      let _coin = this.in_use_coin*100
      let int_coin = parseInt(_coin.toFixed(0))
      this.saveData.score = this.in_use_score
      this.saveData.coin = this.in_use_coin
      this.saveData.pay = this.saveData.total - this.in_use_score - int_coin
    },
    onSelect(val){
      this.showList=false
      this.saveData.address=val
      this.getPointsList()
    },
    onAdd(){
      this.$router.push({
        path:'/my/addressList',
        query:{
          action:'from_order'
        }
      })
    },
    getAddress(){
      ajax.get('/Base/getAddressList',{table:'Address'}).then(async res => {
        this.address = res
        this.hasLoad=true
        if(res.length==0){
          this.saveData.address=false
          return
        }
        this.saveData.address=res[0]
        this.getPointsList()
      })
    },
    getPointsList(){
      ajax.get('/Base/getPointsList',{table:'Points',city_code:this.saveData.address.code}).then(async res => {
        // this.points_arr=res
        // console.log('tirin',this.points_arr.kuaidi)
        // if(res.length==0){
        //   this.saveData.points=false
        //   this.saveData.points_id=0
        //   return
        // }
        this.points = res.points
        this.kuaidi = res.kuaidi
        // if(this.points.length == 0)
        // {
        //   this.saveData.points_id=0; 
        // }else{
          this.saveData.points_id=null
        // }

      })
    },
    getScoreInfo(){
      ajax.get('/Score/getMyScore',{total:this.saveData.total}).then(async res => {
        this.scoreInfo=res
        this.is_vip=res.is_vip
        this.name=res.name
        this.vip_start=res.vip_start
      })
    },
    load(){
      // let data=util.cookies.get('makeData')
      let data = window.localStorage.getItem('makeData')
      this.current_data = JSON.parse(data)
      let _this=this
      if(!data && typeof(data)!="undefined" && data!=0){
        this.$toast('缺少商品信息')
        setTimeout(function(){
          _this.goBack()
        },1000)
        return
      }else{
        data=JSON.parse(data)
        let n=0
        data.list.map(item=>{
          if(item.post_day>n){
            n = item.post_day
            this.saveData.post_date = item.post_day_str
          }
        })
        this.saveData.list=data.list
        this.saveData.seckill_id=data.seckill_id?data.seckill_id:0
        this.saveData.discounts=0

        this.total_price = data.total/100
        let tmp_price = 0
        if(this.is_vip)
        {
            tmp_price = data.total/100
        }else{
          tmp_price = this.total_price 
        }
        this.vip_total = tmp_price.toFixed(2)
        this.saveData.pay=parseInt(this.vip_total*100)
        this.saveData.total=parseInt(this.vip_total*100)
        if(this.saveData.total==0){
          this.$toast('订单金额不能为0')
          setTimeout(function(){
            _this.$router.go(-1)
          },1000)
          return
        }
        this.getScoreInfo()
        this.checkUse()
      }
    },
    goBack(){
      this.$router.go(-1)
    },
    onSubmit() {
      var _this=this
      if(!this.cansubmit){
        this.$toast('请稍后')
        return
      }
      // this.saveData.score=this.in_use_score
      // this.saveData.coin=this.in_use_coin
      if(this.points.length == 0){
        this.$toast('该区域没有提货点')
        return
      }
      if(!this.saveData.points_id){
        // if(this.points_arr.length==0){
        //   this.$toast('收货地址超出配送范围')
        //   return
        // }
        this.$toast('请选择一个提货点')
        return
      }
      this.useButton = false
      this.cansubmit=false
      ajax.post('/MallOrder/createOrder',{table:'MallOrder',saveData:this.saveData}).then(async res => {
        util.cookies.set('needPull', true)
        this.cansubmit=true
        if(res.status==2){
          //已经支付成功
          this.$toast('订单已提交')
          setTimeout(function(){
            _this.$router.push({
              path:'/my/viewMallOrder',
              query:{
                id:res.id,
                froms:'make'
              }
            })
          },1000)
        }else{
          this.toPlay('订单创建成功，是否立即付款')
          this.$dialog.confirm({
            title: '提示',
            message: '订单创建成功，是否立即付款'
          }).then(() => {
            this.order_no=res.order_no
            this.order_id=res.id
            this.toPay()
          }).catch(() => {
            this.$toast('稍后你可在商城订单继续完成支付')
            setTimeout(function(){
              _this.$router.push({
                path:'/my/viewMallOrder',
                query:{
                  id:res.id,
                  froms:'make'
                }
              })
            },1000)
          })
        }
      }).catch((e)=>{
        this.$toast(e.msg)
      })
    },
    toPay(){
      var _this=this
      ajax.post('/Pay/toPay',{order_no:this.order_no,pay_way:1,showTips:true}).then(res=>{
          const Data = JSON.parse(res)
          this.wexinPay(Data,function(){
            _this.$router.push({
              path:'/my/viewMallOrder',
              query:{
                id:_this.order_id,
                froms:'make'
              }
            })
          },function(r){
            _this.$router.push({
              path:'/my/viewMallOrder',
              query:{
                id:_this.order_id,
                froms:'make'
              }
            })
            _this.$toast('支付失败')
          },function(r){
            _this.$toast('您放弃了支付')
            _this.$router.push({
              path:'/my/viewMallOrder',
              query:{
                id:_this.order_id,
                froms:'make'
              }
            })
          })
      }).catch(e=>{
        this.$toast(e)
        return
      })
    },
    wexinPay(data, cb, errorCb,cancelCb) {
      var _this=this
        //获取后台传入的数据
        let appId = data.appId;
        let timestamp = data.timeStamp;
        let nonceStr = data.nonceStr;
        let signature = data.signature;
        let packages = data.package;
        let paySign = data.paySign;
        let signType = data.signType;
        //下面要发起微信支付了
        wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: appId, // 必填，公众号的唯一标识
            timestamp: timestamp, // 必填，生成签名的时间戳
            nonceStr: nonceStr, // 必填，生成签名的随机串
            signature: signature, // 必填，签名，见附录1
            jsApiList: ['chooseWXPay'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
        });
        wx.ready(function () {
          wx.chooseWXPay({
            timestamp: timestamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
            nonceStr: nonceStr, // 支付签名随机串，不长于 32 位
            package: packages, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=***）
            signType: signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
            paySign: paySign, // 支付签名
            success: function (res) {
                // 支付成功后的回调函数
                cb(res);
            },
            cancel: function (res) {
              cancelCb(res)
            },
            fail: function (res) {
                //失败回调函数
                errorCb(res);
            }
          });
        });
        wx.error(function (res) {
          _this.$toast('调起支付失败')
        })
    },
  }
}
</script>

<style scoped>
.bold{
  font-weight: bold;
}
.not-vip-desc{box-sizing: border-box;border: 1px solid #EF0F25;text-align: left; padding: 0px 5px;font-size: 14px;padding: 12px 12px;}

.vip-desc{margin-bottom: 10px;background: linear-gradient(to right,#fff, #FFD700);font-size: 14px;padding: 12px 12px;}

.list{background:#fff;}
.tips{background:#fff;padding:10px 15px;color:#666;font-size:12px;}
.tips p{margin:0;}
.tips span{color:#ff0000;padding:0 5px;}
.van-submit-bar__text{text-align:left;padding:0;padding-left:12px;}
.goods_title .price_info{}
.goods_title .price_info .price{color:#ff0000;font-size:30px;}
.goods_title .price_info small{font-size:14px!important;}
.goods_title .price_info .price_del{font-size:14px;color:#999;text-decoration: line-through;}
.goods_title .star{font-size:20px;color:#999;}
.goods_title .star_act{color:#ff0000;}
.sc{font-size:10px;color:#666;text-align:center;line-height:10px;float:right;width:50px;}
.goods_title h1{font-weight:300;color:#000;font-size:20px;}
.goods_title p{padding:0px;margin:0px;color:#666;font-size:14px;line-height:20px;}
.goods_content{}
.goods_content p{margin:0px;padding:0;}
.goods_content img{width:100%;margin:0;padding:0;}
</style>
