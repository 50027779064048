<template>
  <div>
    <van-nav-bar
      title="商城订单"
      left-text="返回"
      left-arrow
      style="display:none"
      @click-left="goBack"
    />
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
      <van-tabs sticky v-model="active" @click="onRefresh">
        <van-tab :title="type.title" v-for="(type,idx) in status_arr" :info="type.count">
          <form action="/">
  <van-search
    v-model="searchValue"
    show-action
    placeholder="请输入配送单号/商品名"
    @search="onSearch"
    @cancel="onCancel"
  />
</form>
          <van-list
            v-if="idx==active"
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <van-panel v-for="item in list" :title="item.del_no" :status="item.status_txt" style="margin-bottom:20px!important;font-size:12px;" @click="viewOrder(item)">
              <div><van-tag type="danger">{{item.points_info.title}}</van-tag></div>
              <div class="orderCon">
                {{item.goods_title}}<br/>{{item.sku_name}} * {{item.total_num}}
              </div>
              <div class="orderCon" style="color: red;display: flex;" v-if="item.status==4">
                业绩：{{item.total_yeji}}元
              </div>
              <div slot="footer" style="height: 30px;">
                  创建时间 {{item.create_time}}
                <van-button class="bt" size="small">详情</van-button>
              </div>
            </van-panel>
          </van-list>
        </van-tab>
      </van-tabs>
    </van-pull-refresh>
    <van-popup v-model="infoVis">
    <van-steps direction="vertical" :active="logAct">
      <van-step v-for="item in logInfo">
        【{{item.type_txt}}】{{item.content}}<br/>
        <span v-if="item.user_info">By {{item.user_info.name}}<br/></span>
        {{item.create_time}}
      </van-step>
    </van-steps>
    </van-popup>
    
  </div>
</template>

<script>
import * as ajax from "@/api/common"
import util from '@/libs/util'
export default {
  name: 'infoPage',
  data() {
    return {
      filter:{
        status:-1,
      },
      page:{
        current_page:0,
        page_total:1,
        count:0,
        page_size:10,
      },
      list:[],
      logInfo:[],
      active:0,
      logAct:0,
      loading:false,
      finished:false,
      activeNames:1,
      userInfo:false,
      isLoading:false,
      infoVis:false,
      tmpArr:[],
      status_arr:[
      {
        title:'全部',
      },{
        title:'备货中',
      },{
        title:'配送中',
      },{
        title:'待取货',
      },{
        title:'已取货',
      }],
      searchValue:''
    }
  },
  mounted () {
    this.tmpArr=this.status_arr.filter((el, index) => {
      el.list=[]
      return el
    })
    this.active=this.$route.query.status?this.$route.query.status:-1
    this.getType()
  },
  methods:{
    goBack(){
      this.$router.go(-1)
    },
    viewOrder(row){
     this.$router.push({
      path:'/points/putIn',
      query:{
        del_no:row.del_no,
        act:2
      }
     })
    },
    getType(){
      this.loading = true
      ajax.get('/Base/deliveryInfoMy',{
        table:'DeliverySum',
      }).then(res=>{
        this.loading = false
        this.status_arr = res
        this.onLoad()
      }).catch(e=>{
        this.$toast(e.msg)
        if(e.code==999){
          let _this=this
          setTimeout(a=>{
            this.$router.go(-1)
            return
          },1500)
        }
        this.loading = false
      })
    },
    makeList(){
      this.list = this.tmpArr[this.active].list
    },
    onLoad(){
      this.filter.status=this.active
      this.filter.del_no = this.searchValue
      this.loading = true
      this.page.current_page=parseInt(this.page.current_page) + 1
      ajax.getList({
        table:'DeliverySum',
        scence:'mg_DeliveryMy',
        page:this.page.current_page,
        filter:this.filter
      }).then(res=>{
        this.loading = false
        this.page.page_total = res.last_page
        this.page.current_page = res.current_page
        this.page.count = res.total
        for (var i = 0; i <res.data.length; i++) {
          this.tmpArr[this.active].list.push(res.data[i])
        }
        this.makeList()
        if(parseInt(res.last_page)<=parseInt(res.current_page)){
          this.finished = true;
        }
      }).catch(e=>{
        this.$toast(e.msg)
        if(e.code==999){
          let _this=this
          setTimeout(a=>{
            this.$router.go(-1)
            return
          },1500)
        }
        this.loading = false
        this.finished = true;
      })
    },
    onRefresh() {
      this.list=[]
      this.tmpArr=[]
      this.tmpArr=this.status_arr.filter((el, index) => {
        el.list=[]
        return el
      })
      this.isLoading = false;
      this.page.current_page = 0
      this.loading = true;
      this.finished = false;
      this.onLoad()
      this.$toast('刷新成功')
    },
    onSearch(val) {
      this.onRefresh()
    },
    onCancel() {
      this.onRefresh()
    },
  }
}
</script>

<style scoped>
.goods{padding:5px 0;}
.goods_title{
  font-size:12px;color:#000;display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.bt{
  float: right;
}
.van-panel__content{padding:10px 16px!important;}
</style>
