export default {
  namespaced: true,
  state: {
    // 用户信息
    info: {},
    url:'',
    hasNew:false
  },
  getters:{
    getInfo (state) {
        return state.info
    },
    getWebUrl (state) {
      return state.url
    }
  },
  mutations:{
    setHasNew(state,foo){
      state.hasNew = foo
    },
    setIsVip(state,foo)
    {
      state.info.is_vip=foo
    }
  },
  actions: {
    setUrl({ state, dispatch }, info){
      if(state.url!=''){
        return state.url
      }
      state.url=info
      return state.url
    },
    setUserInfo ({ state, dispatch }, info) {
      return new Promise(async resolve => {
        // store 赋值
        state.info = info
        // 持久化
        await dispatch('lele/db/set', {
          dbName: 'sys',
          path: 'user.info',
          value: info,
          user: true
        }, { root: true })
        resolve()
      })
    },
    /**
     * @description 从数据库取用户数据
     * @param {Object} state vuex state
     */
    load ({ state, dispatch }) {
      return new Promise(async resolve => {
        // store 赋值
        state.info = await dispatch('lele/db/get', {
          dbName: 'sys',
          path: 'user.info',
          defaultValue: {},
          user: true
        }, { root: true })
        resolve(state.info)
      })
    }
  }
}
