<template>
  <div id="underway_page">
    <van-nav-bar v-if="!show_answer" :title="info?'答题进度 '+(nowIdx+1)+'/'+info.total:'请稍后' "
      left-text="返回"
      left-arrow
      @click-left="$router.push('/')"
    />
    <van-nav-bar v-else title="答题结果"
      left-text="返回"
      left-arrow
      @click-left="$router.push('/')"
    />
    <div class="cell_space"></div>
    <template v-if="show">
          <van-panel :title="(nowIdx+1)+'、'+item.question" class="cus-panel">
            <div class="opt">
              <img :src="item.path" v-if="item.path!=''">
              <van-radio-group v-model="item.answer">
                <van-radio v-for="(opt,idx) in item.options" @click="choose(idx)" :name="opt.id">{{opt.title}}-{{opt.text}}</van-radio>
              </van-radio-group>
            </div>
          </van-panel>
          <div style="padding:20px;">
            <van-button v-if="showSubmit" type="primary" @click="submitExam">提交我的答案</van-button>
            <van-button type="default" @click="toCancel">放弃本次答题</van-button>
          </div>
          <div class="score_rule">
            <div v-html="rule.content"></div>
          </div>
    </template>

    <template v-if="show_answer">
          <van-panel v-for="(item,idx) in question" :title="(idx+1)+'、'+item.question" class="cus-panel" style="margin-bottom:10px;">
            <div class="opt">
              <img :src="item.path" v-if="item.path!=''">
              <van-radio-group v-model="item.answer" disabled>
                <van-radio v-for="(opt,idx) in item.options" :name="opt.id">{{opt.title}}-{{opt.text}}</van-radio>
              </van-radio-group>
            </div>
            <div slot="footer">
              正确答案 {{item.options[item.right].title}}
              你选择的是 {{item.options[item.answer].title}}
              <van-tag round v-if="item.result==1" type="success">正确</van-tag>
              <van-tag round v-else type="danger">错误</van-tag>
            </div>
          </van-panel>
          <div class="repeat" @click="repeat">再来一次</div>
    </template>
  </div>
</template>

<script>
import * as ajax from "@/api/common"
export default {
  name: 'QuestionPage',
    data() {
      return {
        rule:[],
        question:[],
        item:false,
        show:false,
        showSubmit:false,
        nowIdx:0,
        num:0,
        info:false,
        show_answer:false,
        result:[],
        sub_question:[],
        userInfo:[],
      }
    },
    created(){
    },
    mounted() {
      this.getUserInfo()
    },
    methods: {
      goHome(){
        this.$router.push('/');
      },
      goGroup(){
        this.$router.push('/my/family');
      },
      getUserInfo(){
        ajax.getUserInfo().then(async res => {
          this.$store.dispatch('lele/user/setUserInfo',res)
          this.userInfo = res
          if(!res.family){
            this.$dialog.confirm({
              title:'提示',
              message: '尚未加入家庭组',
              cancelButtonText: '继续答题',
              confirmButtonText: '加入家庭组'
            }).then(() => {
              this.goGroup()
            }).catch(() => {
              this.makeExam()
            });
          }else{
            this.makeExam()
          }
        })
      },
      toCancel(){
        this.$dialog.confirm({
          title:'提示',
          message: '确定要放弃本次答题吗',
          cancelButtonText: '再试试',
          confirmButtonText: '去意已决'
        }).then(() => {
          this.goHome()
        }).catch(() => {
          return
        });
      },
      repeat(){
        this.show=true
        this.show_answer=false
        this.showSubmit=false
        this.question = []
        this.nowIdx = 0
        this.num = 1
        this.makeExam()
      },
      makeExam(){
        this.nowIdx = 0
        //查找+进入
        ajax.get('/Score/makeExam',{member_id:this.userInfo.member_id,catalog_path:'score_question'}).then(res => {
          this.question = res.question
          this.info = {
            total:res.total,
            per:res.per,
            grade:res.grade,
            yes:res.yes,
            err:res.err
          }
          this.rule=res.rule
          this.item = this.question[0]
          this.show = true
          this.show_answer = false
          this.num = this.nowIdx+1
        }).catch(error=>{
          this.$toast({message:error , duration: 1500})
          return
        })
      },
      prevQues(){
        this.nowIdx--
        this.showSubmit=false

        if(this.nowIdx<0){
          this.nowIdx=0
          this.$toast({message: '已经是第一题了' , duration: 1500})
          return
        }
        this.item = this.question[this.nowIdx]
      },
      nextQues(){
        this.nowIdx++
        if(this.nowIdx==this.question.length){
          this.nowIdx--
          this.showSubmit=true
          return 
        }
        this.item = this.question[this.nowIdx]
      },
      choose(idx){
        this.question[this.nowIdx].answer = idx
        this.question[this.nowIdx].result = idx==this.question[this.nowIdx].right?1:0
        let _this=this
        let msg = '请稍后~'
        if(this.nowIdx+1 == this.info.total){
          this.showSubmit=true
          return
        }
        this.nextQues()
        // this.$toast.loading({message: msg,duration:500,loadingType:'spinner'})
        // setTimeout(function(){
        // },500)
      },
      submitExam(){
        let err=0
        let yes=0
        let sub_question=[]
        for (var i = 0; i < this.question.length; i++) {
          var a = this.question[i]
          if(a.result==1){
            yes++
          }else{
            err++
          }
          sub_question.push({
            id:a.id,
            answer:a.answer,
            right:a.right,
            result:a.result
          })
        }
        
        this.info.grade = yes * this.info.per
        this.info.yes = yes
        this.info.err = err
        this.result.question = sub_question
        this.result.info = this.info 
        let tmp={...this.result}
        console.log(tmp)
          ajax.post('/Score/submitExam',{member_id:this.userInfo.family.member_id,exam:tmp,from:'new_wx'}).then(res => {
            let message='回答正确'+yes+'题'+',回答错误'+err+'题'
            if(res.msg&&res.msg!='提交成功'){
              message+=','+res.msg
            }
            this.$dialog.confirm({
              title:'正确率 '+this.info.grade+'%',
              message: message,
              cancelButtonText: '查看结果',
              confirmButtonText: '再来一次'
            }).then(() => {
              this.nowIdx=0
              this.showSubmit=false
              this.show_answer = false
              this.makeExam()
            }).catch(() => {
              this.show=false
              this.show_answer=true
            });
          }).catch(error=>{
            console.log(error)
            this.$toast({message:error , duration: 1500})
            return
          })
        
      },
    }
  }
</script>

<style scoped >
  .repeat{
    position: fixed;
    z-index: 100000;
    right: 10px;
    top: 5px;
    border-radius: 6px;
    background: rgba(103, 194, 58, 0.78);
    color: #fff;
    width: 80px;
    line-height: 30px;
    height: 30px;
    text-align: center;
  }
  .van-radio__label{display:inline!important;}
  .score_rule{padding:15px 20px;}
  .opt{padding:10px 20px;}
  .opt img{width:100%;margin-bottom:10px;}
  .opt .van-radio{margin:10px 0px;}
  .logout_btn{ padding:20px; }
  .logout_btn .van-checkbox--round{ margin-bottom:10px; }
  .logout_btn h4{ margin-bottom:10px; }
  .yuyue_form .vux-x-input-right-full { line-height:280%;padding-right:.4em; }
  #underway_page .weui-upimg img{ width:2em;height:2em;margin-right:.2em; }
  .cell_space{ display:block;width:100%;height:10px; }
  #underway_page .weui-cells{ margin-top:0 !important; }
  .list{ padding:10px 40px;line-height:40px;text-align:right;border-bottom:1px solid #dedede; }
  .list label{ float:left; }
  .list:last{border-bottom:none;}
  .qrcode{
    width: 50%;
    border-radius: 10px;
    border: 1px solid #dedede;
    background: #fff;
    padding: 10px;
  }
</style>
