<template>
  <div>
    <template v-if="!hasLoad">
      <p><center>请稍后</center></p>
    </template>
    <template v-else>
        <van-nav-bar
          :title="page_title"
          left-text="返回"
          left-arrow
          @click-left="goBack"
        />
        <template>

          <van-tabs sticky v-model="active">
            <van-tab title="配送单信息">
              <van-cell title="配送单号" :value="Delivery.del_no"/>
              <van-cell title="物品名称" :value="Delivery.goods_title"/>
              <van-cell title="规格数量">{{Delivery.sku_name+' * '+Delivery.total_num}}</van-cell>
              <van-cell title="当前状态" :value="Delivery.status_txt"/>
              <van-cell title="创建时间" :value="Delivery.create_time"/>
              <van-cell title="发货时间" v-if="Delivery.status>1" :value="Delivery.send_time"/>
              <van-cell title="提货点入库" v-if="Delivery.status>2" :value="Delivery.in_time"/>
              
              <div style="text-align:center;padding:0 20px;margin-top:10px;" v-if="Delivery.status==2">
                <van-button type="primary" round size="large" @click="putIn">确认入库</van-button>
              </div>
              <div style="text-align:center;padding:0 20px;margin-top:10px;" v-if="Delivery.status==1">
                <van-button type="primary" round size="large" @click="shipOut">确认发货（出库）</van-button>
              </div>
              <div style="text-align:center;padding:0 20px;margin-top:10px;">
                <van-button type="default" round size="large" @click="goBack">返 回</van-button>
              </div>
            </van-tab>
            <van-tab title="相关订单">
              <van-panel v-for="item in Delivery.order_list" :title="item.order_no" :status="item.status_txt">
                <div>{{item.user_name}}-{{item.phone}}，{{item.num}}件 <van-tag @click='putOut(item)' v-if='item.status == 3' style='float: right;' type="primary" plain size="medium">确认收货：{{item.ms_code}}</van-tag></div>
              </van-panel>
            </van-tab>
            <van-tab title="配送记录">
              <van-steps direction="vertical" :active="Delivery.log.length-1">
                <van-step v-for='item in Delivery.log'>
                  <div>【{{item.type_txt}}】{{item.content}}</div>
                  <div>{{item.create_time}}</div>
                </van-step>
              </van-steps>
            </van-tab>
          </van-tabs>
          
        </template>
    </template>
  </div>
</template>

<script>
import * as ajax from "@/api/common"
export default {
  name: 'inOutPage',
  data() {
    return {
      hasLoad:false,
      loading:false,
      Delivery:false,
      act:1,
      page_title:'',
      active:0,
      search:{
        table:'DeliverySum',
        keyword:'',
      },
    }
  },
  mounted () {
    this.search.keyword = this.$route.query.del_no?this.$route.query.del_no:''
    this.act = this.$route.query.act?this.$route.query.act:1
    if(this.act==1){
      this.page_title='入库确认'
    }else{
      this.page_title='配送单信息'
    }
    if(!this.search.keyword){
      this.$toast('缺少配送单号')
      return false
    }
    this.getDetail()
  },
  methods:{
    goBack(){
      if(this.act==2){
        this.$router.go(-1)
        return
      }
      this.$router.push('/my')
    },
        putOut(item){
      this.$dialog.confirm({
        title: '确认',
        message: '确认 '+item.user_name+' 已取 '+this.Delivery.goods_title+'?'
      }).then(() => {
        ajax.get('/Base/deliveryPutOut',{ms_code:item.ms_code,table:'DeliverySum',showTips:true}).then(res=>{
          let _this = this
          setTimeout(()=>{
            _this.goBack()
          },1500)
        })
      }).catch(() => {
        this.$toast('放弃')
      });
    },
    getDetail(){
      this.search.showTips=true
      ajax.get('/Base/DeliverySum/detail',this.search).then(async res => {
        this.Delivery=res
        this.hasLoad=true
      }).catch(err=>{
        this.$toast.fail(err.msg)
        let _this=this
        setTimeout(()=>{
          _this.goBack()
        },1500)
      })
    },
    putIn(){
      this.$dialog.confirm({
        title: '确认',
        message: '确认包裹已到达提货点吗'
      }).then(() => {
        ajax.get('/Base/deliveryPutIn',{del_no:this.Delivery.del_no,table:'DeliverySum',showTips:true}).then(res=>{
          let _this = this
          setTimeout(()=>{
            _this.getDetail()
          },500)
        })
      }).catch(() => {
        this.$toast('放弃')
      });
    },
    shipOut(){
      this.$dialog.confirm({
        title: '确认',
        message: '确认发货（出库）吗'
      }).then(() => {
        ajax.get('/Base/deliveryShipments',{del_no:this.Delivery.del_no,table:'DeliverySum',showTips:true}).then(res=>{
          let _this = this
          setTimeout(()=>{
            _this.$router.push('/points/info')
          },500)
        })
      }).catch(() => {
        this.$toast('放弃')
      });
    },
  }
}
</script>

<style scoped>
.user{background:#07c160;padding:20px 0;color:#fff;}
.van-panel__content{
  font-size:14px;
  line-height:24px;
  padding:15px;
}
.van-cell:not(:last-child)::after{left:0px!important;}
</style>
