import { Dialog,Notify,Toast } from 'vant';
let message={}

message.success = function(config){
  if (config && config.success) {
      if (config.success.type === 'message') {
          Dialog(config.success.options)
      } else if (config.success.type === 'alert') {
          Dialog.alert(config.success.options)
      } else if (config.success.type === 'confirm') {
          Dialog.alert(config.success.options)
      } else if (config.success.type === 'notify') {
          Notify(config.success.options)
      } else if (config.success.type === 'toast') {
          Toast.success(config.success.options)
      }
  }
}

message.fail = function(config){
  if (config && config.fail) {
          if (config.fail.type === 'message') {
              Dialog(config.fail.options)
          } else if (config.fail.type === 'alert') {
              Dialog.alert(config.fail.options)
          } else if (config.fail.type === 'confirm') {
              Dialog.alert(config.fail.options)
          } else if (config.fail.type === 'notify') {
              Notify(config.fail.options)
          } else if (config.fail.type === 'toast') {
              Toast.fail(config.fail.options)
          }
      }
}

export default message