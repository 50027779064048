<template>
  <div>
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
      <van-cell-group title="今日订单统计">
        <van-cell style='padding-left: 0px;padding-right: 0px;'>
            <div class="chart-wrapper">
              <!-- <ve-ring :data="chartData1"></ve-ring> -->
              <ve-histogram :data="chartData1" height='250px'></ve-histogram>
            </div>
        </van-cell>
      </van-cell-group>

      <van-cell-group title="一周销量趋势">
        <van-cell style='padding-left: 0px;padding-right: 0px;'>
            <div class="chart-wrapper">
              <ve-line :data="chartData" height='250px'></ve-line>
            </div>
        </van-cell>
      </van-cell-group>
      <van-cell-group title="商品销量统计">
        <van-cell  style='padding-left: 0px;padding-right: 0px;' >
            <div class="chart-wrapper">
              <ve-histogram :data="chartData2"  height='250px'></ve-histogram>
  </div>
        </van-cell>
      </van-cell-group>
      </van-pull-refresh>
    </div>
</template>

<script>
import * as ajax from "@/api/common"
import util from '@/libs/util'
export default {
  name: 'mall_stat',
  data() {
    return {
        count: 0,
        isLoading: false,
        chartData: null,
        chartData2:null,
        chartData1:null
      }
  },
  methods:{
    onLoad(foo){
      ajax.post('/base/getMallStat',{}).then(res=>{
        this.chartData = res.chartData
        this.chartData1 = res.chartData1
        this.chartData2 = res.chartData2

          if(foo)
          {
                    this.$toast('刷新成功');
                    this.isLoading = false;
                    this.count++;
          }
          this.$toast('获取数据成功');
      }).catch(e=>{
          if(foo)
          {
                  this.isLoading = false;
                  this.count++;
          }

           this.$toast(e.msg);
      })
    },
    onRefresh() {
      this.onLoad(true)
    },
  },
  mounted(){
    this.onLoad(false)
  },
  created(){
  }
}
</script>

<style scoped>
.tinfo{color:#fff;}
.scoreList{border-bottom:1px solid #f3f3f3;background:#fff;padding:10px;}
.scoreList .number{font-size:20px;text-align:right;color:#ff6600;}
</style>
