<template>
  <div class="mall">
    <van-sticky>
    <van-nav-bar
      title="商品详情"
      left-text="返回"
      left-arrow
      @click-left="goBack"
    />
    </van-sticky>
    <template v-if="hasLoad">
      <van-swipe class="swipe" :autoplay="3000" style="min-height: 200px;">
        <van-swipe-item v-for="(image, index) in goodsInfo.images" :key="index">
          <img :src="image" />
        </van-swipe-item>
      </van-swipe>
      <div class="goods_title">
        <van-row class="price_info">
          <van-col span="18">
            <span class="price" v-if='goodsInfo.sku.vip_price'><small>积分抵扣价￥</small><b>{{goodsInfo.sku.vip_price}}</b></span> <span class="price_del">市场价￥{{goodsInfo.sku.price}}</span>
          </van-col>
          <van-col span="6" style="text-align:right">
            <div class="sc">
              <van-icon name="like" class="star star_act" v-if="goodsInfo.is_act" @click="changeSc"/>
              <van-icon name="like-o" class="star" v-else @click="changeSc"/>
              <br/>收藏
            </div>
          </van-col>
        </van-row>
        <h1>{{goodsInfo.title}}</h1>
        <div v-if='vip_start'>
        <p v-if="is_vip" class="vip-desc">亲爱的{{name}}，您是乐乐一家VIP用户，所有商品享受批发采购价优惠！</p>
        <p v-else class="not-vip-desc" @click="kownVip">您还不是乐乐一家VIP用户，不能享受批发采购价优惠，点了解乐乐一家VIP？</p>
</div>
        <p>{{goodsInfo.stitle}}</p>
        <div class="tips"><van-icon name="info-o" style="color:#ff6600;margin-right: 5px;" />
          <span v-if='is_special'>现在下单，预计{{goodsInfo.post_day_str?goodsInfo.post_day_str:'明天'}}送达</span>
          <span v-else>今日22:00前下单，次日9：00左右送达</span>
        </div>
      </div>
      <van-divider>部分供应商承诺书及商品介绍</van-divider>
<!--       <img src="../../assets/1_00.png">
      <img src="../../assets/2_00.png"> -->

      <div class="goods_content" v-html="goodsInfo.content"></div>
      <van-goods-action :safe-area-inset-bottom="true">
        <van-goods-action-icon icon="chat-o" text="客服" @click="onClickIcon" />
        <van-goods-action-icon icon="cart-o" text="购物车" @click="goCart"  />
        <van-goods-action-button type="warning" text="加入购物车" @click="viewSku=true" />
        <van-goods-action-button type="danger" text="立即购买" @click="viewSku=true" />
      </van-goods-action>
      <van-sku
        v-model="viewSku"
        :sku="goodsInfo.sku"
        :goods="goodsInfo"
        :goods-id="goodsInfo.id"
        :quota="quota"
        :quota-used="quota_used"
        :hide-stock="goodsInfo.sku.hide_stock"
        :disable-stepper-input="true"
        :close-on-click-overlay="true"
        @stepper-change="stepperChange"
        @add-cart="onAddCartClicked"
        @sku-selected="skuSelected"
        @buy-clicked="onBuyClicked"
      />
    </template>
    <template v-else>
      <p style="text-align:center;padding-top:40px;">加载中...</p>
    </template>
  </div>
</template>

<script>
import * as ajax from "@/api/common"
import util from '@/libs/util'
export default {
  name: 'MallPage',
  data(){
    return {
      is_vip:false,
      vip_start:false,
      name:'',
      has_new:false,
      is_special:false,
      isAct:false,
      viewSku:false,
      hasLoad:false,
      cartNum:'',
      quota:0,
      quota_used:0,
      goodsInfo:{},
      customStepperConfig: {
        // 自定义限购文案
        quotaText: '每次限购xxx件',
        // 自定义步进器超过限制时的回调
        handleOverLimit: (data) => {
          const { action, limitType, quota, quotaUsed } = data;

          if (action === 'minus') {
            this.$toast('至少选择一件商品');
          } else if (action === 'plus') {
            if (limitType === LIMIT_TYPE.QUOTA_LIMIT) {
              let msg = `单次限购${quota}件`;
              if (quotaUsed > 0) msg += `，你已购买${quotaUsed}`;
              this.$toast(msg);
            } else {
              this.$toast('库存不够了');
            }
          }
        },
        // 步进器变化的回调
        handleStepperChange: currentValue => {},
        // 库存
        stockNum: 1999,
        // 格式化库存
        stockFormatter: stockNum => {},
      }
    }
  },
  mounted () {
    this.getDetail()
  },
  methods:{
    goBack(){
      this.$router.go('-1')
    },
    stepperChange(val){
      // console.log(val)
    },
    skuSelected(val){
        //选择规格完成
        this.quota=val.selectedSkuComb?parseInt(val.selectedSkuComb.quota):0
        this.quota_used=val.selectedSkuComb?parseInt(val.selectedSkuComb.quota_used):0
    },
    kownVip()
    {
      this.$router.push({path:"/my/index",query:{from:1}})
    },
    getDetail(){
      util.cookies.set('makeData',null)
      let goods_id = this.$route.query.id
      ajax.getDetail({table:'Goods',id:goods_id,showLoading:false}).then(res=>{
        this.goodsInfo=res
        this.cartNum=res.cartNum
        this.is_vip=res.is_vip
        this.name=res.name
        this.vip_start=res.vip_start
        this.hasLoad=true
        let type_arr = JSON.parse(res.typeid)
        this.is_special = this.in_array(1,type_arr)
      }).catch(e=>{
        this.$toast(e)
        var _this=this
        setTimeout(()=>{
        _this.goBack()
      },800)
        return
      })
    },
    in_array(search,array){
      for(var i in array){
          if(array[i]==search){
              return true;
          }
      }
      return false;
    },
    changeSc(){
      if(this.goodsInfo.is_act){
        this.$dialog.confirm({
          title: '提示',
          message: '确认取消收藏吗？'
        }).then(() => {
          ajax.get('/Base/addCollect',{table:'Collect',item_id:this.goodsInfo.id}).then(res=>{
            if(res==100){
              this.goodsInfo.is_act=false
            }else{
              this.goodsInfo.is_act=true
            }
          })
        }).catch(() => {
          // on cancel
        })
      }else{
        ajax.get('/Base/addCollect',{table:'Collect',item_id:this.goodsInfo.id}).then(res=>{
          if(res==100){
            this.goodsInfo.is_act=false
          }else{
            this.goodsInfo.is_act=true
          }
        })
      }
      
    },
    onClickIcon() {
      this.$router.push('/feedBack')
    },
    goCart(){
      this.$router.push({
        path:'cart'
      })
    },
    onClickButton() {
      this.viewSku=true
    },
    onBuyClicked(val){
      if(val.selectedSkuComb.price<1){
        this.$toast('该产品金额为0,暂不可购买')
        return
      }
      var tmp={
        list:[{
          id:0,
          num:val.selectedNum,
          goods_id:val.goodsId,
          goods_title:this.goodsInfo.title,
          path:this.goodsInfo.path,
          post_day:this.goodsInfo.post_day,
          post_day_str:this.goodsInfo.post_day_str,
          content:{
            market_price:val.selectedSkuComb.market_price,
            normal_price:val.selectedSkuComb.normal_price,
            vip_price:val.selectedSkuComb.vip_price,
            cost_price:val.selectedSkuComb.cost_price,
            max:val.selectedSkuComb.max,
            num:val.selectedNum,
            price:val.selectedSkuComb.price/100,
            score:val.selectedSkuComb.score,
            sku:val.selectedSkuComb.sku,
            sku_name:val.selectedSkuComb.sku_name,
            stock_num:val.selectedSkuComb.stock_num,
            supplier_id:val.selectedSkuComb.supplier_id,
          },
        }],
        total:val.selectedSkuComb.price*val.selectedNum
      }
window.localStorage.setItem("makeData",JSON.stringify(tmp))
      this.$router.push('/mall/makeOrder')
    },
    onAddCartClicked(val){
      this.$toast('加入成功')
      this.viewSku=false
      var save_data = val
      if(val.selectedSkuComb.price<1){
        this.$toast('该产品金额为0,不可加入购物车')
        return
      }
      ajax.post('/Base/addToCart',{table:'Cart',save_data:save_data}).then(res=>{
        this.cartNum = this.cartNum+parseInt(val.selectedNum)
      })
    }
  }
}
</script>
<style>
.van-sku-row__item img{width:30px!important;height:30px!important;}
.goods_content p img{display: block; margin: 0px!important;padding:0px!important;border: none;}
.goods_content p{margin: 0px!important;padding:0px!important;margin-block-start:0px!important;margin-block-end:0px!important;}
</style>
<style scoped>
.tips{height: 30px;line-height: 30px;color: #ff6600;font-size: 13px;clear: both;}
.van-goods-action{z-index:999;}
.swipe img{width:100%;height: auto;}
.goods_title{background:#fff;padding:10px;border-bottom:1px solid #f3f3f3;}
.goods_title .price_info{}
.goods_title .price_info .price{color:#ff0000;font-size:30px;font-weight: bold;}
.goods_title .price_info small{font-size:14px!important;}
.goods_title .price_info .price_del{font-size:14px;font-weight: bold;}
.goods_title .star{font-size:20px;color:#999;}
.goods_title .star_act{color:#ff0000;}
.sc{font-size:10px;color:#666;text-align:center;line-height:10px;float:right;width:50px;}
.goods_title h1{font-weight:300;color:#000;font-size:20px;}
.goods_title p{padding:0px;margin:0px;color:#666;font-size:14px;line-height:20px;}
.goods_content{}
.goods_content img{width:100%;margin:0;padding:0;}
.goods_title .not-vip-desc{box-sizing: border-box;border: 1px solid #EF0F25;padding: 5px;margin-bottom: 10px;font-weight:bold;padding: 1em 0em;padding-left:5px}

.goods_title .vip-desc{font-weight:bold;margin-bottom: 10px;background: linear-gradient(to right,#fff, #FFD700);padding: 1.5em 0em;}
</style>
