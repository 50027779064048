<template>
  <div>
    <van-nav-bar
      title="商城订单"
      left-text="返回"
      left-arrow
      style="display:none"
      @click-left="goBack"
    />
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
      <van-tabs sticky v-model="active" @click="onRefresh">
        <van-tab :title="type.title" v-for="(type,idx) in status_arr">
          <van-list
            v-if="idx==active"
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <van-panel v-for="item in list" :title="item.goods_title" :status="item.status_txt2" style="margin-bottom:20px!important;">
              <div class="orderCon">
                <div>订单号：{{item.order_no}}</div>
                <div>投递重量：{{item.user_weight}}公斤</div>
				<div v-if="box_arr.indexOf(item.o_box_id)>-1">
                <div>投递价值：{{Math.ceil(item.user_total*100)}} 积分<span v-if="item.status>5&&item.status<9" style="color: #cc0000;">(复核 {{Math.ceil(item.fj_total*100)}}积分)</span></div>
				</div>
				<div v-else><div>投递金额：￥{{item.user_total}} <span v-if="item.status>5&&item.status<9" style="color: #cc0000;">(复核 ￥{{item.fj_total}})</span></div></div>
                <div>投递时间：{{item.create_time}}</div>
                <div>清运时间：{{item.qy_time}}</div>
              </div>
              <div slot="footer" style="text-align:right">
                <van-button size="small" type="primary" @click="showLogs(item.log)" style="margin-right:10px;">进度查询</van-button>
                <van-button size="small" @click="feedBack">联系客服</van-button>
              </div>
            </van-panel>
          </van-list>
        </van-tab>
      </van-tabs>
    </van-pull-refresh>
    <van-popup v-model="infoVis" :style="{ width: '80%' }">
      <van-steps direction="vertical" :active="logAct">
        <van-step v-for="item in logInfo" v-if="item.user_info">
            【{{item.type_txt}}】{{item.user_info.name}}<br/>
            {{item.create_time}}
        </van-step>
      </van-steps>
    </van-popup>
  </div>
</template>

<script>
import * as ajax from "@/api/common"
import util from '@/libs/util'
export default {
  name: 'recycleOrderPage',
  data() {
    return {
      filter:{
        status:0,
        company_id:0,
      },
      page:{
        current_page:0,
        page_total:1,
        count:0,
        page_size:10,
      },
      list:[],
      logInfo:[],
      active:0,
      logAct:0,
      loading:false,
      finished:false,
      activeNames:1,
      isLoading:false,
      infoVis:false,
      status_arr:[
      {
        status:0,
        title:'全部',
      },{
        status:2,
        title:'待清运',
      },{
        status:3,
        title:'分拣复核',
      },{
        status:7,
        title:'待付款',
      },{
        status:8,
        title:'已完成',
      }],
	  box_arr: [9523,9916,9917,9918,9919,9920,9927]
    }
  },
  mounted () {
  },
  methods:{
    goBack(){
      this.$router.go(-1)
    },
    feedBack(){
      this.$router.push('/feedBack')
    },
    showLogs(logs){
      this.logInfo=logs
      logs.map((item,i)=>{
        if(item.user_info){
          this.logAct=i
        }
      })
      this.infoVis=true
    },
    onLoad(){
      this.isLoading = true;
      this.loading = true
      this.filter.status = this.status_arr[this.active].status
      //新系统
      ajax.getList({
        table:'Order',
        scence:'mg_order',
        page:parseInt(this.page.current_page)+1,
        page_size:this.page.page_size,
        filter:this.filter
      }).then(res=>{
        this.loading=false
        this.isLoading = false;
        if(parseInt(res.current_page)>=res.last_page){
          this.finished=true
        }
        res.data.map(item=>{
          this.list.push(item)
        })
      }).catch(e=>{
        this.isLoading = false;
        this.finished=true
      })
    },
    onRefresh() {
      this.list=[]
      this.page.current_page=0
      this.isLoading = false;
      this.loading = true;
      this.finished = false;
      this.onLoad()
    }
  }
}
</script>

<style scoped>
.tinfo{color:#fff;}
.orderCon{line-height:1.5rem;padding:10px;font-size:14px;}
.scoreList{border-bottom:1px solid #f3f3f3;background:#fff;padding:10px;}
.scoreList .number{font-size:20px;text-align:right;color:#ff6600;}
</style>
