<template>
  <div class="demo">
    <van-icon name="smile-o" color="#ddd" size="70" />
    <div style="text-align:center">页面不存在</div>
  </div>
</template>

<script>
export default {
  name: 'ErrorPage',
  data(){
    return {
    }
  },
  mounted () {
    this.showMsg()
  },
  methods:{
    showMsg(){
      let _this =this
      _this.active = 0
      this.$toast({message:'页面未找到',duration:1500,type:"fail",onClose:function(){
        _this.$router.replace('/')
      }});
    }
  }
}
</script>

<style scoped>
.demo{text-align:center;}
</style>
