<template>
  <div>
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
      <template v-if="hasLoad">
        <van-sticky>
          <van-cell style="background:#07c160;border-bottom:1px solid #f3f3f3;">
              <van-row class="tinfo">
                <van-col span="18" style="font-size: 16px;">
                  环保金币 {{userInfo.score_info.coin}}
                </van-col>
                <van-col span="6" style="text-align:right" v-if="userInfo.can_tx">
                  <van-button type="default" size="mini" @click="withdrawal">提现</van-button>
                </van-col>
              </van-row>
          </van-cell>
        </van-sticky>
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
        <van-row class="coinlist" v-for="(item,idx) in list" :key="idx">
          <van-col span="18" style="font-size: 12px;">
            <div style="height: 30px;line-height: 30px;color: #333;font-size: 14px;">{{item.remark}}</div>
            {{item.user_name}}-{{item.create_time}}
          </van-col>
          <van-col span="6">
            <div class="number" style="line-height: 50px;">
              {{item.tag_str}}{{item.pay}}
            </div>
          </van-col>
        </van-row>
        </van-list>
      </template>
      <template v-else>
        <p>
          <center>请稍后</center>
        </p>
      </template>
    </van-pull-refresh>
  </div>
</template>

<script>
import * as ajax from "@/api/common"
import util from '@/libs/util'
export default {
  name: 'coinlistPage',
  data() {
    return {
      list:[],
      loading:false,
      finished:false,
      activeNames:1,
      userInfo:false,
      isLoading:false,
      hasLoad:false,
      filter:{

      },
      page:{
        current_page:0,
        page_total:1,
        count:0,
        page_size:10,
      },
    }
  },
  mounted () {
    this.getUserInfo()
  },
  methods:{
    goBack(){
      this.$router.go(-1)
    },
    withdrawal(){
      this.$router.push('withdrawal')
    },
    getUserInfo(){
      ajax.getUserInfo().then(async res => {
        util.cookies.set('uuid', res.id)
        util.cookies.set('token', res.token)
        this.$store.dispatch('lele/user/setUserInfo',res)
        this.userInfo = res
        this.hasLoad=true
      })
    },
    onLoad(){
      this.loading = true
      this.page.current_page=parseInt(this.page.current_page) + 1
      ajax.getList({
        table:'CoinList',
        scence:'mg_coinlist',
        page:this.page.current_page,
        page_size:this.page.page_size,
        filter:this.filter
      }).then(res=>{
        this.loading=false
        res.data.map(item=>{
          this.list.push(item)
        })
        if(parseInt(res.current_page)>=res.last_page){
          this.finished=true
        }
      }).catch(e=>{
        this.$toast(e.msg)
      })
    },
    onRefresh() {
      this.list=[]
      this.page={
        current_page:0,
        page_total:1,
        count:0,
        page_size:10,
      }
      this.isLoading = false;
      this.loading = true;
      this.finished = false;
      this.onLoad()
    }
  }
}
</script>

<style scoped>
.tinfo{color:#fff;}
.coinlist{border-bottom:1px solid #f3f3f3;background:#fff;padding:10px;}
.coinlist .number{font-size:20px;text-align:right;color:#ff6600;}
</style>
