<template>
  <div>
    <van-nav-bar
      title="商城订单"
      left-text="返回"
      left-arrow
      style="display:none"
      @click-left="goBack"
    />
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
      <van-tabs sticky v-model="active" @click="onRefresh">
        <van-tab :title="type.title" v-for="(type,idx) in status_arr">
          <van-list
            v-if="idx==active"
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <van-panel v-for="item in list" :title="item.gc_name" :status="item.status_txt" style="margin-bottom:20px!important;">
              <div class="orderCon">
                <div>订单号：{{item.o_id}}</div>
                <div>投递重量：{{item.o_user_weight}}公斤</div>
				<div v-if="box_arr.indexOf(item.o_box_id)>-1">
					<div v-if="item.o_status<6">
						投递积分：{{item.o_valuation_money*100}}
						</div>
					<div v-else>
						投递积分：{{item.o_money*100}}
						</div>
				</div>
				<div v-else>
					<div v-if="item.o_status<6">
						投递金额：{{item.o_valuation_money}}
						</div>
					<div v-else>
						投递金额：{{item.o_money}}
						</div>
				</div>
                <div>投递时间：{{item.o_time2}}</div>
                <div>清运时间：{{item.o_bag_down_time?item.o_bag_down_time:'未清运'}}</div>
              </div>
              <div slot="footer" style="text-align:right">
                <van-button size="small" type="primary" @click="viewOldOrder(item.o_id)" style="margin-right:10px;">进度查询</van-button>
                <van-button size="small" @click="feedBack">联系客服</van-button>
              </div>
            </van-panel>
          </van-list>
        </van-tab>
      </van-tabs>
    </van-pull-refresh>
    <van-popup v-model="infoVis" :style="{ width: '80%' }">
    <van-steps direction="vertical" :active="logAct">
      <van-step v-for="item in logInfo">
        【{{item.type_txt}}】{{item.content}}<br/>
        <span v-if="item.user_info">By {{item.user_info.name}}<br/></span>
        {{item.create_time}}
      </van-step>
    </van-steps>
    </van-popup>

    <van-popup v-model="step_pop" :style="{ width: '80%' }" closeable>
      <van-steps direction="vertical" :active="0" style="width:80%;" v-if=" !step_loading ">
        <van-step v-for=" (item,index) in step_arr " :key="index">
          <div>{{item.text}}</div>
          <p>{{item.date}}</p>
        </van-step>
      </van-steps>
      <div style="width:30px; margin:10px auto; ">
        <van-loading v-if=" step_loading " />
      </div>
    </van-popup>
    
  </div>
</template>

<script>
import * as ajax from "@/api/common"
import util from '@/libs/util'
export default {
  name: 'recycleOrderPage',
  data() {
    return {
      filter:{
        status:-1,
      },
      page:{
        current_page:0,
        page_total:1,
        count:0,
        page_size:10,
      },
      list:[],
      logInfo:[],
      active:0,
      logAct:0,
      loading:false,
      finished:false,
      activeNames:1,
      userInfo:false,
      isLoading:false,
      infoVis:false,
      tmpArr:[],
      status_arr:[
      {
        status:0,
        title:'全部',
      },{
        status:2,
        title:'待清运',
      },{
        status:4,
        title:'待复核',
      },{
        status:6,
        title:'待付款',
      },{
        status:7,
        title:'已完成',
      }],
      step_arr:[],
      step_pop:false,
      step_loading:false,
	  box_arr: [9523,9916,9917,9918,9919,9920,9927,9841]
    }
  },
  mounted () {
    util.cookies.remove('yuyueData')
    this.tmpArr=this.status_arr.filter((el, index) => {
      el.list=[]
      return el
    })
  },
  methods:{
    goBack(){
      this.$router.go(-1)
    },
    feedBack(){
      this.$router.push('/feedBack')
    },
    viewOldOrder(o_id){
      this.step_loading=true
      ajax.get('/Old/getOrderInfo',{o_id:o_id}).then(res => {
        this.step_loading=false
        this.handle_step_data(res.orderInfo)
      });
    },
    handle_step_data(i){
        var tmp = [];
        if (  i.o_time2 ) {
          tmp.unshift({
            text:"已投放",
            date:i.o_time2
          });
        }
        if (  i.o_time3 ) {
          tmp.unshift({
            text:"已取消",
            date:i.o_time3
          });
        }
        if (  i.o_time4 ) {
          tmp.unshift({
            text:"已清运",
            date:i.o_time4
          });
        }
        if (  i.o_time5 ) {
          tmp.unshift({
            text:"已复核",
            date:i.o_time5
          });
        }
        if (  i.o_time6 ) {
          tmp.unshift({
            text:"待打款",
            date:i.o_time6
          });
        }
        if (  i.o_time7 ) {
          tmp.unshift({
            text:"已打款",
            date:i.o_time7
          });
        }
        this.step_arr = tmp;
        this.step_pop=true
      },
    showInfo(info){
      this.logInfo=info
      for (var i = 0; i < info.length; i++) {
        if(info[i].user_info){
          this.logAct=i
        }
      }
      this.infoVis=true
    },
    makeList(){
      this.list = this.tmpArr[this.active].list
    },
    onLoad(){
      this.isLoading = true;
      this.loading = true

      ajax.get('/Old/getOldOrderList',{
        page:parseInt(this.page.current_page)+1,
        page_size:this.page.page_size,
        status:this.active
      }).then(res=>{
        this.isLoading = false;
        this.loading = false
        this.page.page_total = res.last_page
        this.page.current_page = res.current_page
        this.page.count = res.total_count

        for (var i = 0; i <res.list.length; i++) {
          this.list.push(res.list[i])
        }
        // this.makeList()
        if(res.last_page<=res.current_page){
          this.finished = true;
        }
      }).catch(e=>{
        console.log(e)
        this.finished=true
      })
    },
    onRefresh() {
      this.list=[]
      this.tmpArr=[]
      this.tmpArr=this.status_arr.filter((el, index) => {
        el.list=[]
        return el
      })
      this.page.current_page=0
      this.isLoading = false;
      this.loading = true;
      this.finished = false;
      this.onLoad()
    }
  }
}
</script>

<style scoped>
.tinfo{color:#fff;}
.orderCon{line-height:1.5rem;padding:10px;font-size:14px;}
.scoreList{border-bottom:1px solid #f3f3f3;background:#fff;padding:10px;}
.scoreList .number{font-size:20px;text-align:right;color:#ff6600;}
</style>
