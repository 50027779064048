<template>
  <div>
<!--     	     <van-popup v-model="good_news">
        <img src="@/assets/good_news.png" alt="">
    </van-popup> -->

    <template v-if="!showMall">
      <div v-if="hasLoad"><p><center>正在开发中~</center></p></div>
    </template>
    <template v-else>
      <div style="position: fixed;bottom: 150px;right: 10px;z-index: 2;width:80px"  @click="$router.push('/mall/cart')">
        <img src="../../assets/cart.png" alt="" sizes="" srcset="">
      </div>

      <div class="mall" v-if="hasLoad">
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <form action="/">
          <van-search placeholder="输入要找的商品名" :maxlength="10" v-model="searchForm.keys" @search="onSearch" />
        </form>
        <div style="display: none;">
          <div style="height: 50px;height: 50px;text-align: center;line-height: 50px;background: #ff6600;color: #fff;" @click="goSecKill">限时秒杀</div>
          <div style="height: 50px;height: 50px;text-align: center;line-height: 50px;background: #ff6600;color: #fff;" @click="goVend">积分兑换</div>
        </div>
        <template v-if="banner.length>0">
          <van-swipe class="swipe" :autoplay="3000">
            <van-swipe-item v-for="(image, index) in banner" :key="index">
              <img :src="image.path" />
            </van-swipe-item>
          </van-swipe>
        </template>
        <van-grid :column-num="5" style="margin-top:10px;">
          <van-grid-item
            v-for="(item,idx) in types"
            :key="item.id"
            @click="goUrl('/mall/list',item.id)"
          >
            <van-image :src="item.path" round width="3rem" height="3rem" />
            <span style="font-size:12px;"  :style='{"color":is_vip?"#FFD700":""}'>{{item.typename}}</span>
          </van-grid-item>
        </van-grid>
        <template v-for="spec in special" v-if="spec.goods_list.length>0">
          <div class="spec_img" v-if="spec.path!='' && spec.show_img==1"><img :src="spec.path"/></div>
          <van-divider v-else>{{spec.title}}</van-divider>
          <van-row gutter="10" style="margin:0 8px;">
            <van-col span="12" class="item" v-for="(item,idx) in spec.goods_list" @click="viewDetail(item.id)">
              <div class="wrapper">
  <!--           <van-image
                width="100%"
                height="150px"
                fit="cover"
                :src="item.path"
              /> -->
			  <img src="" alt="" width="100%" height="150px" v-lazy="item.path">
              <div class="info">
                <div class="goods_title" :style='{"color":is_vip?"#FFD700":""}'>{{item.title}}</div>
                <div>
                  <span class="price" v-if='item.vip_price'><b>积分抵扣价￥{{item.vip_price}}</b></span>
                  <span class="price_del">市场价{{item.price}}</span>
                </div>
              </div>
              </div>
            </van-col>
          </van-row>
        </template>
        </van-pull-refresh>
      </div>
      <div v-else>
        <p><center>请稍后~</center></p>
      </div>
    </template>
<!--     <van-popup v-model="show_notice" style="width: 90%; height: 70%">
      <van-panel :title="notice.title">{{notice.content}}
      </van-panel>
    </van-popup> -->
  </div>
</template>

<script>
import * as ajax from "@/api/common"
import util from '@/libs/util'
import store from '@/store'
export default {
  name: 'MallPage',
  data(){
    return {
      show_notice:false,
      good_news:true,
      is_vip:false,
      hasLoad:false,
      loading:false,
      isLoading:false,
      finished:false,
      showMall:false,
      showVend:false,
      vendId:'',
      special:[],
      types:[],
      banner:[],
      searchForm:{
        keys:''
      },
      notice:{
        title:'',
        content:''
      }
    }
  },
  mounted () {
    util.cookies.remove('yuyueData')
    this.getIndex()
    this.$nextTick(() => {
       setTimeout(() => {
          let targetbox= document.getElementById('app');
          this.target= targetbox.offsetTop
     })
    })
  },
  activated() {
    console.log(this.target)
    document.getElementById('app').scrollTop = this.target // 设置滚动条位置
  },
  methods:{
    goSecKill(){
      this.$router.push({path:'/secKill'})
    },
    goVend(){
      this.$router.push({path:`/vend/detail`})
    },
    goUrl(url,id,idx){
      this.$router.push({path:url,query:{typeid:id}})
    },
    viewDetail(val){
      this.$router.push({
        path:'detail',
        query:{
          id:val
        }
      })
    },
    getIndex(){
      ajax.get('/Base/mallIndex',{table:'GoodsType'}).then(res=>{
        this.types=res.types
        this.banner=res.banner
        this.special=res.special
        this.showMall=res.showMall
        this.hasLoad=true
        this.isLoading=false
        this.is_vip=res.is_vip
        this.show_notice = res.show_notice
        this.notice = res.notice
        this.$store.commit('lele/user/setIsVip',res.is_vip)
      }).catch(e=>{
        console.log(e)
      })
    },
    onLoad(){
      this.loading = true
      this.page.current_page=parseInt(this.page.current_page) + 1
      ajax.getList({
        table:'Goods',
        page:this.page.current_page,
        page_size:this.page.page_size,
        scence:'mg_goods_index',
        filter:[]
      }).then(res=>{
        this.loading = false
        this.isLoading=false
        this.page.page_total = res.last_page
        this.page.current_page = res.current_page
        this.page.count = res.total
        
        for (var i = 0; i<res.data.length; i++) {
          this.list.push(res.data[i]);
        }

        if(res.last_page==res.current_page){
          this.finished = true;
        }
      })
    },
    onSearch(val){
      if(!val){
        this.$toast('请输入关键词')
        return
      }
      this.$router.push({
        path:'/mall/list',
        query:{
          typeid:0,
          keyword:val
        }
      })
    },
    goAdUrl(idx){
      let url = this.banner[idx].url
      if(url!=''&&url!='#'){
        location.href=url
      }
    },
    onRefresh() {
      this.getIndex()
      // this.list=[]
      // this.page.current_page=1
      // this.isLoading = false
      // this.loading = true
      // this.finished = false
      // this.onLoad()
      this.$toast('刷新成功')
    }
  }
}
</script>

<style scoped>
.swipe img{width:100%;}
.spec_img{width: 100%;height: auto;margin:6px auto;}
.goods_title{
  font-size:14px;color:#000;display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.item{background:#fff;box-sizing: border-box;margin-bottom: 1em;}
.item .info{
  text-align: center;
}
.item .price{color:#ff0000;font-weight:200;}
.item .price b{font-size:12px;font-weight:bold;;color: #EE0C24;margin-right: 3px;}
.item .price_del{font-weight:bold;font-size:12px;}
.wrapper{
  padding:20px 3px;
  /*border: 1px solid #EE0C24;*/
  box-shadow:0px 10px 20px #DFDFE1; -webkit-box-shadow:0px 10px 20px #DFDFE1; box-shadow:0px 10px 20px #DFDFE1;
  box-sizing: border-box;
}
.van-popup
{
  background: none;
}
.van-popup--center{
  width: 90%;
}
.van-cell__title
{
  text-align: center;
}
</style>
