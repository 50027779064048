<template>
  <div>
    <van-nav-bar
      title="商城订单"
      left-text="返回"
      left-arrow
      style="display:none"
      @click-left="goBack"
    />
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
      <van-tabs sticky v-model="active" @click="onRefresh">
        <van-tab :title="type.title" v-for="(type,idx) in 
      status_arr">
          <van-list
            v-if="idx==active"
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
          >
                    <form action="/">
  <van-search
    v-model="searchValue"
    show-action
    placeholder="订单号/手机号"
    @search="onSearch"
    @cancel="onCancel"
  />
</form>
            <van-panel v-for="(item,index) in list" :title="item.order_no" :status="item.status_txt" style="margin-bottom:20px!important;font-size:12px;-moz-box-shadow:0px 7px 5px #B8B6B6; -webkit-box-shadow:0px 7px 5px #B8B6B6; box-shadow:0px 7px 5px #B8B6B6;">
               <div><van-tag type="danger">提货点：{{item.points_info.title}}</van-tag></div>
<!--               <div class="orderCon" v-for="(v,k) in item.goods_info">
                {{v.goods_title}} * {{v.num}}
              </div> -->

              <!-- 信息区域 -->
<!-- <div class="info-area van-panel__content" style="background: #fff;font-size: 12px"> -->
  <div style="background: #F8F8F8;padding-bottom: 12px;">
<div  class="flex" style="border-bottom: 2px solid #ebedf0"><span class="flex-ite flex-first">商品名</span><span class="flex-ite" style="text-align: left">规格</span><span class="flex-ite">数量</span></div>
<div v-for='(item,index) in item.goods_info' class="flex"><span class="flex-ite flex-first">{{item.goods_title}}</span><span class="flex-ite" style="text-align: left">{{item.sku_name}}</span><span class="flex-ite">{{item.num}}</span></div>
</div>
<!-- </div> -->
<!-- 信息区域 -->
<div  class="flex" style="background: #F8F8F8;margin-top: 12px;border-bottom: 2px solid #ebedf0">详细收货信息</div>

              <div class="userInfo " style="background: #F8F8F8;font-size: 12px;padding: 3px 0px;">
              <div>{{item.address.name}} | {{item.address.phone}}</div>
              <div>{{item.address.province}}{{item.address.city}}{{item.address.area}}{{item.address.address}}</div>
              <!-- <div v-if='item.status >= 2'>单号：{{item.kuaidi}}</div> -->
              </div>
              <div slot="footer" style="height: 30px;">
                  创建时间 {{item.create_time}}
                <!-- <van-button class="bt" size="small" @click='setShow(index)'>详情</van-button> -->
              </div>
              <div>
<!--             <van-tag v-if='item.status == 1' type="success" @click='setShow(index)' size='medium' medium>立即发货</van-tag>
            <van-tag v-if='item.status == 2' type="success" @click='qianshou(item)' size='medium' medium>设置签收</van-tag>
            <van-field
  v-model="kuaidi"
  center
  placeholder="请输入快递单号"
  style='padding: 0px;margin-top: 5px;background: #F8F8F8'
  v-if='current == index'
>
  <template #button>
    <van-button size="small" type="primary" @click='fahuo(item)'>确认</van-button>
  </template> -->
</van-field>
</div>
            </van-panel>
          </van-list>
        </van-tab>
      </van-tabs>
    </van-pull-refresh>
    <van-popup v-model="infoVis">
    <van-steps direction="vertical" :active="logAct">
      <van-step v-for="item in logInfo">
        【{{item.type_txt}}】{{item.content}}<br/>
        <span v-if="item.user_info">By {{item.user_info.name}}<br/></span>
        {{item.create_time}}
      </van-step>
    </van-steps>
    </van-popup>
  </div>
</template>

<script>
import * as ajax from "@/api/common"
import { Notify } from 'vant';
import util from '@/libs/util'
export default {
  name: 'infoPage',
  data() {
    return {
      filter:{
        status:-1,
      },
      current:-1,
      page:{
        current_page:0,
        page_total:1,
        count:0,
        page_size:10,
      },
      searchValue:'',
      show:false,
      list:[],
      logInfo:[],
      active:0,
      logAct:0,
      kuaidi:'',
      loading:false,
      finished:false,
      activeNames:1,
      userInfo:false,
      isLoading:false,
      infoVis:false,
      tmpArr:[],
      status_arr:[
      {
        title:'全部',
      },{
        title:'待付款'
      },
      {
        title:'备货中',
      },{
        title:'待收货',
      },{
        title:'已收货',
      }]
    }
  },
  mounted () {
    this.tmpArr=this.status_arr.filter((el, index) => {
      el.list=[]
      return el
    })
    this.active=this.$route.query.status?this.$route.query.status:-1
    // this.getType()
  },
  methods:{
    goBack(){
      this.$router.go(-1)
    },
    setShow(index){
      if(this.current == index)
      {
        this.current = -1;
      }else{
        this.kuaidi = ''
        this.current = index
      }

    },
    fahuo(item)
    {
      if(!this.kuaidi)
      {
        return Notify('快递单号不能为空');
      }
      this.$dialog.confirm({
        title: '确认',
        message: '确认发货?(单号：'+this.kuaidi+')'
      }).then(() => {
        ajax.get('/Base/kuaidiFahuo',{delivery_id:item.id,kuaidi:this.kuaidi,table:'Delivery',showTips:true}).then(res=>{
          let _this = this
          this.onRefresh()
        })
      }).catch(() => {
        this.$toast('放弃')
      });
    },
    qianshou(item)
    {
      this.$dialog.confirm({
        title: '确认',
        message: '确认设置为签收？'
      }).then(() => {
        ajax.get('/Base/kuaidiQianshou',{delivery_id:item.id,table:'Delivery',showTips:true}).then(res=>{
          let _this = this
          this.onRefresh()
        })
      }).catch(() => {
        this.$toast('放弃')
      });
    },
    viewOrder(row){
     this.$router.push({
      path:'/points/putIn',
      query:{
        del_no:row.del_no,
        act:2
      }
     })
    },
    getType(){
      this.loading = true
      ajax.get('/Base/getMallOrderStatus',{
        table:'MallOrder',
      }).then(res=>{
        this.loading = false
        this.status_arr = res
        this.onLoad()
      }).catch(e=>{
        this.$toast(e.msg)
        if(e.code==999){
          let _this=this
          setTimeout(a=>{
            this.$router.go(-1)
            return
          },1500)
        }
        this.loading = false
      })
    },
    makeList(){
      this.list = this.tmpArr[this.active].list
    },
    onLoad(){
      this.filter.status=this.active
      this.filter.searchValue = this.searchValue
      this.loading = true
      this.page.current_page=parseInt(this.page.current_page) + 1
      this.current = -1
      ajax.getList({
        table:'MallOrder',
        scence:'my_mallorder_list',
        page:this.page.current_page,
        filter:this.filter
      }).then(res=>{
        this.loading = false
        this.page.page_total = res.last_page
        this.page.current_page = res.current_page
        this.page.count = res.total
        for (var i = 0; i <res.data.length; i++) {
          this.tmpArr[this.active].list.push(res.data[i])
        }
        this.makeList()
        if(parseInt(res.last_page)<=parseInt(res.current_page)){
          this.finished = true;
        }
      }).catch(e=>{
        this.$toast(e.msg)
        if(e.code==999){
          let _this=this
          setTimeout(a=>{
            this.$router.go(-1)
            return
          },1500)
        }
        this.loading = false
        this.finished = true;
      })
    },
    onRefresh() {
      this.list=[]
      this.tmpArr=[]
      this.tmpArr=this.status_arr.filter((el, index) => {
        el.list=[]
        return el
      })
      this.isLoading = false;
      this.page.current_page = 0
      this.loading = true;
      this.finished = false;
      this.onLoad()
      this.$toast('刷新成功')
    },
    onSearch(val) {
      this.onRefresh()
    },
    onCancel() {
      this.onRefresh()
    },
  },
}
</script>

<style scoped>
.goods{padding:5px 0;}
.goods_title{
  font-size:12px;color:#000;display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.bt{
  float: right;
}
.van-panel__content{padding:10px 16px!important;}
.flex{
  display: flex;
  justify-content: space-between;
}
.flex-ite{
  width: 10%;
  display: block;
  text-align: right;
}
.flex-first
{
  width: 80%;
  text-align: left;
}
</style>
