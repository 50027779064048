<template>
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
  <div style="height:100%;">
    <van-nav-bar
      :title="page_title"
      left-text="返回"
      right-text="删除"
      left-arrow
      sticky
      @click-left="goBack"
      @click-right="toDel(row.id)"
      v-if="step==3"
    />
    <van-nav-bar
      :title="page_title"
      left-text="返回"
      left-arrow
      sticky
      @click-left="goBack"
      v-else
    />
    <!-- 列表 -->
    <template v-if="step==1">
      <template v-if="list.length>0">
        <van-swipe-cell v-for="(item,idx) in list">
          <van-cell>
            <template slot="title">
              <van-tag plain type="success" v-if="item.is_def">默认</van-tag>
              <span class="custom-title">{{item.name}}-{{item.phone}}</span>
            </template>
            <template slot="label">
              {{item.ssq}}<br/>
              {{item.address}}
            </template>
          </van-cell>

          <template slot="right">
            <van-button square style="height:100%;" type="primary" @click="onEdit(item)" text="编辑"/>
            <van-button square style="height:100%;" type="danger" text="删除" @click="toDel(item.id)"/>
          </template>
        </van-swipe-cell>

      </template>
      <template v-else>
        <center style="padding:30px 0;">没有记录~</center>
      </template>
      <center style="position:fixed;z-index:1000;bottom:0px;left:0px;right:0px;background:#f8f8f8;height:56px;padding:0 10px;"><van-button type="primary" @click="onAdd" round size="large"><van-icon name="plus" /> 增加收货地址</van-button></center>
    </template>
    <!-- 添加/编辑 -->
    <template v-if="step==2||step==3">
      <van-cell-group>
        <van-field
          v-model="row.name"
          required
          clearable
          label="姓名"
          placeholder="收货人姓名"
        />
        <van-field
          v-model="row.phone"
          required
          clearable
          label="电话"
          placeholder="收件人电话"
        />
        <van-field
          v-model="tmpAre"
          required
          disabled
          clearable
          label="地区"
          @click="showAre = true"
          placeholder="请选择省/市/区"
        />
        <van-field
          v-model="row.address"
          required
          clearable
          label="地址"
          placeholder="街道门牌、房号等信息"
        />
        <van-switch-cell v-model="row.is_def" title="设为默认" active-value="1" inactive-value="0"/>
      </van-cell-group>
      <div style="text-align:center;padding:0 20px;margin-top:10px;">
        <van-button type="primary" round size="large" @click="toSave">保 存</van-button>
      </div>
      <div style="text-align:center;padding:0 20px;margin-top:10px;">
        <van-button type="default" round size="large" @click="cancel">取 消</van-button>
      </div>

    </template>
    <van-popup v-model="showAre" position="bottom">
      <van-area
      :area-list="areaList"
      :value="row.code"
      :area-columns-placeholder="['请选择', '请选择', '请选择']"
      title="选择地区"
      @cancel="cancelAre"
      @confirm="confirmAre"
      />
    </van-popup>
  </div>
  </van-pull-refresh>
</template>

<script>
import * as ajax from "@/api/common"
import * as areaData from "@/libs/area"
import util from '@/libs/util'
export default {
  name: 'addressListPage',
  data() {
    return {
      step:1,
      isLoading:false,
      showAre:false,
      tmpAre:'',
      row:{
        id:0,
        name:'',
        phone:'',
        province:'',
        city:'',
        area:'',
        code:'',
        post_code:'',
        address:'',
        is_def:'1',
      },
      list:[],
      areaList:areaData.default,
      page: {
        total:0,
        pageCurrent: 1,
        pageSize: 100,
        pageTotal: 0
      },
      action:false,
      page_title:''
    }
  },
  watch: {
    step(val){
      if(val==1){
        this.page_title='收货地址管理'
      }
      if(val==2){
        this.page_title='新增地址'
      }
      if(val==3){
        this.page_title='编辑地址'
      }
    }
  },
  mounted () {
    this.getList()
    this.checkRoute()
  },
  methods:{
    checkRoute(){
      if(this.$route.query.action=='from_order'||this.$route.query.action=='from_yuyue'){
        this.action=this.$route.query.action
        this.onAdd()
      }else{
        this.step=1
        this.page_title='收货地址管理'
      }
    },
    goBack(){
      if(this.step==2||this.step==3){
        this.step=1
        this.row={}
      }else{
        this.$router.go(-1)
      }
    },
    cancel(){
      if(this.action=='from_order'){
        this.$router.go(-1)
        return
      }
      this.step=1
      this.row={}
    },
    cancelAre(){
      this.showAre=false
    },
    getList(){
      this.row={}
      this.step=1
      let query={
        table:'Address',
        page_size:this.page.pageSize,
        page:this.page.pageCurrent,
        scence:'mg_address'
      }
      ajax.getList(query).then(res => {
        this.loading = false
        if(res){
          this.page.total = res.total
          this.page.pageTotal = res.last_page
          this.list = res.data
        }
      })
    },
    onAdd() {
      this.step=2
    },
    onEdit(item) {
      this.step=3
      this.row={...item}
      this.row.is_def = item.is_def.toString()
      this.tmpAre = item.province+'/'+item.city+'/'+item.area
    },
    confirmAre(obj){
      this.row.province=obj[0].name
      this.row.city=obj[1].name
      this.row.area=obj[2].name
      this.row.code=obj[2].code
      this.tmpAre = obj[0].name+'/'+obj[1].name+'/'+obj[2].name
      this.showAre=false
    },
    toSave(){
      if(!this.row.name){
        this.$toast('请填写收货人')
        return
      }
      if(!this.row.phone){
        this.$toast('请填写联系电话')
        return
      }
      if(!this.row.code){
        this.$toast('请选择区域')
        return
      }
      if(!this.row.address){
        this.$toast('请填写地址')
        return
      }
      let query={
        table:'Address',
        validate:'Address',
        validate_scene:false,
        data:this.row,
        showTips:true
      }
      ajax.addRecord(query).then(res => {
        this.$toast('保存成功')
        if(this.action=='from_order'||this.action=='from_yuyue'){
          this.$router.go(-1)
          return
        }
        util.cookies.set('needPull', true)
        this.getList()
      }).catch(e=>{
        this.$toast(e.msg)
      })
    },
    toDel (val) {
      this.$dialog.confirm({
        title: '提示',
        message: '确认删除该地址吗？'
      }).then(() => {
        this.loading = true
        ajax.toDel({ids:val, table: 'Address'}).then(res => {
          this.getList()
        }).catch(e => {
          this.loading = false
        })
      }).catch(() => {
        // on cancel
      });
    },
    onRefresh() {
      this.list=[]
      this.isLoading = false;
      this.getList()
    }
  }
}
</script>

<style scoped>
.tinfo{color:#fff;}
.scoreList{border-bottom:1px solid #f3f3f3;background:#fff;padding:10px;}
.scoreList .number{font-size:20px;text-align:right;color:#ff6600;}
</style>
