<template>
  <div class="mall">
    <van-sticky>
    <van-nav-bar
      title="商品详情"
      left-text="返回"
      left-arrow
      @click-left="goBack"
    />
    </van-sticky>
    <template v-if="hasLoad">
      <van-swipe class="swipe" :autoplay="3000" style="min-height: 200px;">
        <van-swipe-item v-for="(image, index) in goodsInfo.imgs" :key="index">
          <img :src="image.url" />
        </van-swipe-item>
      </van-swipe>
      <div class="start_info">
        <van-row class="price_info">
          <van-col span="18">
            <span class="price"><small>￥</small><b>{{goodsInfo.price}}</b></span>
            <span class="price_del">￥{{goodsInfo.market_price}}</span>
          </van-col>
          <van-col span="6" style="text-align:right" v-if="goodsInfo.main_status<2">
            <template v-if="goodsInfo.status==1">
              <div class="goods_status" v-if="goodsInfo.main_status==1">距离结束</div>
              <div class="goods_status" v-if="goodsInfo.main_status==0">距离开始</div>
              <div class="times">
                <van-count-down millisecond :time="goodsInfo.etime" format="HH:mm:ss" @finish="getDetail" v-if="goodsInfo.main_status==1" style="color: #fff;" />
                <van-count-down millisecond :time="goodsInfo.stime" format="HH:mm:ss" @finish="getDetail" v-if="goodsInfo.main_status==0" style="color: #fff;" />
              </div>
            </template>
            <template v-else>
              <div class="goods_status">{{goodsInfo.status_txt}}</div>
            </template>
          </van-col>
          <van-col span="6" style="text-align:right" v-else>
            <div class="goods_status">已结束</div>
          </van-col>
        </van-row>
      </div>
      <div class="goods_title">
        <h1>{{goodsInfo.title}}</h1>
        <p>{{goodsInfo.stitle}}</p>
        <div class="tips"><van-icon name="info-o" style="color:#ff6600;margin-right: 5px;" />{{goodsInfo.tips}}</div>
      </div>
      <van-divider>商品介绍</van-divider>
      <div class="goods_content" v-html="goodsInfo.content"></div>
      <van-goods-action :safe-area-inset-bottom="true">
        <van-goods-action-icon icon="chat-o" text="客服" @click="onClickIcon" />
        <van-goods-action-button type="warning" text="加入购物车" @click="viewSku=true" />
        <van-goods-action-button type="danger" text="立即购买" @click="viewSku=true" />
      </van-goods-action>
      <van-sku
        v-model="viewSku"
        :sku="goodsInfo.sku"
        :goods="goodsInfo"
        :goods-id="goodsInfo.id"
        :quota="quota"
        :quota-used="quota_used"
        :hide-stock="goodsInfo.sku.hide_stock"
        :show-add-cart-btn="goodsInfo.showCart"
        :disable-stepper-input="true"
        :close-on-click-overlay="true"
        @stepper-change="stepperChange"
        @buy-clicked="onBuyClicked"
        @add-cart="onAddCartClicked"
        @sku-selected="skuSelected"
      />
    </template>
    <template v-else>
      <p style="text-align:center;padding-top:40px;">加载中...</p>
    </template>
  </div>
</template>

<script>
import * as ajax from "@/api/common"
import util from '@/libs/util'
// :custom-stepper-config="customStepperConfig"
export default {
  name: 'MallPage',
  data(){
    return {
      isAct:false,
      viewSku:false,
      hasLoad:false,
      showCart:true,
      quota:0,
      quota_used:0,
      goodsInfo:{},
      customStepperConfig: {
        // 自定义限购文案
        quotaText: '每次限购xxx件',
        // 自定义步进器超过限制时的回调
        handleOverLimit: (data) => {
          const { action, limitType, quota, quotaUsed, startSaleNum } = data;
          console.log(data)
          if (action === 'minus') {
            this.$toast(startSaleNum > 1  ? `${startSaleNum}件起售` : '至少选择一件商品');
          } else if (action === 'plus') {
            console.log(Sku)
            const { LIMIT_TYPE } = this.goodsInfo.sku.skuConstants;
            if (limitType === LIMIT_TYPE.QUOTA_LIMIT) {
              let msg = `单次限购${quota}件`;
              if (quotaUsed > 0) msg += `，你已购买${quotaUsed}`;
              this.$toast(msg);
            } else {
              this.$toast('库存不够了');
            }
          }
        },
        // 步进器变化的回调
        handleStepperChange: currentValue => {},
        // 库存
        stockNum: 1999,
        // 格式化库存
        stockFormatter: stockNum => {},
      }
    }
  },
  mounted () {
    this.getDetail()
  },
  methods:{
    getDetail(){
      util.cookies.set('makeData',null)
      let goods_id = this.$route.query.id
      ajax.getDetail({table:'Seckill',id:goods_id,showLoading:false}).then(res=>{
        this.goodsInfo=res
        this.hasLoad=true
      }).catch(e=>{
        this.$toast(e)
        var _this=this
        setTimeout(()=>{
        // _this.goBack()
      },800)
        return
      })
    },
    goBack(){
      this.$router.go(-1)
    },
    onClickIcon() {
      this.$router.push('/feedBack')
    },
    onClickButton() {
      this.viewSku=true
    },
    onAddCartClicked(){
      this.$toast('秒杀商品请直接购买')
      return
    },
    skuSelected(val){
        //选择规格完成
        this.quota=val.selectedSkuComb?parseInt(val.selectedSkuComb.quota):0
        this.quota_used=val.selectedSkuComb?parseInt(val.selectedSkuComb.quota_used):0
    },
    stepperChange(val){
      console.log(val)
    },
    onBuyClicked(val){
      if(this.goodsInfo.status!=1){
        this.$toast(this.goodsInfo.status_txt)
        return
      }
      if(val.selectedSkuComb.price<1){
        this.$toast('该产品金额为0,暂不可购买')
        return
      }
      if(val.selectedSkuComb.quota>0 && val.selectedSkuComb.quota_used+val.selectedNum>val.selectedSkuComb.quota){
        this.$toast(`每人限购${val.selectedSkuComb.quota}件，你已购买${val.selectedSkuComb.quota_used}件`)
        return
      }
      var tmp={
        list:[{
          id:0,
          num:val.selectedNum,
          goods_id:val.goodsId,
          goods_title:this.goodsInfo.title,
          path:this.goodsInfo.path,
          content:{
            market_price:val.selectedSkuComb.market_price,
            cost_price:val.selectedSkuComb.cost_price,
            max:val.selectedSkuComb.quota,
            num:val.selectedNum,
            price:val.selectedSkuComb.price/100,
            score:val.selectedSkuComb.score,
            sku:val.selectedSkuComb.sku,
            sku_name:val.selectedSkuComb.sku_name,
            stock_num:val.selectedSkuComb.stock_num,
            supplier_id:val.selectedSkuComb.supplier_id,
          },
        }],
        seckill_id:this.goodsInfo.id,
        total:val.selectedSkuComb.price*val.selectedNum
      }
      util.cookies.set('makeData',tmp)
      this.$router.push('/mall/makeOrder')
    }
  }
}
</script>
<style>
.van-sku-row__item img{width:30px!important;height:30px!important;}
.goods_content p img{display: block; margin: 0px!important;padding:0px!important;border: none;}
.goods_content p{margin: 0px!important;padding:0px!important;margin-block-start:0px!important;margin-block-end:0px!important;}
</style>
<style scoped>
.start_info{background:linear-gradient(to right,#ff6034,#ee0a24);color: #fff;padding:8px;}
.start_info .price_info{}
.start_info .price_info .price{color:#ffffff;font-size:30px;}
.start_info .price_info small{font-size:14px!important;}
.start_info .price_info .price_del{font-size:14px;color:#ffffff;text-decoration: line-through;}
.tips{height: auto;margin:10px auto;line-height: 1.5rem;color: #ff6600;font-size: 13px;clear: both;}
.van-goods-action{z-index:999;}
.swipe img{width:100%;height: auto;}
.goods_title{background:#fff;padding:10px;border-bottom:1px solid #f3f3f3;}
.goods_title .star{font-size:20px;color:#999;}
.goods_title .star_act{color:#ff0000;}
.sc{font-size:10px;color:#666;text-align:center;line-height:10px;float:right;width:50px;}
.goods_title h1{font-weight:300;color:#000;font-size:20px;}
.goods_title p{padding:0px;margin:0px;color:#666;font-size:14px;line-height:20px;}
.goods_content{}
.goods_content img{width:100%;margin:0;padding:0;}
</style>
