<template>
<div class="demo">
  <van-nav-bar
    :title="page_title"
    left-text="返回"
    left-arrow
    sticky
    @click-left="goBack"
  />
  <template v-if="hasLoad">
    <template v-if="save_data">
      <van-pull-refresh v-model="isLoading" @refresh="getDetail">
          <van-cell-group title="设备信息">
            <van-cell title="箱体名称" :value="save_data.box_title" />
            <van-cell title="所在位置" :value="save_data.box_address" />
            <van-cell title="投递时间" :value="save_data.create_time" />
          </van-cell-group>
          <van-cell-group title="物品信息">
            <van-cell title="物品类别" :value="save_data.item_name?save_data.item_name:'请选择'" is-link @click="showType = true"/>
            <van-popup v-model="showType" position="bottom">
              <van-picker
                show-toolbar
                title="请选择物品分类"
                :columns="type_arr"
                @cancel="showType = false"
                @confirm="onTypeConfirm"
                @change="onChange"
              />
            </van-popup>
            <van-cell title="单价" :value="save_data.user_price+'元/Kg'" />
            <van-cell title="重量" :value="save_data.user_weight+'Kg'" />
            <van-cell title="预估价" :value="save_data.user_total+'元'" />
          </van-cell-group>
          <div style="text-align:center;margin-top: 10px;padding: 0 15px;">
            <van-button type="primary" size="large" round @click="toSave">提交订单</van-button>
            <van-button type="default" style="margin-top: 10px;" size="large" round @click="cancleOrder">取消投递</van-button>
          </div>
      </van-pull-refresh>
        <van-overlay :show="visShipmentTips" :z-index="1000">
          <div class="wrapper">
            <div class="block">
              <van-loading type="spinner" color="#fff" size="40" />
              请稍后，正在出货
            </div>
          </div>
        </van-overlay>
    </template>
    <template v-else>
      <div class="scans">
        <van-icon name="scan" @click="scanQRCode"/>
        <p>点击上访图标，扫描订单条码</p>
        <p @click="showVend=true">或者<span style="font-weight: 400;color: #ff6600;padding:0 4px;">点击这里</span>手动输入订单号</p>
      </div>
      <van-popup v-model="showVend">
          <div style="padding: 15px;">
            <div style="color: #ff6600;line-height: 25px;text-align: center;">输入订单号</div>
            <van-cell-group>
              <van-field v-model="tmp" placeholder="请填写订单号" />
            </van-cell-group>
            <div style="margin: 16px;">
              <van-button round block type="info" size="mini" @click="submitVend">确定</van-button>
            </div>
          </div>
      </van-popup>
    </template>
  </template>
  <template v-else>
    <p><center>请稍后...</center></p>
  </template>
</div>
</template>

<script>
import * as ajax from "@/api/common"
import util from '@/libs/util'
export default {
  name: 'orderContinuePage',
  data(){
    return {
      showType:false,
      order_no:'',
      type_arr:[],
      showInfo:true,
      info:false,
      hasLoad:false,
      loading:false,
      isLoading:false,
      page_title:'完善订单',
      isLoading:false,
      save_data:{},
      visShipmentTips:false,
      timer:false,
      max_check:5,
      tmp:'',
      showVend:false
    }
  },
  watch: {
  },
  mounted () {
    this.order_no=this.$route.params.order_no?this.$route.params.order_no:0
    if(!this.order_no){
      this.hasLoad=true
      return
    }
    this.getDetail()
  },
  methods:{
    submitVend(){
      if(!this.tmp){
        this.$toast('请填写订单号')
        return
      }
      this.order_no=this.tmp
      this.getDetail()
    },
    goBack(){
      this.$router.push({path:'/mall/index'})
    },
    toSave(){
      if(!this.save_data.user_goods_id){
        this.$toast('请选择物品类别')
        return
      }
      this.$dialog.confirm({
        title: '提示',
        message: '确认提交订单吗?'
      }).then(() => {
        this.save_data.showTips=true
        ajax.post('/Order/submitTmpOrder',this.save_data).then(res=>{
          this.save_data=false
          this.item_name=''
        }).catch(e=>{
          this.$toast(e.msg)
        })
      }).catch(() => {
        this.$toast('您放弃了投递操作')
      })
    },
    cancleOrder(){
      this.$dialog.confirm({
        title: '提示',
        message: '取消订单后，如需再次投递请重新称重，确认取消?'
      }).then(() => {
        this.save_data.showTips=true
        ajax.post('/Order/cancleTmpOrder',this.save_data).then(res=>{
          this.save_data=false
        }).catch(e=>{
          this.$toast(e.msg)
        })
      }).catch(() => {

      })
    },
    checkStatus(){
      return false
      //指令已发送，轮询出货状态
      if(this.max_check<1){
        //不再轮询
        console.log('轮询次数达到上限')
        clearInterval(this.timer)
        this.visShipmentTips=false
        //检测到状态为失败
        this.$dialog.confirm({
          title: '提示',
          message: '经过多次尝试，出货失败，您可以选择如下操作',
          cancelButtonText:'我要退款',
          confirmButtonText:'再试一次',
        }).then(() => {
          //重新出货
          this.showShipmentTips()
        }).catch(() => {
          //选择退款
          this.cancleOrder()
        })
        return
      }else{
        console.log('检测='+this.max_check)
      }
      ajax.post('/Vend/checkShipMent',{order_no:this.order_no,showLoading:false}).then(res=>{
        console.log(res)
        //状态为出货中或出货成功
        if(res.status==1){
          //正在出货中
          this.max_check-=1
        }else if(res.status==2){
          clearInterval(this.timer)
          this.visShipmentTips=false
          //出货成功
          this.$dialog.confirm({
            title: '提示',
            message:res.msg,
            cancelButtonText:'不需要',
            confirmButtonText:'再看看',
          }).then(() => {
            this.getDetail()
          }).catch(() => {
            this.order_no=0
            this.info=false
          })
        }
      }).catch(e=>{
        clearInterval(this.timer)
        //检测到状态为失败
        this.$dialog.confirm({
          title: '提示',
          message: e.msg,
          cancelButtonText:'我要退款',
          confirmButtonText:'再试一次',
        }).then(() => {
          //重新出货
          this.showShipmentTips()
        }).catch(() => {
          //选择退款
          this.cancleOrder()
        })
      })
    },
    isWeiXin(){ 
      return navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1
    },
    // 扫一扫
    scanQRCode() {
      let _this=this
      if(!this.isWeiXin()){
        this.$toast('请在微信客户端使用')
        return
      }
      const toast1 = this.$toast.loading({
        mask: true,
        message: '请稍后...'
      })
      wx.scanQRCode({
        needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
        scanType: ["qrCode","barCode"], // 可以指定扫二维码还是一维码，默认二者都有, "barCode"
        success:  res=> {
          toast1.clear()
          let tmp = res.resultStr.split('N')
          _this.$toast.success('扫码成功')
          _this.order_no = 'N'+tmp[1]
          _this.getDetail()
        },
        fail: err=>{
          _this.$toast({message: err.errMsg , duration: 1500});
        }
      })
    },
    getDetail(){
      this.isLoading=true
      this.loading=true
      ajax.get('/Order/getTmpOrder',{table:'Order',order_no:this.order_no,showTips:true}).then(res=>{
        this.isLoading=false
        this.loading=false
        this.save_data={...res}
        this.save_data.user_price = 0
        this.save_data.user_total=0
        this.item_name=''
        this.tmp=''
        this.hasLoad=true
        this.getItemTypeList(15,res.price_id)
      }).catch(e=>{
        this.save_data=false
        this.order_no=0
        this.tmp=''
        this.showVend =false
        this.loading=false
        this.hasLoad=true
        this.$toast(e.msg)
      })
    },
    getItemTypeList(parent_id,price_id){
      ajax.get('/Order/getItemListByPriceID',{table:'Price',pid:parent_id,price_id:price_id,field:'id,id value,typename text,id children',field_info:'id,id value,typename text,id children,id price_info'}).then(res=>{
        this.type_arr=res
      }).catch(e=>{
        this.$toast(e.msg)
      })
    },
    onTypeConfirm(value, index) {
      let id = this.type_arr[index[0]].children[index[1]].id
      let price = this.type_arr[index[0]].children[index[1]].price_info.price
      console.log(value)
      this.save_data.item_name = value[0]+'-'+value[1]
      this.save_data.user_goods_id = id
      this.save_data.user_price = price
      this.save_data.user_total = Math.round(price*this.save_data.user_weight*1000)/1000
      this.showType=false
    },
    onChange(picker, values) {
      console.log(values)
    }
  }
}
</script>
<style scoped>
.scans{text-align: center;margin-top:50px; font-size: 14px!important;color: #333;}
.scans .van-icon-scan{font-size: 100px!important;}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.block {
  width: 120px;
  height: 120px;
  text-align: center;
  color: #fff;
}
.tips{padding:0 10px;font-size: 12px;}
.tips span{color: #ff0000;margin: 0 5px;}
.goodsList{
  background: #fff;
  margin-bottom: 10px;
}
.goods_title{
  font-size:14px;color:#000;display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.orderCon{padding:10px;}
.goods_title{font-size: 14px;color: #333;}
.goods_stitle{font-size: 12px;color: #666;margin:5px 0;}

.price{color:#ff0000;}
.price b{font-size:16px;font-weight:200;}
.price small{font-size:12px;color: #666;}

.price_del{color:#666;text-decoration: line-through;font-weight:200;font-size:12px;}
</style>
