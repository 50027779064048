<template>
  <div>
    <van-nav-bar
      title="加入家庭组"
      left-text="返回"
      left-arrow
      @click-left="goBack"
    />
    <template v-if="!hasLoad">
      <p><center>请稍后</center></p>
    </template>
    <template v-else>
      <template v-if="family">
            <van-cell-group>
              <van-cell title="小区名" :value="family.city_title?family.city_title:'--'" />
              <van-cell title="户主名" :value="family.name"/>
              <van-cell title="户主电话" :value="family.phone"/>
              <van-cell title="所在地址" :value="family.address"/>
              <van-cell title="创建时间" :value="family.create_time"/>
              <van-cell title="成员数量" :value="family.member_count+'人'" @click="active=1"/>
              <van-cell title="当前积分" :value="family.score"/>
            </van-cell-group>
            <div style="text-align:center;padding:0 20px;margin-top:10px;"><van-button type="primary" round size="large" @click="applyTo">确定加入</van-button></div>
            <div style="text-align:center;padding:0 20px;margin-top:10px;"><van-button type="default" round size="large" @click="goBack">取消</van-button></div>
      </template>

      <template v-else>
        <p><center>抱歉，未找到家庭组记录</center></p>
      </template>
    </template>
  </div>
</template>

<script>
import * as ajax from "@/api/common"
import util from '@/libs/util'
export default {
  name: 'FamilyQrPage',
  data() {
    return {
      hasLoad:false,
      showMyQrcode:false,
      active:0,
      list:[],
      loading:false,
      finished:false,
      activeNames:1,
      userInfo:false,
      isLoading:false,
      family:false,
      qrCodeUrl:''
    }
  },
  mounted () {
    this.f_id=this.$route.query.qd?this.$route.query.qd:0
    if(!this.f_id){
      this.$toast('缺少参数')
      this.goBack()
      return
    }
    this.getFamilyInfo()
  },
  methods:{
    goBack(){
      this.$router.push('/my')
    },
    getFamilyInfo(){
      ajax.get('/Family/getFamilyInfoByFid',{f_id:this.f_id,showTips:true}).then(async res => {
        this.family = res
        this.loading = false
        this.finished = true
        this.hasLoad=true
      }).catch(e=>{
        this.hasLoad=true
      })
    },
    applyTo(){
      this.$dialog.confirm({
        title: '确认',
        message: '确认加入【'+this.family.name+'】家庭组吗'
      }).then(() => {
        ajax.get('/Family/applyFamily',{f_id:this.family['id']}).then(res=>{
          this.$router.push('/my/family')
        })
      }).catch(() => {
        this.$toast('放弃')
      });
    },
    onRefresh() {
      this.isLoading = false;
      this.loading = true;
      this.finished = false;
      this.$toast('刷新成功')
    }
  }
}
</script>

<style scoped>
.user{background:#07c160;padding:20px 0;color:#fff;}
.van-panel__content{
  font-size:14px;
  line-height:24px;
  padding:15px;
}
.van-cell:not(:last-child)::after{left:0px!important;}
</style>
