import util from '@/libs/util.js'
import router from '@/router'
import { Login,Logout} from '@/api/common'
import { Dialog,Toast } from 'vant'
export default {
  namespaced: true,
  actions: {
    /**
     * @description 登录
     * @param {Object} param context
     * @param {Object} param username {String} 用户账号
     * @param {Object} param password {String} 密码
     * @param {Object} param route {Object} 登录成功后定向的路由对象 任何 vue-router 支持的格式
     */
    login ({ dispatch }, {
      phone = '',
      passwd = '',
      verify='',
      login_type=''
    } = {}) {
      return new Promise((resolve, reject) => {
        // 开始请求登录接口
        Login({
          phone,
          passwd,
          verify,
          login_type
        }).then(async res => {
            util.cookies.set('uuid', res.id)
            util.cookies.set('token', res.token)
            // 设置 vuex 用户信息
            await dispatch('lele/user/setUserInfo', res, { root: true })
            // await dispatch('load')
            // 更新路由 尝试去获取 cookie 里保存的需要重定向的页面完整地址
            const path = util.cookies.get('redirect')
            // 删除 cookie 中保存的重定向页面
            util.cookies.remove('redirect')
            // 根据是否存有重定向页面判断如何重定向
            router.push({
              name: path ? { path } : '/'
            })
            // 结束
            resolve()
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    /**
     * @description 注销用户并返回登录页面
     * @param {Object} param context
     * @param {Object} param confirm {Boolean} 是否需要确认
     */
    logout ({ commit, dispatch }, { confirm = false } = {}) {
      /**
       * @description 注销
       */
      async function logout () {
          let isWeiXin = () => { return navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1 }
          return new Promise((resolve, reject) => {
            Logout({}).then(async res => {
                // 删除cookie
                util.cookies.remove('token')
                util.cookies.remove('uuid')
                // 清空 vuex 用户信息
                await dispatch('lele/user/setUserInfo', {}, { root: true })
                // 跳转路由
                let url='wechat'
                router.push({
                  name: url
                })
                resolve()
              })
              .catch(err => {
                console.log('err: ', err)
                reject(err)
              })
          })
      }
      // 判断是否需要确认
      if (confirm) {
        Dialog.confirm({
          title: '提示',
          message: '重新登陆以便更新页面缓存'
        }).then(() => {
          logout()
        }).catch(() => {
          Toast('取消')
        });
      } else {
        logout()
      }
    },
    load ({ dispatch }) {
      return new Promise(async resolve => {
        // DB -> store 加载用户名
        await dispatch('lele/user/load', null, { root: true })
        // end
        resolve()
      })
    },
  }
}
