<template>
    <!-- <van-pull-refresh v-model="isLoading" @refresh="onRefresh"> -->
    <div class="mall">
    <van-sticky>
    <van-nav-bar
      title="购物车"
      left-text="返回"
      :right-text="rightTxt"
      left-arrow
      @click-right="toEdit"
      @click-left="goBack"
    />
    </van-sticky>
    <div class="empty" v-if="list.length==0">
      <van-icon name="cart-o"/>
      <p @click='goMall'>购物车中啥也没有~<br/><van-tag size="large" color="#f2826a" plain>去商城看看</van-tag></p>
    </div>
    <template v-else>
      <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
        <van-row class="item" v-for="(item,idx) in list">
          <van-col span="2" @click="selectGoods(idx)">
            <van-checkbox class="check" v-model="item.checked">　</van-checkbox>
          </van-col>
          <van-col span="4">
            <van-image
              width="5rem"
              height="5rem"
              fit="cover"
              :src="item.path"
              @click="selectGoods(idx)"
            />
          </van-col>
          <van-col span="13" offset="3" @click="selectGoods(idx)">
            <span class="title">{{item.goods_title}}</span><br/>
            {{item.content.sku_name}}<br/>
            ￥{{item.content.price}} * {{item.num}}<br/>
          </van-col>
          <van-stepper class="step" v-model="item.num" :disable-input="true" :min="1" :max="item.max" @change="checkPrice" />
        </van-row>
      </van-list>
    </template>
    <van-submit-bar
      :price="total"
      button-text="去结算"
      @submit="onSubmit"
      v-if="!isEdit"
    >
      <van-checkbox v-model="checkAll" @click="clickAll">全选</van-checkbox>
    </van-submit-bar>
    <div class="spec" v-else>
    <van-submit-bar
      :price="total"
      button-text="删除"
      @submit="toDel"
    >
      <van-checkbox v-model="checkAll" @click="clickAll">全选</van-checkbox>
    </van-submit-bar>
    </div>
    
  </div>
    <!-- </van-pull-refresh> -->
</template>

<script>
import * as ajax from "@/api/common"
import util from '@/libs/util'
export default {
  name: 'MallPage',
  data(){
    return {
      list:[],
      loading:false,
      isLoading:false,
      finished:false,
      checkAll:false,
      isEdit:false,
      rightTxt:'编辑',
      total:0,
      page:{
        current_page:0,
        page_total:1,
        count:0,
        page_size:10,
      },
      select_arr:{
        list:[],
        total:0
      },
    }
  },
  mounted () {
    util.cookies.remove('yuyueData')
    this.onLoad()
  },
  watch: {
  },
  methods:{
    toEdit(){
      if(this.list.length==0){
        this.$toast('购物车是空的')
        return
      }
      this.isEdit=!this.isEdit
      this.rightTxt=this.isEdit?'完成':'编辑'
    },
    selectGoods(idx){
      this.list[idx].checked=!this.list[idx].checked
      this.checkPrice()
    },
    clickAll(){
      this.checkAll = !this.checkAll
      this.list = this.list.map(item=>{
        item.checked = !this.checkAll
        return item
      })
      this.checkPrice()
    },
    checkPrice(){
      let total=0
      let arr=this.list.filter(res=>{
        if(res.checked){
          total+= res.num*res.content.price*100
          return res
        }
      })
      if(arr.length==this.list.length){
        this.checkAll=true
      }else{
        this.checkAll=false
      }
      this.total=total
      this.select_arr.list=arr
      this.select_arr.total=total
    },
    toDel(){
      let ids=[]
      let arr=this.list.filter(res=>{
        if(res.checked){
          ids.push(res.id)
          return res
        }
      })
      this.$dialog.confirm({
        title: '确认',
        message: '删除已选择商品吗'
      }).then(() => {
        ajax.toDel({table:'Cart',ids:ids}).then(res=>{
          this.onRefresh()
        })
      }).catch(() => {
        this.$toast('放弃删除')
      });
    },
    goMall(){
      this.$router.push('/mall')
    },
    goBack(){
      this.$router.go(-1)
    },
    onSubmit(){
      if(this.select_arr.list.length==0||this.select_arr.total==0){
        this.$toast('请至少选择一件商品')
        return
      }
      // util.cookies.set('makeData',this.select_arr)
      window.localStorage.setItem("makeData",JSON.stringify(this.select_arr))
      console.log(localStorage.getItem("makeData"))
      this.$router.push('/mall/makeOrder')
    },
    reset(){
      this.list=[]
      this.isEdit=false
      this.checkAll=false
      this.rightTxt=this.isEdit?'完成':'编辑'
    },
    onLoad(){
      util.cookies.set('makeData',null)
      this.loading = true
      this.page.current_page=parseInt(this.page.current_page) + 1
      ajax.getList({
        table:'Cart',
        page:this.page.current_page,
        page_size:this.page.page_size,
        scence:'mg_cart',
        filter:[]
      }).then(res=>{
        this.loading = false
        this.isLoading=false
        this.page.page_total = res.last_page
        this.page.current_page = res.current_page
        this.page.count = res.total
        
        for (var i = 0; i<res.data.length; i++) {
          this.list.push(res.data[i]);
        }

        this.list.map(item=>{
          item.num = parseInt(item.num)
          item.max = item.content.max?item.content.max:1000
        })

        if(res.last_page==res.current_page){
          this.finished = true;
        }
      })
    },
    onRefresh() {
      this.list=[]
      this.page.current_page=0
      this.isLoading = false
      this.loading = true
      this.finished = false
      this.onLoad()
      this.$toast('刷新成功')
    }
  }
}
</script>

<style scoped>
.spec .van-submit-bar__text{color: #fff!important;}
.spec .van-submit-bar__price{color: #fff!important;}
.empty{color:#888888;text-align:center;padding-top:50px;}
.empty .van-icon{color:#888888;font-size:70px;}
.list{background:#fff;}

.item{background:#fff;margin-bottom:10px;padding:10px;position:relative;font-size:12px;line-height:20px;color:#666;}
.item .title{font-size:14px;color:#000;display: -webkit-box;
-webkit-box-orient: vertical;
-webkit-line-clamp: 2;
overflow: hidden;}
.item .check{position:absolute;z-index:2;left:10px;top:30px;}
.item .step{position:absolute;z-index:2;right:10px;bottom:10px;}
</style>
