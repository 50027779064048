<template>
<div class="demo">
  <van-nav-bar
    :title="page_title"
    left-text="返回"
    left-arrow
    sticky
    @click-left="goBack"
  />
  <template v-if="isLoad">
    <div class="titles">
      <h1>{{article.title}}</h1>
      <h3>{{article.stitle}}</h3>
      <div class="tinfo">阅读<span>{{article.view}}</span>发布<span>{{article.author}}</span>时间<span>{{article.up_time}}</span></div>
    </div>
    <div class="content" v-html="article.content"></div>
  </template>
  <template v-else>
    <p><center>请稍后...</center></p>
  </template>
</div>
</template>

<script>
import * as ajax from "@/api/common"
import util from '@/libs/util'
export default {
  name: 'newsDetailPage',
  data(){
    return {
      banner:[],
      article_id:0,
      article:false,
      isLoad:false,
      page_title:'资讯详情',
    }
  },
  mounted () {
    this.article_id=this.$route.params.article_id?this.$route.params.article_id:0
    if(!this.article_id){
      this.$toast('页面不存在')
      return
    }
    this.getDetail()
  },
  methods:{
    goBack(){
      this.$router.go(-1)
    },
    getDetail(){
      ajax.get('/Base/detail',{id:this.article_id,table:'Article',field:'id,title,stitle,update_time up_time,content,desc,view,author'}).then(async res => {
        this.article=res
        this.isLoad=true
      }).catch(e=>{
        console.log(e)
      })
    },
  }
}
</script>
<style>
img{width:100%!important;}
</style>
<style scoped>
.titles{padding:10px;}
.titles h1{font-size:18px;font-weight:200;color:#000;}
.titles h3{font-size:14px;font-weight:200;color:#666;}
.titles .tinfo{color:#666;font-size:14px;text-align:center;}
.titles .tinfo span{color:#5fabf8;font-size:14px;padding:0px 5px;}
.content{line-height:2rem;padding:10px;font-size:14px;color:#333;}
</style>
