<template>
  <div class="demo">	 
<!-- 	 <van-notice-bar mode="closeable">
尊敬的会员朋友，感谢您一直以来对“乐乐一家”的信任和支持，为了能更好为大家服务，我司近期将对商城系统进行升级改版，从11月25日起暂时关闭生鲜配送服务，您通过参与垃圾分类投放废品获得的每一笔金币(扣除已消费的，同时根据腾讯规定累计金额＞0.3元)满48小时后可以在“乐乐回收”公众号的个人中心直接申请提现。因此给您带来的不便之处敬请谅解！服务电话：15870561113  19112689210
	 </van-notice-bar> -->
    <router-view></router-view>
    <van-tabbar v-if="showBottomTab" v-model="active" :active-color="$store.state.lele.user.info.is_vip?'#FFD700':'#07C160'">
      <van-tabbar-item v-for="(item,idx) in bar" :name="item.name" :to="item.url" :key="idx" :icon="item.icon">{{item.title}}</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import * as ajax from "@/api/common"
export default {
  name: 'BasePage',
  props: {
  },
  data(){
    return {
      good_news:true,
      showBottomTab:true,
      active:'index',
      bar:[{
       title:'乐乐一家', 
       icon:'home-o',
       name:'index',
       url:'/', 
      },{
       title:'旧物回收', 
       icon:'exchange', 
       name:'huishou',
       url:'/huishou', 
      },
      // {
      //  title:'商城', 
      //  icon:'new-arrival-o', 
      //  name:'mall',
      //  url:'/mall', 
      // },
      // {
      //  title:'购物车', 
      //  icon:'cart-o', 
      //  name:'cart',
      //  url:'/mall/cart', 
      // },
      {
       title:'我的', 
       icon:'contact', 
       name:'my',
       url:'/my', 
      }]
    }
  },
  mounted () {
    this.active=this.$route.meta.act
  },
  watch:{
    $route(to,from){
      this.active=to.meta.act
      if(to.name=='goodsDetail'||to.name=='cart'||to.name=='viewMallOrder'||to.name=='recycleBox' ||to.name=='hospital' ||to.name=='exam'){
        this.showBottomTab=false
      }else{
        this.showBottomTab=true
      }
    },
  },
  methods:{
  }
}
</script>
<style type="text/css">
.van-nav-bar__title{font-size: 14px!important;}
</style>
<style scoped>
.demo{padding-bottom:60px;}
.van-popup
{
  background: none;
}
.van-popup--center{
  width: 90%;
}
</style>
