<template>
  <div>
    <van-nav-bar :title="'附近回收箱'"
      left-text="返回"
      right-text="我的关注"
      left-arrow
      sticky
      @click-left="$router.push('/')"
      @click-right="$router.push('/myBox')"
    />
    

    <div class="map-box">
      <van-popup v-model="show_pop" :style="{ width: '80%' }" closeable>
        <div style="padding:5px 10px;">
          <p>{{current.b_name}}</p>
          <p v-if="current.b_addressnote">位置：{{current.b_addressnote}}</p>
          <p >状态：
            <b style="color:red;" v-if="  get_data_ts-current.lasttime_real>600  ">维护中</b>
            <b style="color:red;" v-else-if=" current.b_state>0 || current.b_error>0  ">已满</b>
          <span style="color:green;" v-else>未满{{parsePercent(current.weight)}}% </span></p>
        </div>
        <p v-if="current.url_query"><center><van-button type="default" size="small" style="margin-right: 10px;" @click="addBox">{{act_tit}}</van-button><van-button type="primary" size="small" @click="gourl()">导航</van-button></center></p>
      </van-popup>
      <el-amap ref="map" :center="center" :plugin="plugin" vid="amapDemo" class="map_demo" :zoom="zoom">
          <el-amap-marker @click="test(marker)" v-if="markers.length>0" v-for="(marker, index) in markers" :position="marker.position"  :draggable="false" :vid="index" :key="index" :events="marker.events">
            <v-touch tag="p" v-on:tap="viewMarker(marker)"><img src="https://ae01.alicdn.com/kf/HTB16eChavc3T1VjSZPf763WHXXa2.png" style="width:30px!important;"></v-touch>
          </el-amap-marker>

      </el-amap>
    </div>


  </div>
</template>

<script>
  import * as ajax from "@/api/common"
  import wx from 'weixin-js-sdk';
  import { mapGetters,mapActions } from 'vuex'
  import {Toast} from 'vant';
  export default {
    name:'signinPage',
    data() {
      return {
        act_tit:'',
        show:false,
        get_data_ts:0,
        show_pop:false,
        current:{},
        zoom: 14,
          center: [106.497958,29.514523],
          lng: 0,
          lat: 0,
          loaded: false,
          markers:[],
          boxes:[],
        plugin: [{
          pName: 'Geolocation',
            events: {
              init(o) {
                Toast.loading({
                mask: true,
                duration:0,
                message: '定位中...'
              });
              // o 是高德地图定位插件实例
              o.getCurrentPosition((status, result) => {
                console.log(status)
                console.log(result)
                if (result && result.position) {
                  Toast.clear();
                  self.lng = result.position.lng;
                  self.lat = result.position.lat;
                  self.center = [self.lng, self.lat];
                  self.loaded = true;
                  // this.$nextTick();
                }else{
                  Toast.fail('定位失败');
                }
              });
              }
            }
          },{
            pName: 'ToolBar',
            events: {
              init(instance) {
                console.log(instance);
              }
            }
          }]
      }
    },
    created(){
      // this.getsignature();
    },
    mounted() {
      this.getBoxes();
    },
    methods: {
      ...mapGetters('lele/user', [
        'getWebUrl',
      ]),
      addBox(){
        console.log(this.current)
        if(this.current.has_gz){
          this.$dialog.confirm({
            title: '提示',
            message: '确定要取消关注吗'
          }).then(() => {
            ajax.get('/Old/addBox',{b_id:this.current.b_id,show_tips:true}).then(res=>{
              this.$toast('已取消关注')
              this.current.has_gz=false
              this.act_tit = this.current.has_gz==true?'取消关注':'添加关注'
              this.show_pop=false
            })
          }).catch(() => {
            return
          })
        }else{
          ajax.get('/Old/addBox',{b_id:this.current.b_id,show_tips:true}).then(res=>{
            this.$toast('关注成功')
            this.current.has_gz=true
            this.act_tit = this.current.has_gz==true?'取消关注':'添加关注'
            this.show_pop=false
          })
        }
      },
      viewMarker(e){
        this.current = e;
        this.current.url_query = {
          lng:e.position[0],
          lat:e.position[1],
          b_name:e.b_name,
          b_addressnote:e.b_addressnote,
        };
        this.show_pop=true
        this.act_tit = this.current.has_gz==true?'取消关注':'添加关注'
      },
        parsePercent(weight){
          let percent = parseInt(weight/40*100);
          percent = percent > 95 ? 95 : percent;
          return percent;
        },
        //获取签名
        getsignature() {
          let wechaturl = this.getWebUrl()
          if (wechaturl=='') {
            wechaturl = window.location.href
          }
          ajax.get('/Publics/getWxSign',{input_url:wechaturl}).then(async res => {
            wx.config({
              debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
              appId: res.appId, // 必填，公众号的唯一标识
              timestamp: res.timestamp, // 必填，生成签名的时间戳
              nonceStr: res.nonceStr, // 必填，生成签名的随机串
              signature: res.signature,// 必填，签名，见附录1
              success:function(e){
                console.log(e)
              },
              jsApiList: [
                'openLocation'
              ] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
            })
            //处理验证失败的信息
            wx.error(function (res) {
              Toast({message: res.errMsg , duration: 1500});
            });
          }).catch(e=>{
              console.log(e)
            })
        },
        gourl(){
          let query={
            latitude: parseFloat(this.current.url_query.lat), // 纬度，浮点数，范围为90 ~ -90
            longitude: parseFloat(this.current.url_query.lng), // 经度，浮点数，范围为180 ~ -180。
            name: this.current.url_query.b_name,// 位置名
            address: this.current.url_query.b_addressnote, // 地址详情说明
            scale: 15, // 地图缩放级别,整形值,范围从1~28。默认为最大
            infoUrl: '' // 在查看位置界面底部显示的超链接,可点击跳转
          }
          wx.openLocation(query);
        },
        getBoxes(){
          //获取箱体列表
            ajax.get('/Old/getAroundBox',{}).then(res => {
              let tmp = [];
              let Things = res;
              this.boxes = res;
              this.get_data_ts = Math.round( new Date() / 1000 );
              let windows = [];
              for (var i = 0; i < Things.length; i++) {
                var cur = Things[i];
                if ( cur.b_lng && cur.b_lat ) {
                  tmp.push({
                    position:[cur.b_lng,cur.b_lat],
                    events:{
                      click:function(){
                          
                      }
                    },
                    b_id:cur.b_id,
                    b_name:cur.b_name,
                    b_addressnote:cur.b_addressnote,
                    weight:cur.weight,
                    b_state:cur.b_state,
                    b_error:cur.b_error,
                    has_gz:cur.has_gz,
                    lasttime_real:cur.lasttime_real,
                    showdetial:false
                  });
                }
              }
              this.markers = tmp;
            });
          },
        handle_click(e,a){
          console.log(e);
          console.log(a);
          let Things = this.boxes;
          let click_box = {};
          for (var i = Things.length - 1; i >= 0; i--) {
            var cur = Things[i];
            if( cur.b_lat == e.lnglat.lat && cur.b_lng == e.lnglat.lng ) {
              click_box = cur;
              break;
            }
          }
          console.log(click_box);
        }
    }
    
  }
</script>
<style>
.map-box { height:95vh;  }
.amap-logo,.amap-copyright{ display: none !important;width:1px;height:1px; }
.amap-geolocation-con amap-locate-loading{bottom:60px!important;}
</style>
