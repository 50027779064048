import Base from '@/views/Base'
import Home from '@/views/Home'
import My from '@/views/my/index'
import Mall from '@/views/mall/index'
import goodsList from '@/views/mall/list'
import goodsDetail from '@/views/mall/detail'
import cart from '@/views/mall/cart'
import makeOrder from '@/views/mall/makeOrder'
import YuYue from '@/views/order/Yuyue'
import question from '@/views/question'
import signin from '@/views/signin'
import recycleBox from '@/views/recycleBox'
import scoreList from '@/views/my/scoreList'
import coinList from '@/views/my/coinList'
import myFamily from '@/views/my/myFamily'
import myFamilyQr from '@/views/my/myFamilyQr'
import withdrawal from '@/views/my/withdrawal'
import withdrawalLog from '@/views/my/withdrawalLog'
import bindPhone from '@/views/my/bindPhone'
import mallOrder from '@/views/order/mallOrder'
import viewMallOrder from '@/views/order/mallOrderDetail'
import recycleOrder from '@/views/order/recycleOrder'
import recycleNewOrder from '@/views/order/recycleNewOrder'
import addressList from '@/views/my/addressList'
import Login from '@/views/public/Login'
import Wechat from '@/views/public/Wechat'
import Err from '@/views/Err'
import newsDetail from '@/views/news/newsDetail'
import authLogin from '@/views/public/authLogin'
import pointsPutIn from '../views/points/putIn.vue'
import pointsPutOut from '../views/points/putOut.vue'
import pointsInfo from '../views/points/info.vue'
import abnormal from '../views/points/abnormal.vue'
import feedBack from '../views/public/feedBack.vue'
import myBox from '../views/myBox.vue'
import myFeedBack from '../views/feedBack/list.vue'
import secKill from '../views/secKill/index.vue'
import secKillDetail from '../views/secKill/detail.vue'
import viewFeedBack from '../views/feedBack/viewFeedBack.vue'
import vendDetail from '../views/mall/vendDetail.vue'
import orderContinue from '../views/public/orderContinue.vue'

import feedBackAdm from '../views/feedBack/feedBackAdm.vue'
import feedBackAdmView from '../views/feedBack/feedBackAdmView.vue'

import kuaidiPutIn from '../views/kuaidi/putIn.vue'
import kuaidiPutOut from '../views/kuaidi/putOut.vue'
import kuaidiInfo from '../views/kuaidi/info.vue'
import offlineorder from '../views/order/offLineOrder.vue'
import mallStat from '../views/stat/info.vue'
import hospital from '../views/order/hospital.vue'
import caigou from '../views/caigou/info.vue'
import area from '../views/area/info.vue'
import myinfo from '../views/points/myinfo.vue'
import huishou from '../views/points/huishou.vue'
import manual from '../views/manual.vue'
import exam from '../views/exam.vue'


const meta = { requiresAuth: true }
// 由于懒加载页面太多的话会造成webpack热更新太慢，所以开发环境不使用懒加载，只有生产环境使用懒加载
// const _import = require('@/libs/util.import.' + process.env.NODE_ENV)

/**
 * 在主框架内显示
 */
const frameIn = [
    {
      path: '/hospital/:room_no',
      name: 'hospital',
      meta:{
        title:'快捷预约',
        act: 'hospital',
      },
      component:hospital
    },
    {
      path: '/exam',
      name: 'exam',
      meta:{
        title:'在线问答',
        act: 'exam',
      },
      component:exam
    },
  {
    path: '/',
    redirect: { name: 'index' },
    component: Base,
    children: [
      // 首页 必须 name:index
      {
        path: '/',
        name: 'index',
        meta:{
          title:'首页',
          act: 'index',
        },
        component:Home
      },
      {
        path: '/huishou',
        name: 'huishou2',
        meta:{
          title:'废旧物资回收',
          act: 'huishou',
        },
        component:()=>import('../views/huishou.vue')
      },
      {
        path: '/manual',
        name: 'manual',
        meta:{
          title:'垃圾分类服务',
          act: 'huishou',
        },
        component:manual
      },
      {
        path: 'caigou/info',
        name: 'caigou',
        meta:{
          title:'采购',
          act: 'my',
        },
        component:caigou        
      },
      {
        path: 'area/info',
        name: 'area',
        meta:{
          title:'片区经理',
          act: 'my',
        },
        component:area        
      },
      {
        path: 'secKill',
        name: 'secKill',
        meta:{
          title:'限时秒杀',
          act: 'mall',
        },
        component:secKill
      },{
        path: 'seckill/detail',
        name: 'seckillDetail',
        meta:{
          title:'商品详情',
          act: 'mall',
        },
        component:secKillDetail
      },{
        path: '/orderContinue/:order_no',
        name: 'orderContinue',
        meta:{
          title:'完善订单',
          act: 'index',
        },
        component:orderContinue
      },{
        path: '/vend/detail/:vend_id',
        name: 'vendDetail',
        meta:{
          title:'积分兑换',
          act: 'mall',
        },
        component:vendDetail
      },
      {
        path: 'yuyue',
        name: 'yuyue',
        meta:{
          title:'我的预约',
          act: 'yuyue',
        },
        component:YuYue
      },
      {
        path: 'myBox',
        name: 'myBox',
        meta:{
          title:'我关注的箱体',
          act: 'index',
        },
        component:myBox
      },
      {
        path: 'signin',
        name: 'signin',
        meta:{
          title:'签到',
          act: 'index',
        },
        component:signin
      },
      {
        path: 'question',
        name: 'question',
        meta:{
          title:'环保问答',
          act: 'index',
        },
        component:question
      },
      {
        path: '/recycle/box',
        name: 'recycleBox',
        meta:{
          title:'附近回收箱',
          act: 'index',
        },
        component:recycleBox
      },{
        path: '/authLogin',
        name: 'authLogin',
        meta:{
          title:'箱体登陆',
          act: 'index',
        },
        component:authLogin
      },{
        path: '/login',
        name: 'login',
        meta:{
          title:'登陆',
          act: 'index',
        },
        component:Login
      },{
        path: '/news/detail/:article_id',
        name: 'newsDetail',
        meta:{
          title:'资讯详情',
          act: 'index',
        },
        component:newsDetail
      },
      {
        path: '/points/putIn',
        name: 'PutIn',
        meta:{
          title:'入库确认',
          act: 'my',
        },
        component:pointsPutIn
      },
      {
        path: '/points/huishou',
        name: 'huishou',
        meta:{
          title:'线下回收详情',
          act: 'my',
        },
        component:huishou
      },
      {
        path: '/points/huishou_sum',
        name: 'huishou_sum',
        meta:{
          title:'线下回收汇总',
          act: 'my',
        },
        component:()=>import('../views/points/huishou_sum.vue')
      },
      {
        path: '/points/putOut',
        name: 'PutOut',
        meta:{
          title:'用户取件',
          act: 'my',
        },
        component:pointsPutOut
      },
      {
        path: '/points/info',
        name: 'points_info',
        meta:{
          title:'数据统计',
          act: 'my',
        },
        component:pointsInfo
      },
      {
        path: '/points/myinfo',
        name: 'myinfo',
        meta:{
          title:'数据统计',
          act: 'my',
        },
        component:myinfo
      },
      {
        path: '/kuaidi/info',
        name: 'kuaidi_info',
        meta:{
          title:'数据统计',
          act: 'my',
        },
        component:kuaidiInfo
      },
      {
        path: '/kuaidi/putIn',
        name: 'PutInruku',
        meta:{
          title:'入库确认',
          act: 'my',
        },
        component:kuaidiPutIn
      },
      {
        path: '/kuaidi/putOut',
        name: 'PutOutchuku',
        meta:{
          title:'用户取件',
          act: 'my',
        },
        component:kuaidiPutOut
      },
      {
        path: '/points/abnormal',
        name: 'points_abnormal',
        meta:{
          title:'异常处理',
          act: 'my',
        },
        component:abnormal
      },
      {
        path: '/stat/info',
        name: 'mall_stat',
        meta:{
          title:'商城统计',
          act: 'my',
        },
        component:mallStat
      },
    ]
  },
  {
    path: '/mall',
    name: 'mall',
    meta:{
      title:'积分商城',
      act: 'mall',
    },
    redirect: { path: '/mall/index' },
    component:Base,
    children:[
      // {
      //   path: 'index',
      //   name: 'mallIndex',
      //   meta:{
      //     title:'积分商城',
      //     act: 'mall',
      //     keepAlive:true
      //   },
      //   component:Mall,
      // },
      {
        path: 'list',
        name: 'goodsList',
        meta:{
          title:'商品列表',
          act: 'mall',
        },
        component:goodsList,
      },
      {
        path: 'detail',
        name: 'goodsDetail',
        meta:{
          title:'商品详情',
          act: 'mall',
        },
        component:goodsDetail,
      },
      {
        path: 'cart',
        name: 'cart',
        meta:{
          title:'购物车',
          act: 'cart',
        },
        component:cart,
      },
      {
        path: 'makeOrder',
        name: 'makeOrder',
        meta:{
          title:'提交订单',
          act: 'mall',
        },
        component:makeOrder,
      },{
        path: 'off/:order_no',
        name: 'offlineorder',
        meta:{
          title:'订单支付',
          act: 'mall',
        },
        component:offlineorder,
      },
    ]
  },
  {
    path: '/my',
    name: 'my',
    redirect: { path: '/my/index' },
    component:Base,
    children:[
    {
      path: 'index',
      name: 'myIndex',
      meta:{
        title:'个人中心',
        act: 'my',
      },
      component:My,
    },{
      path: 'scoreList',
      name: 'scoreList',
      meta:{
        title:'积分明细',
        act: 'my',
      },
      component:scoreList
    },{
      path: 'coinList',
      name: 'coinList',
      meta:{
        title:'环保金币',
        act: 'my',
      },
      component:coinList
    },{
      path: 'family',
      name: 'myFamily',
      meta:{
        title:'我的家庭组',
        act: 'my',
      },
      component:myFamily
    },{
      path: 'family_qr',
      name: 'myFamilyQr',
      meta:{
        title:'邀请新成员',
        act: 'my',
      },
      component:myFamilyQr
    },{
      path: 'withdrawal',
      name: 'withdrawal',
      meta:{
        title:'提现',
        act: 'my',
      },
      component:withdrawal
    },{
      path: 'withdrawal_log',
      name: 'withdrawal_log',
      meta:{
        title:'提现记录',
        act: 'my',
      },
      component:withdrawalLog
    },{
      path: 'bindPhone',
      name: 'bindPhone',
      meta:{
        title:'绑定手机号',
        act: 'my',
      },
      component:bindPhone
    },{
      path: 'mallOrder',
      name: 'mallOrder',
      meta:{
        title:'商城订单',
        act: 'my',
      },
      component:mallOrder,
    },{
        path: '/my/viewMallOrder',
        name: 'viewMallOrder',
        meta:{
          title:'订单详情',
          act: 'my',
        },
        component:viewMallOrder,
      },{
      path: 'recycleOrder',
      name: 'recycleOrder',
      meta:{
        title:'历史投递',
        act: 'index',
      },
      component:recycleOrder
    },{
      path: 'recycleNewOrder',
      name: 'recycleNewOrder',
      meta:{
        title:'投递记录',
        act: 'index',
      },
      component:recycleNewOrder
    },{
      path: 'addressList',
      name: 'addressList',
      meta:{
        title:'收货地址',
        act: 'my',
      },
      component:addressList
    }]
  },
  {
    path: '/wechat',
    name: 'wechat',
    meta:{
      title:'微信登陆',
    },
    component:Wechat
  },
  {
    path: '/feedBack',
    name: 'feedBack',
    meta:{
      title:'意见反馈',
    },
    component:feedBack
  },{
    path: '/my/feedBack',
    name: 'myFeedBack',
    meta:{
      title:'我的反馈',
    },
    component:myFeedBack
  },{
    path: '/my/viewFeedBack',
    name: 'viewFeedBack',
    meta:{
      title:'反馈详情',
    },
    component:viewFeedBack
  },{
    path: '/feedBackAdm',
    name: 'feedBackAdm',
    meta:{
      title:'工单处理',
    },
    component:feedBackAdm
  },{
    path: '/feedBackAdm/viewFeedBack',
    name: 'feedBackAdmView',
    meta:{
      title:'反馈详情',
    },
    component:feedBackAdmView
  }
]

/**
 * 错误页面
 */
const errorPage = [
  // 404
  {
    path: '*',
    name: '404',
    meta:{
          title:'回到首页',
        },
    redirect: to=>{
      return "/"
    }
  }
]

// 导出需要显示菜单的
// export const frameInRoutes = frameIn

// 重新组织后导出
export default [
  ...frameIn,
  ...errorPage
]
