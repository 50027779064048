<template>
  <div>
    <van-nav-bar
      title="商城订单"
      left-text="返回"
      left-arrow
      style="display:none"
      @click-left="goBack"
    />
  <van-dropdown-menu sticky>
  <van-dropdown-item v-model="point_id" :options="points" @change="chooseItem"/>
</van-dropdown-menu>
<!-- 信息区域 -->
<div class="info-area van-panel__content" style="background: #fff;font-size: 12px">
  <div><van-tag type="success">姓名</van-tag> <span class="address">{{currentInfo.name}}</span></div>
  <div><van-tag type="success">手机</van-tag> <span class="address">{{currentInfo.phone}}</span></div>
</div>
<!-- 信息区域 -->
<!-- 信息区域 -->
<div v-if="point_id>0">

<van-cell @click="show = true">
  <div style="display: flex; justify-content: space-between;">
    <span>选择截止日期（包含）</span>
    <span style="color: red">{{date}}</span>
  </div>
</van-cell>
<van-cell>
  <span style="color: #EE0A24">下面是截止{{date}}（包含）,该合作点还没有复核的回收物资统计，可以一键复核或者单独对某一项复核</span>
</van-cell>
<van-calendar v-model="show"  @confirm="onConfirm"  confirm-text='确定' :minDate='new Date(2010, 0, 1)'/>
<div class="info-area van-panel__content" style="background: #fff;font-size: 12px">
<div  class="flex" style="background: #E0E0E0"><span class="flex-ite">标题</span><span class="flex-ite">重量(kg)</span><span class="flex-ite">金额(元)</span><span class="flex-ite">复核</span></div>
<div v-for='(item,index) in sums' class="flex"><span class="flex-ite">{{item.title}}</span><span class="flex-ite">{{item.weight}}</span>
  <span class="flex-ite">{{item.pay}}</span>
  <span class="flex-ite"><van-button type="info" size="mini" v-if="index != 0" @click="fuheAll(item.item_id)">点我复核</van-button></span>
</div>
<div style="margin-top: 10px;text-align: right;">
  <van-button type="primary" size="mini" @click="fuheAll(0)">一键复核以上回收物资，截止日期： {{date}}</van-button>
</div>
</div>

</div>
<!-- 信息区域 -->
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
<!--       <van-tabs sticky v-model="active" @click="onRefresh">
        <van-tab :title="type.title" :name='type.caigou_status' v-for="(type,idx) in status_arr" :info="type.count">
          <form action="/">
  <van-search
    v-model="searchValue"
    show-action
    placeholder="请输入配送单号/商品名"
    @search="onSearch"
    @cancel="onCancel"
  />
</form>
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
          >
          </van-list>
        </van-tab>
      </van-tabs> -->

      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <van-panel v-for="item in list" :title="item.del_no" :status="item.status_txt" style="margin-bottom:20px!important;font-size:12px;">
<van-card
  :num="item.weight"
  :price="item.price"
  :desc="'共'+item.pay+'元'"
  :title="item.title"
  :thumb="item.images[0]"
/>

          <div slot="footer" style="height: 30px;display: flex;justify-content: space-between;align-items: center;">
              <span>创建时间 {{item.create_time}}</span>
              <van-tag v-if='item.is_checked' type="success">已复核</van-tag>
              <van-tag v-else type="danger">未复核</van-tag>
          </div>
        </van-panel>
      </van-list>

    </van-pull-refresh>
    <van-popup v-model="infoVis">
    <van-steps direction="vertical" :active="logAct">
      <van-step v-for="item in logInfo">
        【{{item.type_txt}}】{{item.content}}<br/>
        <span v-if="item.user_info">By {{item.user_info.name}}<br/></span>
        {{item.create_time}}
      </van-step>
    </van-steps>
    </van-popup>
    
  </div>
</template>

<script>
import * as ajax from "@/api/common"
import util from '@/libs/util'
export default {
  name: 'infoPage',
  // props:[points],
  data() {
    return {
      point_id: 0,
      old_point:true,
      points: [],
      filter:{
      },
      page:{
        current_page:0,
        page_total:1,
        count:0,
        page_size:50,
      },
      list:[],
      logInfo:[],
      active:0,
      logAct:0,
      loading:false,
      finished:false,
      activeNames:1,
      userInfo:false,
      isLoading:false,
      infoVis:false,
      status_arr:[
        {
          title:"全部",
          caigou_status:0
        }
      ],
      searchValue:'',
      currentInfo:[],
      show:false,
      sums:[],
      date:this.formatDate(new Date)
    }
  },
  mounted () {
    this.tmpArr=this.status_arr.filter((el, index) => {
      el.list=[]
      return el
    })
    this.active=this.$route.query.status?this.$route.query.status:-1
    this.getOptionsAndStatus()
  },
  watch:
  {
    point_id(old,now){
      this.old_point = false
    }
  },
  methods:{
    fuheAll(item_id)
    {
      this.$dialog.confirm({
        title: '确认',
        message: '是否确认复核该合作点截止'+this.date+'的所有物资'
      }).then(() => {
        ajax.post('/base/fuheAll',{item_id:item_id,worker_id:this.currentInfo.worker_id,date:this.date}).then(res=>{
          // let _this = this
          this.onRefresh()
        })
      }).catch(() => {
        this.$toast('放弃')
      });      
    },
    formatDate(date) {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    },
    onConfirm(date) {
      this.show = false;
      this.date = this.formatDate(date);
      ajax.post('/base/getSums',{worker_id:this.currentInfo.worker_id,date:this.date}).then(res=>{
        // let _this = this
        this.onRefresh()
        this.sums = res
      })      
    },
    getTitle(caigou_status)
    {
      let item = this.status_arr.filter(item=>{
        return item.caigou_status == (caigou_status + 1)
      })
      return item[0].title
    },
    show_status(caigou_status){
      return this.getTitle(caigou_status)
    },
    chooseItem(index){
        let thePoint = this.points.filter((v,k)=>{
        return v.value == index
      })
      this.currentInfo = thePoint[0]
      this.sums = this.currentInfo.sum
      this.list = []
      this.date = this.formatDate(new Date)
      this.page = {
        current_page:0,
        page_total:1,
        count:0,
        page_size:50,
      }
      this.finished = false;
      this.onLoad()
    },
    goBack(){
      this.$router.go(-1)
    },
    viewOrder(row){
     this.$router.push({
      path:'/points/putIn',
      query:{
        del_no:row.del_no,
        act:2
      }
     })
    },
    caigou(item){
      this.$dialog.confirm({
        title: '确认',
        message: '确认 '+item.goods_title+' 设置为 "'+this.getTitle(item.caigou_status)+'"？'
      }).then(() => {
        let to_caigoustatus = item.caigou_status + 1
        ajax.post('/caigou/changeDeliveryCaigouStatus',{delivery_id:item.id,to_caigoustatus,del_no:item.del_no}).then(res=>{
          let _this = this
          this.onRefresh()
        })
      }).catch(() => {
        this.$toast('放弃')
      });
    },
    getOptionsAndStatus(){
      this.loading = true
      ajax.get('/base/getOptionsAndStatus',{
        table:'DeliverySum',
        model:'areaManager'
      }).then(res=>{
        this.loading = false
        this.points = res.points
        this.status_arr = this.status_arr.concat(res.tabs)
        this.currentInfo = res.points[0]
        // this.onLoad()
      }).catch(e=>{
        this.$toast(e.msg)
        if(e.code==999){
          let _this=this
          setTimeout(a=>{
            this.$router.go(-1)
            return
          },1500)
        }
        this.loading = false
      })
    },
    onLoad(){
      this.filter.caigou_status=this.active
      this.filter.del_no = this.searchValue
      this.filter.points_id = this.point_id
      this.filter.date = this.date
      this.loading = true
      this.page.current_page=parseInt(this.page.current_page) + 1
      ajax.getList({
        table:'huishou',
        scence:'mg_huishou',
        page:this.page.current_page,
        filter:this.filter,
      }).then(res=>{
        this.loading = false
        // this.page.page_total = 50
        // this.page.current_page = 1
        // this.page.count = res.total

        // for (var i = 0; i <res.data.length; i++) {
            
        // }

        this.list = this.list.concat(res.data)
        if(parseInt(res.last_page)<=parseInt(res.current_page)){
          this.finished = true;
        }
      }).catch(e=>{
        this.$toast(e.msg)
        if(e.code==999){
          let _this=this
          setTimeout(a=>{
            this.$router.go(-1)
            return
          },1500)
        }
        this.loading = false
        this.finished = true;
      })
    },
    onRefresh() {
      this.isLoading = false;
      this.page.current_page = 0
      this.loading = true;
      this.finished = false;
      this.onLoad()
      this.list = []
      this.$toast('刷新成功')
    },
    onSearch(val) {
      this.onRefresh()
    },
    onCancel() {
      this.onRefresh()
    },
  }
}
</script>

<style scoped>
.goods{padding:5px 0;}
.goods_title{
  font-size:12px;color:#000;display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.bt{
  float: right;
}
.van-panel__content{padding:10px 16px!important;}
.flex{
  display: flex;
  justify-content: space-between;
}
.flex-ite{
  width: 25%;
  display: block;
  text-align: left;
margin-top: 7px;
}
</style>
