<template>
  <div class="demo">
    <!--    <van-notice-bar
      v-if="notice"
      :text="notice.title"
      left-icon="volume-o"
      mode="link"
      @click="newsDetail(notice.id)"
    /> -->
    <van-cell>
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
        "
      >
        <img src="@/assets/logo2.png" style="width: 40% !important" />
        <!-- <span><van-icon name="plus" color="#2C3E55" size='40'/></span> -->
        <van-icon
          name="wap-nav"
          color="#2C3E55"
          size="35"
          @click="showMenuValue = !showMenuValue"
          :class="showMenuValue ? 'go' : 'aa'"
        />
      </div>
    </van-cell>
    <template>
      <div class="parent">
        <van-swipe class="swipe" :autoplay="3000" v-if="banner">
          <van-swipe-item v-for="(image, index) in banner" :key="index">
            <img :src="image.path" @click="goUrl(index)" />
          </van-swipe-item>
        </van-swipe>
        <div class="menu" v-if="showMenuValue">
          <ul>
            <li @click="showMyQrcode = true">
              <img src="@/assets/kuaiji.png" /><span>快捷投放</span>
            </li>
            <li @click="checkHasNew">
              <img src="@/assets/jilu.png" /><span>投递记录</span>
            </li>
            <li @click="$router.push('/recycle/box')">
              <img src="@/assets/xiangti.png" /><span>附近箱体</span>
            </li>
            <li @click="showTask = true">
              <img src="@/assets/huanbao.png" /><span>环保任务</span>
            </li>
            <li @click="scoreRuleShow = !scoreRuleShow">
              <img src="@/assets/jifen.png" /><span>积分规则</span>
            </li>
          </ul>
        </div>
      </div>
    </template>
    <div>
      <!--       <van-grid :column-num="4">
        <van-grid-item icon="qr" text="快捷投放" @click="showMyQrcode=true" />
        <van-grid-item icon="balance-list-o" text="投递记录" @click="checkHasNew"/>
        <van-grid-item icon="location-o" text="附近箱体" to="/recycle/box"/>
        <van-grid-item icon="flag-o" text="环保任务" @click="showTask=true" />
      </van-grid> -->
    </div>
    <van-popup v-model="showMyQrcode" v-if="userInfo">
      <div style="text-align: center; padding: 10px">
        <qriously
          level="H"
          :value="userInfo.member_id"
          v-if="userInfo"
          :size="220"
        />
        用于登录箱体、定点投递厨余垃圾获取积分等
      </div>
    </van-popup>

    <div class="showWay" v-if="moreVisiable" @click="moreVisiable = false">
      <div class="show_list">
        <div class="show_list_con" @click="showMyQrcode = true">
          <van-icon name="qr" />
          <br />
          二维码登录
        </div>
      </div>
      <div class="show_list" @click="showTips">
        <div class="show_list_con">
          <van-icon name="scan" />
          <br />
          扫一扫
        </div>
      </div>
    </div>

    <div class="showWay" v-if="showTask" @click="showTask = false">
      <div class="show_list" @click="goQuestion">
        <div class="show_list_con">
          <van-icon name="question-o" /><br />
          答题
        </div>
      </div>
      <div class="show_list" @click="goSignin">
        <div class="show_list_con">
          <van-icon name="flag-o" /><br />
          签到
        </div>
      </div>
    </div>

    <div class="showWay" v-if="showOrderType" @click="showOrderType = false">
      <div class="show_list" @click="goNewOrder">
        <div class="show_list_con">
          <van-icon name="balance-list-o" /><br />
          最新投递
        </div>
      </div>
      <div class="show_list" @click="goOldOrder">
        <div class="show_list_con">
          <van-icon name="clock-o" /><br />
          历史投递
        </div>
      </div>
    </div>

    <!--   <van-cell style="margin-top: 10px;" @click="scoreRuleShow = !scoreRuleShow">积分规则【我的积分<count-to :startVal='0' :endVal='endValue' :duration='3000'></count-to>】</van-cell> -->
    <van-popup v-model="scoreRuleShow" style="width: 80%; height: 70%">
      <van-panel title="积分规则详情">
        <div v-html="userInfo.score_rules"></div>
      </van-panel>
    </van-popup>
    <van-cell class="display-area">
      <ul>
        <li style="background: #a7d64c" @click="$toast('业务调整中，敬请期待！')">
          <img src="@/assets/two.png" />
          <div>一站批发生鲜果蔬</div>
        </li>
        <li style="background: #008043" @click="$router.push('/manual')">
          <img src="@/assets/three.png" />
          <div>垃圾分类服务</div>
        </li>
        <li style="background: #005699" @click="$router.push('/huishou')">
          <img src="@/assets/one.png" />
          <div>高价回收废旧物资</div>
        </li>
        <li style="background: #434343" @click="$toast('即将上线，敬请期待！')">
          <img src="@/assets/four.png" />
          <div>二手物品互享互通</div>
        </li>
      </ul>
    </van-cell>
    <van-cell style="margin-top: 10px"
      ><a
        style="color: #434343"
        href="https://mp.weixin.qq.com/s/K4fzhaTFzbs4rFQ-CtoGZA"
        >智能设备操作说明</a
      ></van-cell
    >

    <van-panel title="乐乐动态" style="margin-top: 10px">
      <div v-for="item in news" class="news_list" @click="newsDetail(item.id)">
        <img :src="item.path" />
        <div class="news_info">
          <div class="news_title">{{ item.title }}</div>
        </div>
        <span class="news_time">{{ item.create_time }}</span>
      </div>
    </van-panel>
  </div>
</template>

<script>
import * as ajax from "@/api/common";
import util from "@/libs/util";
import countTo from "vue-count-to";
export default {
  name: "HomePage",
  data() {
    return {
      showMenuValue: false,
      scoreRuleShow: false,
      banner: [],
      notice: false,
      moreVisiable: false,
      showMyQrcode: false,
      showTask: false,
      userInfo: false,
      news: [],
      default_act: false,
      showOrderType: false,
      has_new: false,
      endValue: 0,
      good_news: true,
    };
  },
  components: {
    countTo,
  },
  mounted() {
    util.cookies.remove("yuyueData");
    this.default_act = this.$route.query.default_act
      ? this.$route.query.default_act
      : false;
    this.getUserInfo();
    this.getIndexData();
  },
  methods: {
    showMore() {
      this.moreVisiable = true;
    },
    checkHasNew() {
	  this.goNewOrder();
      // if (this.has_new) {
      //   this.showOrderType = true;
      // } else {
      //   this.showOrderType = false;
      //   //直接访问旧的订单列表
      //   this.goOldOrder();
      // }
    },
    showTips() {
      let _this = this;
      const toast1 = this.$toast.loading({
        mask: true,
        message: "请稍后...",
      });
      wx.scanQRCode({
        needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
        scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有, "barCode"
        success: (res) => {
          toast1.clear();
          let arr = res.resultStr.split("&b_id=");
          let qr = arr[0].split("qr_code=");
          if (arr.length > 0) {
            _this.$router.push({
              path: "authLogin",
              query: {
                b_id: arr[1],
                qr_code: qr[1],
              },
            });
          }
        },
        fail: (err) => {
          alert(res.resultStr);
          _this.$toast({ message: "请稍后重试", duration: 1500 });
        },
      });
    },
    goUrl(idx) {
      let url = this.banner[idx].url;
      if (url != "" && url != "#") {
        location.href = url;
      }
    },
    goOldOrder() {
      this.$router.push("/my/recycleOrder");
    },
    goNewOrder() {
      this.$router.push("/my/recycleNewOrder");
    },
    goQuestion() {
      this.$router.push("/question");
    },
    goSignin() {
      this.$router.push("/signin");
    },
    newsDetail(id) {
      this.$router.push("/news/detail/" + id);
    },
    getUserInfo() {
      // let info = this.$store.dispatch('lele/user/load').then(res=>{
      //   this.userInfo=res
      //   this.userInfo.member_id=this.userInfo.member_id.toString()
      //   if(this.default_act=='quick'){
      //     this.showMyQrcode=true
      //   }
      //   this.loadUser=true
      // }).catch(e=>{
      //   this.userInfo=false
      // })

      ajax.getUserInfo({ showLoading: false }).then(async (res) => {
        util.cookies.set("uuid", res.id);
        util.cookies.set("token", res.token);
        this.$store.dispatch("lele/user/setUserInfo", res);
        this.userInfo = res;
        this.endValue = res.score_info.score;
        this.userInfo.member_id = this.userInfo.member_id.toString();
        if (this.default_act == "quick") {
          this.showMyQrcode = true;
        }
      });
    },
    getIndexData() {
      ajax
        .get("/Base/getIndexData", { showLoading: false })
        .then(async (res) => {
          this.banner = res.banner;
          this.news = res.news;
          this.notice = res.notice;
          this.has_new = res.has_new;
          this.$store.commit("lele/user/setHasNew", res.has_new);
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
<style>
.news_list img {
  width: 60px !important;
  height: 60px !important;
}
</style>
<style scoped>
.swipe img {
  width: 100%;
}
.showWay {
  position: fixed;
  z-index: 100;
  left: 0px;
  right: 0px;
  bottom: 0px;
  top: 0px;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 0 40px;
}
.show_list {
  width: 50%;
  height: auto;
  text-align: center;
  float: left;
  margin-top: 160px;
}
.show_list_con {
  width: 100px;
  height: 100px;
  text-align: center;
  background-color: #fff;
  color: #333;
  margin: 0 auto;
  border-radius: 8px;
}
.show_list_con i {
  font-size: 40px;
  line-height: 40px;
  margin-top: 20px;
}
.news_list {
  background-color: #fff;
  padding: 10px;
  border-bottom: 1px solid #f3f3f3;
  position: relative;
  font-size: 14px;
}
.news_list .news_info {
  position: absolute;
  left: 80px;
  right: 10px;
  top: 10px;
  bottom: 10px;
  font-size: 14px;
  color: #333;
}
.news_time {
  position: absolute;
  left: 80px;
  bottom: 10px;
  color: #999;
}
.display-area {
  background: none;
}
.display-area ul {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: center;
}
.display-area ul > li {
  width: 49%;
  margin-top: 5px;
  border-radius: 2px;
  font-size: 14px;
  color: #fff;
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: center;
}
.display-area li img {
  width: 51% !important;
}
.display-area li div {
  width: 90%;
  text-align: center;
}
.parent {
  position: relative;
}
.menu {
  position: absolute;
  top: 0px;
  right: 1px;
  height: 100%;
}
.menu ul {
  display: block;
  border: 1px solid #010b00;
  box-sizing: border-box;
  height: 98%;
}
.menu ul li {
  display: block;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  display: -webkit-flex;
  justify-content: space-around;
  align-items: center;
  box-sizing: border-box;
  border-bottom: 1px solid #7d7d7b;
  height: 20%;
  font-size: 14px;
  font-weight: bold;
}
.menu ul li:nth-child(5) {
  border-bottom: none;
}
.menu ul li img {
  width: 20% !important;
  height: 60%;
}
.aa {
  transition: all ease 0.2s;
}
.go {
  transform: rotate(-90deg);
  transition: all ease 0.2s;
}
</style>
