import store from '@/store'
import axios from 'axios'
import util from '@/libs/util'
import loading from '@/libs/loading'
import message from '@/libs/message'
// 记录和显示错误
function errorLog (error) {
  // 打印到控制台
  if (process.env.NODE_ENV === 'development') {
    util.log.danger('>>>>>> Error >>>>>>')
  }
  // 显示提示
  message.fail({
    fail: {
        type: 'notify',
        options: {
            message: error.message,
            type: 'danger'
        }
    }
  })
}
let isWeiXin = () => { return navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1 }
// 创建一个 axios 实例
const service = axios.create({
  baseURL: process.env.VUE_APP_API,
  timeout: 20000 // 请求超时时间
})

// 请求拦截器
service.interceptors.request.use(
  config => {
    if(config.loading){
    loading.show(config)
    }
    // 在请求发送之前做一些处理
    if (!(/^https:\/\/|http:\/\//.test(config.url))) {
      const token = util.cookies.get('token')
      if (token && token !== 'undefined') {
        // 让每个请求携带token-- ['Authorization']为自定义key 请根据实际情况自行修改
        config.headers['token'] = token
      }else{
        config.headers['token'] = 'noToken'
      }
        config.headers['device'] = 'h5'
    }
    return config
  },
  error => {
    // 发送失败
    Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  response => {
    if(response.config.loading){
      loading.hide(response.config)
    }
    const res = response.data;
    if(response.config.success){
    response.config.success.options.message = response.config.success.message?response.config.success.message:response.data.msg
    }
    if(response.config.fail){
    response.config.fail.options.message = response.config.fail.message?response.config.fail.message:response.data.msg
    }
    if ( res.code != 0 ) {
      if(response.config.fail){
        message.fail(response.config)
      }
      if(res.code===401){
        util.cookies.remove('token')
        util.cookies.remove('uuid')
        util.cookies.set('redirect',window.location.href)
        if(isWeiXin()){
          window.location.href="/wechat"
        }else{
          window.location.href="/login"
        }
      }
      return Promise.reject(res);
    } else {
      if(response.config.success){
        message.success(response.config)
      }
      return res.data;
    }
  },
  error => {
    if(error.config.loading){
      loading.hide(error.config)
    }
    if (error.response && error.response.data.code === 401) {
      util.cookies.remove('token')
      util.cookies.remove('uuid')
      util.cookies.set('redirect',window.location.href)
      if(error.response.config.fail){
        error.response.config.fail.options.message = error.response.config.fail.message?error.response.config.fail.message:error.response.data.msg
        message.fail(error.response.config)
      }
      if(isWeiXin()){
        window.location.href="/wechat"
      }else{
        window.location.href="/login"
      }
    } else if (error.response && error.response.status === 500) {
      errorLog(new Error(`出错了，请稍后再试~`))
    } else if (error.message && error.message.indexOf("timeout") > -1) {
      errorLog(new Error(`网络超时!: ${error.config.url}`))
    } else if (error.type === "403") {
      errorLog(new Error(`没有权限!`))
    } else {
      errorLog(new Error(`网络错误!: ${error.response.data.msg}`))
    }
    return Promise.reject(error.response.data)
  }
)

export default service
