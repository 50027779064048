<template>
  <div>
    <van-nav-bar
      v-if="notInWx"
      title="请登陆"
    />
    <section class="login">
      <van-cell-group>
        <van-field
          v-model="user.phone"
          clearable
          label="账号"
          placeholder="请输入手机号"
        />
      </van-cell-group>
      <van-cell-group>
        <van-field
          v-model="user.passwd"
          type="password"
          label="密码"
          placeholder="请输入密码"
        />
      </van-cell-group>
      <div style="text-align: center;">
        <van-button class="login_bt" type="primary" @click="submit" round>立即登陆</van-button>
      </div>
    </section>
</div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'LoginPage',
  data(){
    return {
      user:{
        phone:'1363547681',
        passwd:'11111',
      },
      notInWx:false
    }
  },
  mounted () {
  },
  methods:{
    ...mapActions('lele/account', [
      'login',
    ]),
    submit () {
      if(!this.user.phone||!this.user.passwd){
        this.$toast('请填写完整内容')
        return
      }
      this.login({
        phone: this.user.phone,
        passwd: this.user.passwd,
        login_type:'account',
      })
        .then(() => {
          // 重定向对象不存在则返回顶层路径
          this.$router.replace(this.$route.query.redirect || '/')
        }).catch((res)=>{
        })
    }
  }
}
</script>

<style scoped>
.login{margin:10px 0;}
.login_bt{margin:10px 0;width:90%;}
</style>
