<template>
  <div id="underway_page">
    <van-nav-bar :title="info.has_family?info.month+'签到日历':'提示信息'"
      left-text="返回"
      left-arrow
      @click-left="$router.push('/')"
    />
    <div class="cell_space"></div>
    <template v-if="show">
      <div v-if="info.has_family">
        
      <div class="sign">
        <div class="tips_btn" @click="signDo">
        <div class="today">{{info.today}}</div>
        <span v-if="info.today_has_sign">今日已签到</span>
        <span v-else>签到</span>
        </div>
      </div>
      <div class="sign_info">
        <span>本月连续签到<van-tag type="success" style="margin:0 5px;">{{info.continuous}}天</van-tag></span>
        <span>累计签到<van-tag type="success" style="margin:0 5px;">{{info.all}}天</van-tag></span>
      </div>
        <div class="th">
          <span v-for="w in week_arr">{{w}}</span>
        </div>
        <div class="td">
          <span v-for="item in date_arr">
            <span v-if="item.is_sign" class="active">{{item.day}}</span>
            <span v-else>{{item.day}}</span>
            <!-- <i class="el-icon-check" v-if="item.is_sign"></i> -->
          </span>
        </div>
  
        <div class="prev_next">
          <van-row type="flex" justify="center">
            <van-col span="6" style="text-align:center">
              <van-button icon="arrow-left" type="primary" @click="prev"/>
            </van-col>
            <van-col span="6" style="text-align:center">
              <van-button icon="arrow" type="primary" @click="next" />
            </van-col>
          </van-row>
        </div>

        <div class="score_rule">
          <div v-html="rule.content"></div>
        </div>

      </div>
      <template v-else>
        <p>请先加入一个家庭组</p>
      </template>
    </template>
    
  </div>
</template>

<script>
  import * as ajax from "@/api/common"
  export default {
    name:'signinPage',
    data() {
      return {
        week_arr:[],
        date_arr:[],
        info:[],
        show:false,
        result:[],
        rule:'',
        userInfo:[],
        m:0
      }
    },
    created(){
      this.getUserInfo()
    },
    methods: {
      getUserInfo(){
        ajax.getUserInfo().then(async res => {
          this.$store.dispatch('lele/user/setUserInfo',res)
          this.userInfo = res
          if(!res.family){
            this.$dialog.confirm({
              title:'提示',
              message: '尚未加入家庭组',
              cancelButtonText: '取消',
              confirmButtonText: '加入家庭组'
            }).then(() => {
              this.goGroup()
            }).catch(() => {
              this.goHome()
            });
          }else{
            this.getDateArr()
          }
        })
      },
      goHome(){
        this.$router.push('/');
      },
      goGroup(){
        this.$router.push('/my/family');
      },
      prev(){
        this.m--
        this.getDateArr()
      },
      next(){
        this.m++
        this.getDateArr()
      },
      getDateArr(){
        ajax.get('/Score/getDateArr',{member_id:this.userInfo.member_id,date_num:this.m,catalog_path:'score_sign'}).then(res => {
          this.week_arr = res.week_arr
          this.date_arr = res.date_arr
          this.info = res.info
          if(this.m==0){
          this.rule = res.rule
          }
          this.show = true
        }).catch(error=>{
          if(error.code==4003){
            this.$dialog.confirm({
              title:'提示',
              message: error.msg,
              cancelButtonText: '取消',
              confirmButtonText: '去看看'
            }).then(() => {
              this.goGroup()
            }).catch(() => {
              this.goHome()
            });
            return
          }else{
            this.$toast({message: error.msg,duration:1500})
            return
          }
          
        })
      },
      signDo(){
        if(this.info.today_has_sign){
          this.$toast.fail({message: '已经签过啦',duration:1500})
          return
        }
        ajax.post('/Score/applyScore',{member_id:this.userInfo.member_id,way:2,showTips:true}).then(res => {
          this.m=0
          this.getDateArr()
        }).catch(error=>{
          this.$toast.fail({message: error.msg,duration:1500})
        })
      },
    },
    mounted() {
    },
  }
</script>

<style lang="scss" >
  .score_rule{padding:15px 20px;}
  .today{text-align:center;font-size:16px;color:#fff;font-size:20px;padding-top:30px;}
  .tips_btn{border-radius: 50%;width:100px;height:100px;text-align:center;background:#ff6600;color:#fff;margin:10px auto;}
  .sign_info{text-align:center;line-height:40px;margin:10px auto;}
  .th{
    border-bottom:1px solid #dedede;
    background:#f3f3f3;
    height:40px;
    line-height:40px;
  }
  .th span{display:inline-block;text-align:center;width:14.2857%;}
  .td span{width:14.2857%;display:inline-block;text-align:center;height:40px;line-height:40px;position: relative;}
  .td span i{color:#cc0000;position: absolute;font-weight: bolder;}
  .td .active{border-radius: 50%;background-color: #0fbf0d;color: #fff;width:30px;height: 30px;line-height: 30px;}
</style>
