<template>
  <div class="mall">
    <van-sticky>
    <van-nav-bar
      title="查看详情"
      left-text="返回"
      right-text="结束工单"
      left-arrow
      @click-left="goBack"
      @click-right="toClose"
    />
    </van-sticky>
    <template v-if="hasLoad">
      <van-tabs sticky v-model="active" @disabled="onClickDisabled">
        <van-tab title="详情">
          <van-cell title="类型" :value="result.typename"/>
          <van-cell title="工单号" :value="result.feed_no"/>
          <van-cell title="联系电话" :value="result.phone?result.phone:'未提供'"/>
          <van-cell title="当前状态" :value="result.status_txt"/>
          <van-cell title="创建时间" :value="result.create_time"/>
          <van-cell>
            <template slot="title">
              <span class="custom-title">反馈内容</span>
            </template>
            <template slot="label">
              <div style="font-size: 14px;line-height: 25px;">{{result.content}}</div>
              <div class="img_lista" v-if="result.imgs.length>0">
                <van-image
                  class="imgs"
                  fit="contain"
                  :src="img.url"
                  v-for="(img,idx) in result.imgs"
                  @click="viewImg(result.imgs,idx)"
                />
              </div>
            </template>
          </van-cell>
        </van-tab>
        <van-tab title="处理进展">
          <van-steps direction="vertical" :active="result.log.length-1">
            <van-step v-for='item in result.log'>
              <div>【{{item.user_info.name}}】{{item.content}}</div>
              <div>{{item.create_time}}</div>
              <div class="img_lista" v-if="item.imgs.length>0">
                <van-image
                  class="imgs"
                  fit="contain"
                  :src="img.url"
                  v-for="(img,idx) in item.imgs"
                  @click="viewImg(item.imgs,idx)"
                />
              </div>
            </van-step>
          </van-steps>
        </van-tab>
        <van-tab title="反馈补充" :disabled="result.status==4?true:false">
          <van-cell-group>
            <van-field
              v-model="save_data.content"
              rows="2"
              autosize
              label="反馈内容"
              type="textarea"
              maxlength="200"
              placeholder="反馈内容"
              show-word-limit
            />
            <van-field
              readonly
              label="相关图片"
              :placeholder="'最多上传'+maxCount+'张'"
            />
            <div class="upimg">
              <div class="img_list" v-for="(img,idx) in this.save_data.imgs">
                <van-image
                  class="imgs"
                  fit="contain"
                  :src="img.url"
                />
                <van-icon class="delimg" @click="delImg(idx)" name="cross" />
              </div>
              <van-uploader :after-read="afterRead" v-model="files" v-if="save_data.imgs.length<maxCount" :preview-image="false" :before-read="beforeRead"/>
              <div style="clear: both;height: 1px;"></div>
            </div>
          </van-cell-group>

          <div style="text-align:center;margin:0px 20px;margin-top: 10px;">
            <van-button type="primary" size="large" @click="onSubmit">提交反馈</van-button>
          </div>

        </van-tab>
      </van-tabs>
      <template v-if="showImg">
        <van-image-preview
        v-model="showImg"
        :closeable="true"
        :images="images"
        :start-position="index"
        @change="onChange"
        >
        <template v-slot:index>{{index+1}}/{{images.length}}</template>
        </van-image-preview>
      </template>
    </template>
    <template v-else>
      <center>请稍后</center>
    </template>

  </div>
</template>

<script>
import * as ajax from "@/api/common"
export default {
  name: 'MallOrderDetailPage',
  data(){
    return {
      active:0,
      hasLoad:false,
      fid:0,
      result:{},
      index:0,
      showImg:false,
      images:[],
      save_data:{
        f_id:'',
        content:'',
        imgs:[],
        user_type:1,
      },
      files:[],
      maxCount:3,
    }
  },
  watch: {

  },
  mounted () {
    this.fid=this.$route.query.id
    this.getDetail()
  },
  methods:{
    onChange(index){
      this.index=index
    },
    viewImg(imgs,idx){
      this.images = imgs.map(item=>{
        return item.url
      })
      this.showImg=true
      this.index = idx
    },
    onClickDisabled(name, title) {
      this.$toast('当前状态不可操作，如有需要可重新发起新反馈');
    },
    getDetail(){
      if(!this.fid){
        this.$toast('缺少订单号')
        return
      }
      ajax.getDetail({table:'FeedBack',id:this.fid,user_type:1}).then(res=>{
        this.result=res
        this.save_data.f_id = res.id
        this.hasLoad=true
      }).catch(e=>{
        this.$toast(e.msg)
        return
      })
    },
    goBack(){
        this.$router.go(-1)
    },
    // 返回布尔值
    beforeRead(file) {
      if (file.type !== 'image/jpeg' || file.type !== 'image/png') {
        this.$toast('请上传 jpg/png 格式图片');
        return false;
      }
      return true;
    },
    // 返回 Promise
    asyncBeforeRead(file) {
      return new Promise((resolve, reject) => {
        if (file.type !== 'image/jpeg' || file.type !== 'image/png') {
          this.$toast('请上传 jpg/png 格式图片');
          reject();
        } else {
          resolve();
        }
      });
    },
    beforeRead(file){
      if(file.length>this.maxCount){
        this.$toast('最多上传'+this.maxCount+'张')
        return false
      }
      return true
    },
    afterRead(files) {
      if(files.length==undefined){
        files=[files]
      }
      let formData = new FormData()
      for(let f of files){
        formData.append('file_data', f.file)
        ajax.post('/Upload/toSave',formData).then(res=>{
            this.save_data.imgs.push({
              name:res.savename,
              url:res.path,
            })
        }).catch(e=>{
          console.log(e)
        })
      }
    },
    delImg(idx){
      this.save_data.imgs.splice(idx,1)
    },
    onSubmit(){
      this.save_data.table="FeedBack"
      this.save_data.showTips=true
      //旧系统 添加预约订单
      ajax.post('/Fankui/reBack',this.save_data).then(res=>{
      this.active=1
      this.save_data={
        f_id:'',
        content:'',
        imgs:[],
        user_type:1,
      }
      this.getDetail()
      }).catch(error=>{
        this.$toast(error.msg)
      })
    },
    toClose(){
      this.$dialog.confirm({
        title: '确认',
        message: '确认要结束吗'
      }).then(() => {
        ajax.get('/Fankui/done',{f_id:this.fid,table:'FeedBack',user_type:1,showTips:true}).then(res=>{
          let _this = this
          setTimeout(()=>{
            _this.getDetail()
          },500)
        })
      }).catch(() => {
        this.$toast('放弃')
      });
    }
  }
}
</script>

<style scoped>
.van-panel__content{
  font-size:14px;
  line-height:24px;
  padding:15px;
}
.van-cell:not(:last-child)::after{left:0px!important;}
.upimg{padding:10px;}
.img_lista{width:100%;height: 80px;margin:6px 0;}
.img_list{width:80px;height: 80px;position: relative;margin-right:6px;float: left;}
.imgs{border-radius: 5px;width:80px;height: 80px;float: left;margin-right:6px;}
.upimg .img_list .delimg{position: absolute;z-index: 2;right:5px;top:5px;font-size: 16px;}
</style>
