<template>
  <div class="demo">
    <van-tabs v-model="active">
  <van-tab title="公司介绍">
    <van-cell class="info">
    我司是集垃圾分类智能设备、智慧管理软件研发、生产、销售以及垃圾分类系统运营管理为一体的综合服务公司。在垃圾分类项目运营服务中，本着可持续、可复制、易推广的原则，我司采取智能化分类设备、智慧化管理软件逐步替代传统人工的运营模式。通过阶段性的监督、激励和指导，达到让居民习惯在家庭源头进行垃圾分类的最终目的，目前我司在重庆、成都、湖南、昆明等城市均有成功案例。
    </van-cell>
  </van-tab>
  <van-tab title="部分产品">
    <img src="@/assets/product.jpg">
  </van-tab>
</van-tabs>
  </div>
</template>

<script>
import * as ajax from "@/api/common"
export default {
  name: 'manual',
  data(){
    return {
      active:0
    }
  },
  mounted () {
    this.default_act = this.$route.query.default_act?this.$route.query.default_act:false
  },
  watch:{
  },
  methods:{
  }
}
</script>
<style scoped>
.info{
  text-indent: 2em;
}
</style>
