<template>
  <div class="mall">
    <van-sticky>
    <van-nav-bar
      title="订单详情"
      left-text="返回"
      left-arrow
      @click-left="goBack"
    />
    </van-sticky>
    <template v-if="hasLoad">
      <van-cell icon="location-o" v-if="orderInfo.address">
        <template slot="title">
          <span class="custom-title">{{orderInfo.address.name}}</span>
          <van-tag type="danger" v-if="orderInfo.address.is_def">默认</van-tag>
        </template>
        <template slot="label">
          {{orderInfo.address.phone}}<br/>{{orderInfo.address.ssq}}-{{orderInfo.address.address}}
        </template>
      </van-cell>
      <van-cell style="margin-top:10px;">
        <template slot="title">
          <span class="custom-title">订单号</span>
        </template>
        <template slot="right-icon">
          <span>{{orderInfo.order_no}}</span>
        </template>
      </van-cell>
      <van-cell>
        <template slot="title">
          <span class="custom-title">创建时间</span>
        </template>
        <template slot="right-icon">
          <span>{{orderInfo.create_time}}</span>
        </template>
      </van-cell>
      <van-card
        class="list"
        v-for="item in orderInfo.goods_info"
        :num="item.num"
        :price="item.price"
        :desc="item.sku_name"  
        :title="item.goods_title"
        :thumb="item.path"
      >
      <div slot="footer">
        小计 ￥{{item.num*item.price}}
      </div>
      </van-card>
      <van-cell style="margin-top:10px;">
        <template slot="title">
          <span class="custom-title">总金额</span>
        </template>
        <template slot="right-icon">
          <span style="color:#ff0000;">￥{{orderInfo.total}}</span>
        </template>
      </van-cell>
      <van-cell>
        <template slot="title">
          <span class="custom-title">积分抵扣</span>
        </template>
        <template slot="right-icon">
          <span style="color:#ff0000;">-￥{{(orderInfo.score+orderInfo.gift_score)/100}}</span>
        </template>
      </van-cell>
      <van-cell>
        <template slot="title">
          <span class="custom-title">金币抵扣</span>
        </template>
        <template slot="right-icon">
          <span style="color:#ff0000;">-￥{{orderInfo.coin}}</span>
        </template>
      </van-cell>
      <van-cell v-if="orderInfo.discounts>0">
        <template slot="title">
          <span class="custom-title">优惠</span>
        </template>
        <template slot="right-icon">
          <span style="color:#ff0000;">-￥{{orderInfo.discounts}}</span>
        </template>
      </van-cell>
      <van-cell>
        <template slot="title">
          <span class="custom-title">实付款</span>
        </template>
        <template slot="right-icon">
          <span style="color:#ff0000;">￥{{orderInfo.pay}}</span>
        </template>
      </van-cell>
      <van-cell :label="orderInfo.mark">
        <template slot="title">
          <span class="custom-title">备注</span>
        </template>
      </van-cell>

      <template v-if="orderInfo.pay_way>0">
        <van-cell style="margin-top:10px;" :value="orderInfo.pay_time">
          <template slot="title">
            <span class="custom-title">支付时间</span>
          </template>
        </van-cell>
        <van-cell :value="orderInfo.pay_way_txt">
          <template slot="title">
            <span class="custom-title">支付方式</span>
          </template>
        </van-cell>
        <van-cell :value="orderInfo.pay_no">
          <template slot="title">
            <span class="custom-title">流水号</span>
          </template>
        </van-cell>
      </template>
      
      <div class="bottom_bt" v-if="orderInfo.status==1">
        <div class="txt">实付款 <span>{{orderInfo.pay}}</span></div>
        <div class="bt">
          <van-button type="danger" size="large" @click="toPay">去支付</van-button>
        </div>
      </div>
      <template v-if="orderInfo.status>=2">

        <van-collapse v-model="activeName" accordion>
          <van-collapse-item :title="'配送信息'+(ii+1)" :name="ii+1" v-for="(del,ii) in orderInfo.delivery">
            <div class="psinfo">
              <div>配送单号：{{del.del_no}}</div>
              <div>配送状态：{{del.r_status_txt}}</div>
              <div>物品名称：{{del.goods_title}}</div>
              <div>规格数量：{{del.sku_name+' * '+del.num}}</div>
              <div v-if="del.status>1">发货时间：{{del.send_time}}</div>
              <template v-if="del.status>=3 && del.points_info">
              <div>取件地址：{{del.points_info.address}}</div>
              <div>联系人：<a :href="'tel:'+del.points_info.phone">{{del.points_info.name+','+del.points_info.phone}}</a></div>
              <div>到达时间：{{del.in_time}}</div>
              <div>取货码：<van-tag type="danger">{{del.ms_code}}</van-tag></div>
              <div><qriously level="H" :value="del.ms_code.toString()" :size="220" /></div>
            </template>
            </div>
          </van-collapse-item>
        </van-collapse>
      </template>

      <template v-else>
        <van-button type="default" size="large">{{orderInfo.status_txt}}</van-button>
      </template>
    </template>

    <template v-else>
      <center>请稍后</center>
    </template>

  </div>
</template>

<script>
import * as ajax from "@/api/common"
import wx from 'weixin-js-sdk';
export default {
  name: 'MallOrderDetailPage',
  data(){
    return {
      activeName:['1'],
      hasLoad:false,
      order_id:0,
      orderInfo:{},
      error:false,
      order_no:'',
      froms:false
    }
  },
  watch: {

  },
  mounted () {
    this.order_id=this.$route.query.id
    this.froms=this.$route.query.froms?this.$route.query.froms:false
    this.getDetail()
  },
  methods:{
    getDetail(){
      if(!this.order_id){
        this.$toast('缺少订单号')
        return
      }
      ajax.getDetail({table:'MallOrder',id:this.order_id}).then(res=>{
        this.orderInfo=res
        this.hasLoad=true
      }).catch(e=>{
        this.$toast(e)
        return
      })
    },
    toPay(){
      var _this=this
      ajax.post('/Pay/toPay',{order_no:this.orderInfo.order_no,pay_way:1}).then(res=>{
          const Data = JSON.parse(res)
          this.wexinPay(Data,function(res){
            _this.getDetail()
          },function(r){
            _this.$toast(r.errMsg)
          },function(r){
            _this.$toast('取消支付')
          })
      }).catch(e=>{
        _this.$toast(e)
        return
      })
    },
    wexinPay(data, cb, errorCb,cancelCb) {
      var _this=this
        //获取后台传入的数据
        let appId = data.appId;
        let timestamp = data.timeStamp;
        let nonceStr = data.nonceStr;
        let signature = data.signature;
        let packages = data.package;
        let paySign = data.paySign;
        let signType = data.signType;
        //下面要发起微信支付了
        wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: appId, // 必填，公众号的唯一标识
            timestamp: timestamp, // 必填，生成签名的时间戳
            nonceStr: nonceStr, // 必填，生成签名的随机串
            signature: signature, // 必填，签名，见附录1
            jsApiList: ['chooseWXPay'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
        });
        wx.ready(function () {
          wx.chooseWXPay({
            timestamp: timestamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
            nonceStr: nonceStr, // 支付签名随机串，不长于 32 位
            package: packages, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=***）
            signType: signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
            paySign: paySign, // 支付签名
            success: function (res) {
                // 支付成功后的回调函数
                cb(res);
            },
            cancel: function (res) {
              cancelCb(res)
            },
            fail: function (res) {
                //失败回调函数
                errorCb(res);
            }
          });
        });
        wx.error(function (res) {
          _this.$toast((res.errMsg))
        })
    },
    goBack(){
      if(this.froms){
        this.$router.push('/my/mallOrder')
      }else{
        this.$router.go(-1)
      }
    },
  }
}
</script>

<style scoped>
.list{background:#fff;}
.bottom_bt{
  position: fixed;
  bottom: 0;
  left: 0;
  right:0;
  background:#fff;
  z-index:10;
  height: 50px;
}
.bottom_bt .txt{
  text-align:left;
  padding-left:12px;
  float:left;
  font-size:14px;
  line-height:50px;
}
.bottom_bt .txt span{color:#ff0000;font-size:18px;}
.bottom_bt .bt{width:110px;position: absolute;right:0px;z-index:10;}
.psinfo{color: #666;font-size: 13px;line-height: 2.5rem;}
</style>
