<template>
  <div class="mall">
    <van-nav-bar
      title="查询结果"
      left-text="返回"
      left-arrow
      @click-left="goBack"
    />
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
    <template>

<van-sticky>
      <form action="/">
        <van-search placeholder="输入要找的商品名" :maxlength="10" v-model="filter.keyword" @search="onSearch" />
      </form>
      </van-sticky>
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="finished_txt"
        @load="onLoad"
      >
	  
        <van-row gutter="10" style="margin:0 8px;">
          <van-col span="12" class="item" v-for="(item,idx) in goodsList" @click="viewDetail(item.id)">
                <div class="wrapper">
   <!--             <van-image
                  width="100%"
                  height="150px"
                  fit="cover"
                  :src="item.path"
                /> -->
				<img src="" alt="" width="100%" height="150px" v-lazy="item.path">
                <div class="info">
                  <div class="goods_title">{{item.title}}</div>
                  <div>
                  <span class="price"  v-if='item.vip_price'><b>VIP￥{{item.vip_price}}</b></span>
                  <span class="price_del">零售￥{{item.price}}</span>
                  </div>
                </div>
                </div>
          </van-col>
        </van-row>

      </van-list>
    </template>
<!--     <template v-else>
            <van-tabs sticky v-model="activeKey" @click="onRefresh" v-if="hasLoad">
        <van-tab :title="type.typename" v-for="(type,idx) in types">
          <van-list
            v-model="loading"
            :finished="finished"
            :finished-text="finished_txt"
            @load="onLoad"
          >
            <van-row gutter="10" style="margin:0px 8px;">
              <van-col span="12" class="item" v-for="(item,idx) in goodsList" @click="viewDetail(item.id)">
                <div class="wrapper">
                <van-image
                  width="100%"
                  height="150px"
                  fit="cover"
                  :src="item.path"
                />
                <div class="info">
                  <div class="goods_title">{{item.title}}</div>
                  <div>
                  <span class="price"  v-if='item.vip_price'><b>VIP￥{{item.vip_price}}</b></span>
                  <span class="price_del">零售￥{{item.price}}</span>
                  </div>
                </div>
                </div>
              </van-col>
            </van-row>

          </van-list>
        </van-tab>
      </van-tabs>
      <div style="color: #666;line-height: 80px;" v-else><center>请稍后~</center></div>
    </template> -->
  </van-pull-refresh>
  </div>
</template>

<script>
import * as ajax from "@/api/common"
export default {
  name: 'MallPage',
  data(){
    return {
      types:[{
        id:0,
        typename:'全部',
      }],
      activeKey:-1,
      loading:false,
      searchPage:true,
      isLoading:false,
      finished:false,
      hasLoad:false,
      goodsList:[],
      finished_txt:'没有更多了',
      page:{
        page_total:0,
        current_page:0,
        count:0,
        page_size:10
      },
      filter:{
        typeid:0,
        status:1,
        keyword:''
      }
    }
  },
  mounted () {
    this.filter.typeid=this.$route.query.typeid?this.$route.query.typeid:0
    this.filter.keyword=this.$route.query.keyword?this.$route.query.keyword:''
    // if(this.filter.typeid){
    //   this.searchPage=false
    //   this.getType(this.filter.typeid)
    // }else{
    //   this.finished_txt='建议更搜索关键词，再试试~'
    //   this.onLoad()
    // }
  },
  methods:{
    goBack(){
      this.$router.go('-1')
    },
    getType(nid){
      ajax.get('/Base/mallIndex',{table:'GoodsType'}).then(res=>{
        res.types.map((item,idx)=>{
          if(item.id==parseInt(nid)){
            this.activeKey=idx+1
          }
          this.types.push(item)
        })
        this.hasLoad=true
        this.isLoading=false
        this.onLoad()
      })
    },
    viewDetail(val){
      this.$router.push({
        path:'detail',
        query:{
          id:val
        }
      })
    },
    onLoad(){
      this.loading = true
      this.types.map((item,idx)=>{
        if(idx==this.activeKey){
          this.filter.typeid=item.id
        }
      })
      let p= parseInt(this.page.current_page)+1
      ajax.getList({
        table:'Goods',
        scence:'mg_mall_goods_list',
        page:p,
        page_size:this.page.page_size,
        filter:this.filter
      }).then(res=>{
        this.loading = false
        this.page.page_total = res.last_page
        this.page.current_page = res.current_page
        this.page.count = res.total
        for (var i = 0; i <res.data.length; i++) {
          this.goodsList.push(res.data[i])
        }
        if(parseInt(res.last_page)>0){
          this.finished_txt='没有更多了~'
        }else{
          if(this.searchPage){
            this.finished_txt='建议更搜索关键词，再试试~'
          }
        }
        if(parseInt(res.last_page)<= p){
          this.finished = true;
        }
      })
    },
    onSearch(val){
      if(!val){
        this.$toast('请输入关键词')
        return
      }
      this.filter.keyword=val
      this.onRefresh()
    },
    onRefresh() {
      this.goodsList=[]
      this.page={
        page_total:0,
        current_page:0,
        count:0,
        page_size:10
      }
      this.finished = false;
      this.onLoad()
      this.isLoading = false
    }
    
  }
}
</script>

<style scoped>
.goods_title{
  font-size:14px;color:#000;display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.item{background:#fff;box-sizing: border-box;margin-bottom: 1em;}
.item .info{
  text-align: center;
}
.item .price{color:#ff0000;font-weight:200;}
.item .price b{font-size:12px;font-weight:bold;;color: #EE0C24;margin-right: 3px;}
.item .price_del{font-weight:bold;font-size:12px;}
.wrapper{
  padding:20px 3px;
  /*border: 1px solid #EE0C24;*/
  box-shadow:0px 10px 20px #DFDFE1; -webkit-box-shadow:0px 10px 20px #DFDFE1; box-shadow:0px 10px 20px #DFDFE1;
  box-sizing: border-box;
}
</style>
