<template>
  <div class="empty" v-if="ok">
    <van-empty
  class="custom-image"
  image="https://img.yzcdn.cn/vant/custom-empty-image.png"
  description="我们已经收到您的预约订单，稍后会有工作人员联系您"
/>
  </div>
  <div class="demo" v-else>
    <div v-if="find">
      <van-empty image="search" description="没有找到相关信息" />
    </div>
    <div v-else>
    <van-panel title="在线预约">
  <div><van-tag type="success">名称</van-tag> <span class="address">{{info.title}}</span></div>
  <div><van-tag type="success">地址</van-tag> <span class="address">{{info.address}}</span></div>
</van-panel>
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <van-cell-group>
          <van-field
            v-model="save_data.desc"
            rows="2"
            autosize
            label="物品描述"
            type="textarea"
            maxlength="200"
            placeholder="简单描述下物品，长度5~200字符"
            show-word-limit
          />
          <van-field
            v-model="save_data.user_weight"
            label="预估重量"
            type="number"
            placeholder="请填写物品预估重量"
          >
          <span slot="button">千克</span>
          </van-field>
          <van-popup v-model="showDate" position="bottom">
            <van-datetime-picker
              v-model="currentDate"
              type="datetime"
              :min-date="minDate"
              @cancel="showDate = false"
              @confirm="onDateConfirm"
              :formatter="formatter"
            />
          </van-popup>
          <van-field
            readonly
            v-model="save_data.visit_time"
            label="上门时间"
            placeholder="请选择上门时间"
            right-icon="arrow"
            @click="showDate = true"
          >
          </van-field>
          <van-field
            v-model="save_data.phone"
            label="联系电话"
            type="number"
            placeholder="请填写电话"
          >
          </van-field>
          
          <van-field
            readonly
            label="物品图片"
            :placeholder="'最多上传'+maxCount+'张'"
          />
          <div class="upimg">
          <div class="img_list" v-for="(img,idx) in this.save_data.imgs">
            <van-image
              class="imgs"
              fit="contain"
              :src="img.url"
            />
            <van-icon class="delimg" @click="delImg(idx)" name="cross" />
          </div>
          <van-uploader :after-read="afterRead" v-model="files" v-if="save_data.imgs.length<maxCount" :preview-image="false" :before-read="beforeRead"/>
          <div style="clear: both;height: 1px;"></div>
          </div>
        </van-cell-group>

        <div style="text-align:center;margin-top: 10px;padding: 0 15px;margin-bottom: 20px;">
          <van-button type="primary" size="large" round @click="onSubmit">提交订单</van-button>
        </div>
    </van-pull-refresh>
  </div>
  </div>
</template>

<script>
import * as ajax from "@/api/common"
import util from '@/libs/util'
export default {
  name: 'YuyuePage',
  data(){
    return {
      ok:false,
      find:false,
      info:{
        title:"",
        address:"",
        id:0
      },
      save_data:{
        type:[],
        user_weight:'',
        phone:'',
        visit_time:'',
        address:'',
        area_code:'',
        imgs:[],
        desc:''
      },
      addressNow:false,
      isLoading:false,
      files:[],
      addressList:[],
      maxCount:3,
      minHour: 9,
      maxHour: 20,
      minDate: new Date(),
      maxDate: new Date(2030, 10, 1),
      currentDate: new Date(),
      showType:false,
      showDate:false,
      type_arr:['纸板','塑料','废旧金属','其他'],
      activeName:'tab1',
      list:[],
      old_list:[],
      loading:false,
      finished:false,
      old_finished:false,
      activeNames:1,
      userInfo:false,
      isLoading:false,
      page:0,
      old_page:0,
      page_size:10,
      showAddress:false,
      result:[],
      room_no:''
    }
  },
  mounted () {
    this.active = this.$route.query.active?parseInt(this.$route.query.active):0
    // this.getAddress()
    this.room_no = this.$route.params.room_no
    this.getRoomDetail(this.room_no)
  },
  watch:{
    $route(to,from){
      this.active=to.query.active
    },
  },
  methods:{
    getRoomDetail:function(room_no)
    {
      ajax.get("/hospital/detail",{table:'hospital','room_no':this.room_no}).then(res => {
        this.info = res
      }).catch(res=>{
        this.find= true
      });      
    },
    toggle(index) {
      this.$refs.checkboxes[index].toggle();
    },
    onSelect(val){
      this.showAddress=false
      this.addressNow=val
      this.save_data.phone=val.phone
      this.save_data.area_code=val.code
      this.save_data.address=val.address
    },
    onAdd(){
      //保存当前页面已填写的内容
      util.cookies.set('yuyueData',this.save_data)
      this.$router.push({
        path:'/my/addressList',
        query:{
          action:'from_yuyue'
        }
      })
    },
    // 返回布尔值
    beforeRead(file) {
      if (file.type !== 'image/jpeg' || file.type !== 'image/png') {
        this.$toast('请上传 jpg/png 格式图片');
        return false;
      }
      return true;
    },
    // 返回 Promise
    asyncBeforeRead(file) {
      return new Promise((resolve, reject) => {
        if (file.type !== 'image/jpeg' || file.type !== 'image/png') {
          this.$toast('请上传 jpg/png 格式图片');
          reject();
        } else {
          resolve();
        }
      });
    },
    getAddress(){
      let def = util.cookies.get('yuyueData')
      if(def){
        this.save_data=JSON.parse(def)
      }
      ajax.get('/Base/getYuYuePrepare',{table:'Address'}).then(async res => {
        this.addressList = res.addressList
        this.save_data.phone = res.userInfo.phone
        if(res.length==0){
          this.save_data.address=false
          return
        }
        // this.save_data.address=res[0]
      })
    },
    onClick(name,title){
      // if(name=='tab1'||name=='tab2'){
      //   this.onRefresh()
      // }
    },
    onLoadOld(){
      // 旧系统
      this.loading=true
      this.old_page+=1
      ajax.get('/Old/getYuYueList',{page:this.old_page,page_size:this.page_size}).then(res=>{
        this.loading=false
        if(parseInt(res.page)>=res.page_count){
          this.old_finished=true
        }
        res.list.map(item=>{
          this.old_list.push(item)
        })
      }).catch(e=>{
        console.log(e)
      })
    },
    onLoadNew(){
      //新系统
      this.loading=true
      this.page+=1
      ajax.getList({
        table:'BookOrder',
        scence:'mg_book_order_list',
        page:this.page,
        page_size:this.page.page_size,
        filter:this.filter
      }).then(res=>{
        this.loading=false
        if(parseInt(res.current_page)>=res.last_page){
          this.finished=true
        }
        res.data.map(item=>{
          this.list.push(item)
        })
      }).catch(e=>{
        console.log(e)
      })
    },
    onTypeConfirm(value, index) {
      this.save_data.type = value
      this.showType=false
    },
    onDateConfirm(value, index) {
      this.save_data.visit_time = this.formatDateTime(value)
      this.showDate=false
    },
    formatDateTime(inputTime) {
        var date = new Date(inputTime);
        var y = date.getFullYear();
        var m = date.getMonth() + 1;
        m = m < 10 ? ('0' + m) : m;
        var d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        var h = date.getHours();
        h = h < 10 ? ('0' + h) : h;
        var minute = date.getMinutes();
        var second = date.getSeconds();
        minute = minute < 10 ? ('0' + minute) : minute;
        second = second < 10 ? ('0' + second) : second;
        return y + '-' + m + '-' + d+' '+h+':'+minute+':'+second;
    },
    formatter(type, value) {
      if (type === 'year') {
        return `${value}年`;
      } else if (type === 'month') {
        return `${value}月`
      } else if (type === 'day') {
        return `${value}日`
      }
      return value;
    },
    beforeRead(file){
      if(file.length>this.maxCount){
        this.$toast('最多上传'+this.maxCount+'张')
        return false
      }
      return true
    },
    afterRead(files) {
      if(files.length==undefined){
        files=[files]
      }
      let formData = new FormData()
      for(let f of files){
        formData.append('file_data', f.file)
        ajax.post('/Upload/toSave',formData).then(res=>{
            this.save_data.imgs.push({
              name:res.savename,
              url:res.path,
            })
        }).catch(e=>{
          console.log(e)
        })
      }
    },
    delImg(idx){
      this.save_data.imgs.splice(idx,1)
    },
    onSubmit(){
      util.cookies.remove('yuyueData')
      this.save_data.showTips=true
      this.save_data.address = this.info.address
      this.save_data.user_id = this.info.id
      this.save_data.user_id = this.info.user_id
      this.save_data.name = this.info.title
      this.save_data.area_code = 1
      ajax.post('/hospital/createOrder',this.save_data).then(res=>{
        this.save_data={
        type:[],
        user_weight:'',
        phone:this.addressNow.phone,
        visit_time:'',
        address:this.addressNow.address,
        area_code:this.addressNow.code,
        imgs:[],
        desc:''
      }
      this.files=[]
      this.activeName='tab2'
      this.ok = true
      }).catch(error=>{
        this.$toast(error.msg)
      })
    },
    onRefresh() {
      if(this.activeName=='tab2'){
        this.page=0
        this.list=[]
        this.finished=false
        this.onLoadNew()
      }else if(this.activeName=='tab3'){
        this.old_page=0
        this.old_list=[]
        this.old_finished=false
        this.onLoadOld()
      }
      this.isLoading = false
    },
  }
}
</script>

<style scoped>
.van-panel__content{
  font-size:14px;
  line-height:24px;
  padding:15px;
}
.address{
  color: #969799;
  font-size: 12px;
}
.van-cell:not(:last-child)::after{left:0px!important;}
.upimg{padding:10px;}
.upimg .img_list{width:80px;height: 80px;float: left;position: relative;margin-right:6px;}
.upimg .img_list .imgs{border-radius: 5px;width:80px;height: 80px;}
.upimg .img_list .delimg{position: absolute;z-index: 2;right:5px;top:5px;font-size: 16px;}
</style>
