<template>
  <div class="mall">
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
      <van-tabs sticky v-model="activeKey" @click="onRefresh" v-if="hasLoad">
        <van-tab :title="type.title" v-for="(type,idx) in types">
          <div slot="title">
              <div class="a">{{type.title}}</div>
              <div class="b" v-if="type.status==1" style="color: #cc0000;">{{type.status_txt}}</div>
              <div class="b" v-else>{{type.status_txt}}</div>
          </div>
          <van-list
            v-model="loading"
            :finished="finished"
            :finished-text="finished_txt"
            @load="onLoad"
          >
          <van-card
            class="goodsList"
            v-for="(item,idx) in goodsList"
            :tag="item.status_txt"
            :price="item.price"
            :title="item.title"
            :desc="item.stitle"
            :thumb="item.path"
            :origin-price="item.market_price"
            @click="viewDetail(item.id)"
          >
            <div slot="tag">
              <template v-if="item.main_status==1">
                <van-tag mark type="danger" v-if="item.status==1">{{item.status_txt}}</van-tag>
                <van-tag mark v-else>{{item.status_txt}}</van-tag>
              </template>
              <template v-if="item.main_status==0">
                <van-tag type="success" mark>即将开始</van-tag>
              </template>
              <template v-if="item.main_status==2">
                <van-tag mark>已结束</van-tag>
              </template>
            </div>
            <div slot="tags">
              已抢购 <span style="color: #ff0000;">{{item.sale_num}}</span> 件
            </div>
            <span slot="price">
              秒杀价 ￥<span style="font-size: 1.5rem">{{item.price}}</span>
            </span>
            <div slot="footer">
              <template v-if="item.main_status==1">
                <template v-if="item.status==1">
                <van-button type="danger" size="mini">去抢购</van-button>
                </template>
                <template v-else>
                <van-button type="danger" disabled size="mini">去抢购</van-button>
                </template>
              </template>
              <template v-if="item.main_status==0">
                <van-button type="danger" disabled size="mini">去抢购</van-button>
              </template>
              <template v-if="item.main_status==2">
                <van-button type="danger" disabled size="mini">去抢购</van-button>
              </template>
            </div>
          </van-card>
          </van-list>
        </van-tab>
      </van-tabs>
      <div style="color: #666;line-height: 80px;" v-else><center>请稍后~</center></div>
  </van-pull-refresh>
  </div>
</template>

<script>
import * as ajax from "@/api/common"
export default {
  name: 'MallPage',
  data(){
    return {
      types:[],
      activeKey:-1,
      loading:false,
      searchPage:false,
      isLoading:false,
      finished:false,
      hasLoad:false,
      goodsList:[],
      finished_txt:'没有更多了',
      now_type:false,
      page:{
        page_total:0,
        current_page:0,
        count:0,
        page_size:10
      },
      filter:{
        sec_id:0,
        status:1,
        keyword:''
      }
    }
  },
  mounted () {
    // this.getSecList()
  },
  methods:{
    goBack(){
      this.$router.go(-1)
    },
    getSecList(nid){
      ajax.get('/Seckill/getSecList',{}).then(res=>{
        this.types = res.list
        this.hasLoad=true
        this.types.map((item,idx)=>{
          if(item.status==1){
            this.activeKey=idx
          }
        })
        // this.isLoading=false
        // this.onLoad()
      })
    },
    viewDetail(val){
      this.$router.push({
        path:'/seckill/detail',
        query:{
          id:val
        }
      })
    },
    onLoad(){
      this.loading = true
      let sec_status=0
      let tips=''
      this.types.map((item,idx)=>{
        if(idx==this.activeKey){
          this.filter.sec_id=item.id
          sec_status = item.status
          tips = item.tips
          this.now_type = item
        }
      })
      let p= parseInt(this.page.current_page)+1
      ajax.getList({
        table:'Seckill',
        scence:'mg_seckill_list',
        page:p,
        page_size:this.page.page_size,
        filter:this.filter
      }).then(res=>{
        this.loading = false
        this.page.page_total = res.last_page
        this.page.current_page = res.current_page
        this.page.count = res.total
        for (var i = 0; i <res.data.length; i++) {
          this.goodsList.push(res.data[i])
        }
        if(parseInt(res.last_page)>0){
          this.finished_txt='没有更多了~'
        }else{
          if(this.searchPage){
            this.finished_txt='建议更搜索关键词，再试试~'
          }
        }
        if(parseInt(res.last_page)<= p){
          this.finished = true;
        }
      })
    },
    onRefresh() {
      this.goodsList=[]
      this.page={
        page_total:0,
        current_page:0,
        count:0,
        page_size:10
      }
      this.finished = false;
      this.onLoad()
      this.isLoading = false
    }
    
  }
}
</script>

<style scoped>
.van-tab .a{line-height:22px!important;font-size: 16px!important;}
.van-tab .b{line-height:14px!important;font-size:0.6rem!important;}
.goodsList{
  background: #fff;
  margin-bottom: 10px;
}
.item{height:220px;padding:0px 10px;background:#fff;}
.item .info{}
.item .price{color:#ff0000;font-weight:200;}
.item .price b{font-size:16px;font-weight:200;}
.item .price_del{color:#999;text-decoration: line-through;font-weight:200;font-size:12px;}
</style>
