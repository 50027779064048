import request from '@/plugins/axios'
export function getUserInfo(data) {
    let showLoading={
        type: 'loading',
        options: {
            fullscreen: true,
            lock: true,
            text: '加载中...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.8)'
        }
    }
    if(data==undefined||!data.showLoading ||data.showLoading==undefined){
        showLoading=false
    }
    return request({
        url: '/User/getUserInfo',
        method: 'get',
        params:data,
        permission:false,
        loading: showLoading,
        success:false,
        fail: {
            type: 'toast',
            options: {
                title:'',
                message: '',
                type: 'fail'
            }
        }
    })
}
//获取分页列表
export function getList(data) {
    const url = data.url?data.url:'/Base/'+data.table+'/getList';
    let def_permission='p_'+data.table+'_list'
    def_permission=false
    let showLoading={
        type: 'loading',
        options: {
            fullscreen: true,
            lock: true,
            text: '加载中...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.8)'
        }
    }
    if(!data.showLoading){
        showLoading=false
    }
    return request({
        url: url,
        method: 'get',
        params:data,
        permission:data.permission?data.permission:def_permission,
        loading:showLoading,
    })
}
//创建记录
export function addRecord(data) {
    const url = data.url?data.url:'/Base/'+data.table+'/addRecord';
    let def_permission='p_'+data.table+'_add'
    def_permission=false

    var tips={
            type: 'toast',
            options: {
                message: '',
                type: 'success'
            }
        }
    if(!data.showTips){
        tips=false
    }

    return request({
        url: url,
        method: 'post',
        data,
        permission:data.permission?data.permission:def_permission,
        loading: {
            type: 'loading',
            options: {
                fullscreen: true,
                lock: true,
                text: '请稍后...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.8)'
            }
        },
        success:tips
    })
}
//获取详情
export function getDetail(data) {
    const url = data.url?data.url:'/Base/'+data.table+'/detail';
    const def_permission='p_'+data.table+'_detail'
    let showLoading={
        type: 'loading',
        options: {
            fullscreen: true,
            lock: true,
            text: '加载中...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.8)'
        }
    }
    if(!data.showLoading){
        showLoading=false
    }
    return request({
        url: url,
        method: 'get',
        params:data,
        permission:data.permission?data.permission:def_permission,
        loading:showLoading
    })
}
//更改状态1、0
export function changeStatus(data){
    const url = data.url?data.url:'/Base/'+data.table+'/changeStatus';
    const def_permission='p_'+data.table+'_changeSt'
    return request({
        url: url,
        method: 'get',
        params:data,
        permission:data.permission?data.permission:def_permission,
        success: {
            type: 'message',
            options: {
                message: '操作成功',
                type: 'success'
            }
        }
    })
}
//删除
export function toDel(data) {
    const def_permission='p_'+data.table+'_delete'
    return request({
        url: '/Base/'+data.table+'/delete',
        method: 'get',
        params:data,
        permission:data.permission?data.permission:def_permission,
        loading: {
            type: 'loading',
            options: {
                fullscreen: true,
                lock: true,
                text: '加载中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.8)'
            }
        }
    })
}
//登陆
export function Login(data) {
    return request({
        url: '/User/login',
        method: 'post',
        data,
        loading: {
            type: 'loading',
            options: {
                fullscreen: true,
                lock: true,
                text: '加载中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.8)'
            }
        },
        success: {
            type: 'toast',
            options: {
                title:'',
                message: '',
                type: 'success'
            }
        },
        fail: {
            type: 'toast',
            options: {
                title:'',
                message: '',
                type: 'fail'
            }
        }
    })
}
//退出
export function Logout(data) {
    return request({
        url: '/User/logout',
        method: 'get',
        params:data,
        loading: {
            type: 'loading',
            options: {
                fullscreen: true,
                lock: true,
                text: '加载中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.8)'
            }
        },
        success: {
            type: 'toast',
            options: {
                title:'',
                message: '操作成功',
                type: 'success'
            }
        },
        fail: {
            type: 'toast',
            options: {
                title:'',
                message: '',
                type: 'fail'
            }
        }
    })
}
//获取模型筛选数组
export function getFilterArr(data) {
    return request({
        url: '/Base/'+data.table+'/getFilterArr',
        method: 'get',
        params:data
    })
}
export function get(url,data) {
    var tips={
            type: 'toast',
            options: {
                title:'',
                message: '操作成功',
                type: 'success'
            }
        }
    if(!data.showTips){
        tips=false
    }
    let showLoading={
        type: 'loading',
        options: {
            fullscreen: true,
            lock: true,
            text: '加载中...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.8)'
        }
    }
    if(!data.showLoading){
        showLoading=false
    }
    return request({
        url: url,
        method: 'get',
        params:data,
        permission:data.permission?data.permission:false,
        loading: showLoading,
        success: tips,
        fail: {
            type: 'toast',
            options: {
                title:'',
                message: '',
                type: 'fail'
            }
        }
    })
}
export function post(url,data) {
    var tips={
            type: 'toast',
            options: {
                message: '',
                type: 'success'
            }
        }
    if(!data.showTips){
        tips=false
    }
    let showLoading={
        type: 'loading',
        options: {
            fullscreen: true,
            lock: true,
            text: '加载中...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.8)'
        }
    }
    if(data==undefined||!data.showLoading ||data.showLoading==undefined){
        showLoading=false
    }
    return request({
        url: url,
        method: 'post',
        data:data,
        permission:data.permission?data.permission:false,
        loading:showLoading,
        success: tips,
    })
}
export function upload(url,data) {
    return request({
        url: url,
        method: 'post',
        data:data,
        permission:false,
        loading: {
            type: 'loading',
            options: {
                fullscreen: true,
                lock: true,
                text: '上传中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.8)'
            }
        },
        success: {
            type: 'toast',
            options: {
                title:'',
                message: '上传成功',
                type: 'success'
            }
        },
        fail: {
            type: 'toast',
            options: {
                title:'',
                message: '',
                type: 'fail'
            }
        }
    })
}
