import Vue from 'vue'
import VueRouter from 'vue-router'
import util from '@/libs/util'
// 路由数据
import routes from './routers'
import {
  getUserInfo
} from "@/api/common";
import store from '@/store'
import wechat from "@/libs/wechat"
Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
/**
 * 路由拦截
 * 权限验证
 */
//免校验token白名单
let whiteList = ['/login', '/wechat']
let FRONT_BASE = window.location.host
if(FRONT_BASE == 'wx.lelehuanbao.com')
{
  FRONT_BASE = 'https://' + FRONT_BASE
}else{
  FRONT_BASE = 'http://' + FRONT_BASE
}
let isWeiXin = () => {
  return navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1
}
let isIos = () => {
  var u = navigator.userAgent;
  var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
  return isiOS
}
let needSignature = false

router.beforeEach(async (to, from, next) => {
  let token = util.cookies.get('token')
  const loginToken = to.query.token
  //授权登陆回来
  if ((loginToken && loginToken !== 'undefined')) {
    util.cookies.set('token', loginToken)
    token = loginToken
    getUserInfo().then(async res => {
        util.cookies.set('uuid', res.id)
        util.cookies.set('token', res.token)
        store.dispatch('lele/user/setUserInfo', res)
        needSignature = true
      })
      .catch(err => {
        needSignature = false
        console.log(err)
      })
  }
  // if (to.path.indexOf('off') != -1) {
  //   return next()
  // }
  if (to.path.indexOf('hospital') != -1) {
    return next()
  }
  if (to.path.indexOf('exam') != -1) {
    return next()
  }
  if (whiteList.indexOf(to.path) === -1) {
    // 这里暂时将cookie里是否存有token作为验证是否登录的条件
    if (token && token !== 'undefined') {
      needSignature = true
      next()
    } else {
      // 将当前预计打开的页面完整地址临时存储 登录后继续跳转
      // 这个 cookie(redirect) 会在登录后自动删除
      // util.cookies.set('redirect',window.location.href)
      util.cookies.set('redirect', `${FRONT_BASE}${to.fullPath}`)
      //待补充屏幕扫码登陆箱体，特殊处理
      needSignature = false
      // 没有登录的时候跳转到登录界面
      next({
        name: 'wechat'
      })
    }
  } else {
    needSignature = true
    next()
  }
})
router.afterEach(to => {
  util.title(to.meta.title)
  if (isWeiXin() && needSignature == true) {
    if (isIos()) {
      if (window.wechaturl == '' || window.wechaturl == undefined) {
        window.wechaturl = `${FRONT_BASE}${to.fullPath}` // 将后面的参数去除
        if (!window.hasSignature || window.hasSignature == undefined) {
          wechat.getsignature(window.wechaturl, function (res) {}, function (error) {})
          window.hasSignature = true
        }
      }
    } else {
      setTimeout(function () {
        //安卓 只对这些页面签名
        if (to.fullPath != '/recycle/box' || to.fullPath != '/my/index' || to.fullPath == '/my')
          window.wechaturl = `${FRONT_BASE}${to.fullPath}`
        wechat.getsignature(window.wechaturl, function (res) {}, function (error) {})
        window.hasSignature = true
      }, 400)
    }
  }
  // store.dispatch('lele/user/setUrl', window.wechaturl).then(u=>{})
})
export default router