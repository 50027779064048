<template>
  <div>
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
    <div class="user" v-if="userInfo">

      <van-row style='display: flex;justify-content: space-between;align-items: center;'>
        <van-col offset="2">
          <van-image
            round
            width="5rem"
            height="5rem"
            :class="{'vip-head':userInfo.is_vip}"
            :src="userInfo.headimgurl"
            @dblclick.native="singout()"
          />
        </van-col>
        <van-col span="14">
          <div :style="{color: userInfo.is_vip?'#FFD700':'#fff'}" class="flex"><img :src="require('../../assets/vip2.png')" style="width: 1.8em !important" v-if='userInfo.is_vip'>
            <span>{{userInfo.name}} [ {{userInfo.vip_name}} ]</span></div>
            <div v-if='userInfo.vip_start'>
          <div v-if='userInfo.is_vip'><span style="font-size: 0.7rem">到期日期：{{userInfo.vip_end}}</span></div>

          <div v-else><span style="font-size: 1rem" @click='showVipDetail'>立即试用VIP服务?</span></div>
</div>
          <div style="margin-top: 5px;">
            <span style="margin-right: 4px;" v-if="userInfo.phone">{{userInfo.phone_hide}}</span>
            <van-tag type="success" v-if="userInfo.phone" plain style="margin-right:8px"  @click="bindPhone">更换</van-tag>
            <van-tag type="success" v-else plain style="margin-right:8px" @click="bindPhone">绑定手机</van-tag>
          </div>
          <div style="text-align:left;" v-if="showWithdrawal">
            积分 {{userInfo.family.score}}
            <van-tag type="success" plain style="margin-right:8px" @click="withdrawal" v-if="showWithdrawal">提现</van-tag>
          </div>
        </van-col>
      </van-row>

    </div>
    <div>
      <van-cell-group title="商城订单">
        <van-grid :column-num="5">
          <van-grid-item
              :icon="mallOrder.icon"
              :text="mallOrder.title"
              
              v-for="(mallOrder,idx) in userInfo.mall_order"
              v-if="idx<4"
              @click="goUrl2('/my/mallOrder',mallOrder.status)"
            />
          <van-grid-item
              icon="completed"
              text="全部订单"
              @click="goUrl('/my/mallOrder')"
            />
        </van-grid>
      </van-cell-group>
      <van-cell-group title="预约回收二维码" v-if="userInfo.room_qrcode" @click="showQrCode">
        <van-cell title="预约回收二维码" icon="chart-trending-o " value="查看"/>
      </van-cell-group>
      <van-cell-group title="配送统计" v-if="userInfo.area_manager" @click="showQrCode">
        <van-cell title="配送统计" icon="chart-trending-o " value="查看" is-link to="/area/info" />
      </van-cell-group>

      <van-cell-group title="采购统计" v-if="userInfo.caigou" @click="showQrCode">
        <van-cell title="采购统计" icon="chart-trending-o " value="查看" is-link to="/caigou/info" />
      </van-cell-group>
      <van-cell-group title="提货点" v-if="userInfo.points && !userInfo.is_mall_admin">
        <van-cell title="用户取件" icon="scan" value="取件码取件" is-link @click="putOut()"/>
        <van-cell title="提货点配送单" icon="orders-o" value="查看" is-link to="/points/myinfo"/>
      </van-cell-group>

      <div v-if="userInfo.is_mall_admin">
        <van-cell-group title="商城管理员">
          <!-- <van-cell title="入库/查询" icon="scan" value="扫描包裹条码" is-link @click="scanQRCode(1)"/> -->
          <van-cell title="用户取件" icon="scan" value="取件码取件" is-link @click="putOut()"/>
          <!-- <van-cell title="异常订单" icon="bulb-o" value="退换货处理" is-link to="/points/abnormal"/> -->
          <van-cell class='emphasize' title="提货点配送单" icon="orders-o" value="查看" is-link to="/points/info"/>
          <van-cell class='emphasize' title="用户订单" icon="orders-o" value="查看" is-link to="/kuaidi/info"/>
          <van-cell title="商城基础统计" icon="chart-trending-o " value="进入" is-link to="/stat/info"/>
          <van-cell title="线下回收汇总" icon="chart-trending-o " value="进入" is-link to="/points/huishou_sum"/>
          <van-cell title="线下回收详情" icon="chart-trending-o " value="进入" is-link to="/points/huishou"/>
        </van-cell-group>
      </div>
      <!-- 商城管理员或者提货点人员都能看到 线下回收统计 -->
      <van-cell-group title="线下回收详情" v-if="userInfo.points && !userInfo.is_mall_admin">
          <van-cell title="线下回收详情" icon="chart-trending-o " value="进入" is-link to="/points/huishou"/>
      </van-cell-group>


      <van-cell-group title="可回收订单" v-if="showRecycle">
        <van-grid :column-num="5">
          <van-grid-item
              icon="send-gift-o"
              text="待清运"
              info='1'
            />
          <van-grid-item
              icon="send-gift-o"
              text="待分拣"
            />
          <van-grid-item
              icon="todo-list-o"
              text="待复核"
            />
          <van-grid-item
              icon="balance-list-o"
              text="待结算"
              info='5'
            />
          <van-grid-item
              icon="completed"
              text="全部订单"
              @click="goUrl('/my/recycleOrder')"
            />
        </van-grid>
      </van-cell-group>
      <van-cell-group title="其他">
        <van-cell title="我的积分" icon="gold-coin-o" v-if="userInfo.score_info" :value="userInfo.score_info.score" is-link to="/my/scoreList"/>
        <van-cell title="我的金币" icon="gold-coin-o" v-if="userInfo.score_info" :value="userInfo.score_info.coin" is-link to="/my/coinList"/>
        <van-cell title="投递记录" icon="orders-o" @click="checkHasNew" />
        <van-cell title="我的家庭组" icon="friends-o" :value="userInfo.family?userInfo.family.member_count+'人':'去添加'" is-link to="/my/family"/>
        <van-cell title="收货地址" icon="location-o" :value="userInfo.address_count" is-link to="/my/addressList"/>
        <!-- <van-cell title="IC卡管理" icon="idcard" value="已绑定3张" is-link/> -->
        <van-cell title="意见反馈" icon="question-o" value="" is-link to="/feedBack"/>
        <van-cell title="注销登陆" icon="close" value="测试" v-if="!in_wx" is-link @click="singout"/>
      </van-cell-group>
      <van-cell-group title="工作人员权限" v-if="userInfo.worker">
        <van-cell title="工单处理" value="处理用户的反馈内容" is-link to="/feedBackAdm" v-if="userInfo.worker.permission.work_order.length>0"/>
      </van-cell-group>
    </div>
    </van-pull-refresh>
  
<van-popup v-model="qrShow" style="width:80%;text-align: center;">
<van-panel title="我的专属预约回收二维码">
  <span style="color: red">温馨提示：长按可保存到相册</span>
  <!-- <van-button @click="download" type="primary">保存到手机</van-button> -->
<!-- <qriously level="H" :value="userInfo.room_qrcode" :size="220" id="qrcode"/> -->
<img :src="imgUrl">
</van-panel>
</van-popup>
  <div class="showWay" v-if="showOrderType" @click="showOrderType = false">
    <div class="show_list" @click="goNewOrder">
      <div class="show_list_con">
        <van-icon name="balance-list-o" /><br/>
        最新投递
      </div>
    </div>
    <div class="show_list" @click="goOldOrder">
      <div class="show_list_con">
        <van-icon name="clock-o" /><br/>
        历史投递
      </div>
    </div>
  </div>

<van-action-sheet v-model="showVip" title="乐乐一家VIP" style='height: 85%;background: #F7F8FA'>
  <div class="content">
<van-tabs v-model="active" color='#FFD700' :animated='true'>
  <van-tab title="关于VIP">
<div style="padding-top: 10px;">
  <div class="about-item"><div v-html='aboutVip'></div>
<van-button type="primary" block round style='margin: 20px 0px;' plain color="#FFD700" @click='kaitong'>我已知晓，立即开通</van-button>
  </div>
</div>
  </van-tab>
  <van-tab title="套餐" v-if='read'>
<div style="padding-top: 10px;">
  <van-radio-group v-model="radio" checked-color='#FFD700'>
    <div class="vip-item" v-for="(item,key) in vipList" :key='key'>
        <div class="left vip-item-content">
          <div class="van-coupon__amount">{{item.price}}<span>元</span></div>
          <div class="van-coupon__condition">尽享乐乐{{item.desc}}VIP服务</div>
        </div>
        <div class="two vip-item-content">
          <div class="van-coupon__name">[ {{item.desc}} ] VIP尊贵服务</div>
          <div class="van-coupon__valid">{{item.start}} - {{item.end}}</div>
        </div>
        <div class="three vip-item-content">
          <van-radio :name="key"></van-radio>
        </div>
    </div>
</van-radio-group>
<div style="padding:15px 12px;"><van-button type="primary" round plain color="#FFD700" block @click="startPay">选好了，立即支付</van-button></div>

</div>

</van-tab>


</van-tabs>
  </div>
</van-action-sheet>

  </div>
</template>

<script>
import * as ajax from "@/api/common"
import util from '@/libs/util'
import wechat from '@/libs/wechat'
import { mapGetters,mapActions } from 'vuex'
import store from '@/store'
export default {
  name: 'MyPage',
  data() {
    return {
      active:0,
      showOrderType:false,
      has_new:false,
      url:'',
      url2:'',
      activeNames:1,
      userInfo:false,
      isLoading:false,
      showRecycle:false,
      showWithdrawal:false,
      canCheckWorkOrder:false,
      test:'',
      in_wx:false,
      qrShow:false,
      imgUrl:'',
      showVip:false,
      radio:null,
      vipList:[],
      aboutVip:'',
      read:false
    }
  },
  mounted () {
    let needPull = util.cookies.get('needPull')
    if(needPull){
      util.cookies.remove('needPull')
      this.getUserInfoF5()
    }else{
      this.getUserInfo()
    }
    this.in_wx = this.isWeiXin()
    util.cookies.remove('yuyueData')
    this.has_new = this.$store.state.lele.user.hasNew
    if(this.$route.query.from == 1)
    {
      this.showVipDetail()
    }
  },
  methods:{
    ...mapActions('lele/account', [
      'logout',
    ]),
    isWeiXin(){ 
      return navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1
    },
    showQrCode(){
      this.qrShow=!this.qrShow

    },
    kaitong(){
      this.read=true
      this.active = 1
    },
    showVipDetail(){
      ajax.get('/base/getVipList',{showLoading:true}).then(res=>{
        this.vipList=res.list
        this.showVip = !this.showVip
        this.aboutVip = res.aboutVip
      }).catch(err=>console.log(err))
    },
    putOut(){
      this.$router.push({
        path:'/points/putOut'
      })
    },  
    // 扫一扫
    scanQRCode(val) {
      let _this=this
      const toast1 = this.$toast.loading({
        mask: true,
        message: '请稍后...'
      })
      wx.scanQRCode({
        needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
        scanType: ["qrCode","barCode"], // 可以指定扫二维码还是一维码，默认二者都有, "barCode"
        success:  res=> {
          toast1.clear()
            _this.$toast.success('扫码成功')
          if(val==1){
            //入库
            _this.$router.push({
              path:'/points/putIn',
              query:{
                del_no:res.resultStr
              }
            })
          }else if(val==2){
            //取件
            _this.$router.push({
              path:'/points/putOut',
              query:{
                del_no:res.resultStr
              }
            })
          }else if(val==3){
            //查询
            _this.$router.push({
              path:'/points/putOut',
              query:{
                del_no:res.resultStr
              }
            })
          }
          
        },
        fail: err=>{
          _this.$toast({message: err.errMsg , duration: 1500});
        }
      })
    },
    withdrawal(){
      this.$router.push('withdrawal')
    },
    download(){
      // window.location.href=""
    },
    bindPhone(){
      this.$router.push('bindPhone')
    },
    getUserInfoF5(){
      ajax.getUserInfo({showLoading:false}).then(async res => {
        util.cookies.set('uuid', res.id)
        util.cookies.set('token', res.token)
        this.$store.dispatch('lele/user/setUserInfo',res)
        this.userInfo = res
        if(this.userInfo.worker && this.userInfo.worker.permission.work_order.length>0){
          this.canCheckWorkOrder=true
        }
      })
    },
    getUserInfo(){
      let info = this.$store.dispatch('lele/user/load').then(res=>{
        this.userInfo=res
        // this.imgUrl = 'http://qr.topscan.com/api.php?text='+this.userInfo.room_qrcode+'&logo=http://lelehuanbao.com/template/images/logo.png'
        if(this.userInfo.worker && this.userInfo.worker.permission.work_order.length>0){
          this.canCheckWorkOrder=true
        }
      }).catch(e=>{
        this.userInfo=false
      })
    },
    singout(){
      this.logout({confirm:true})
    },
    onRefresh() {
      this.getUserInfoF5()
      this.isLoading = false;
      this.showVip=false
    },
    goUrl(url) {
      this.$router.push(url)
    },
    goUrl2(url,st) {
      this.$router.push({path:url,query:{status:st}})
    },
    checkHasNew() {
	  this.goNewOrder();
      // if (this.has_new) {
      //   this.showOrderType = true;
      // } else {
      //   this.showOrderType = false;
      //   //直接访问旧的订单列表
      //   this.goOldOrder();
      // }
    },
    goOldOrder(){
      this.$router.push('/my/recycleOrder')
    },
    goNewOrder(){
      this.$router.push('/my/recycleNewOrder')
    },
    wexinPay(data, cb, errorCb,cancelCb) {
      var _this=this
        //获取后台传入的数据
        let appId = data.appId;
        let timestamp = data.timeStamp;
        let nonceStr = data.nonceStr;
        let signature = data.signature;
        let packages = data.package;
        let paySign = data.paySign;
        let signType = data.signType;
        //下面要发起微信支付了
        wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: appId, // 必填，公众号的唯一标识
            timestamp: timestamp, // 必填，生成签名的时间戳
            nonceStr: nonceStr, // 必填，生成签名的随机串
            signature: signature, // 必填，签名，见附录1
            jsApiList: ['chooseWXPay'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
        });
        wx.ready(function () {
          wx.chooseWXPay({
            timestamp: timestamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
            nonceStr: nonceStr, // 支付签名随机串，不长于 32 位
            package: packages, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=***）
            signType: signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
            paySign: paySign, // 支付签名
            success: function (res) {
                // 支付成功后的回调函数
                cb(res);
            },
            cancel: function (res) {
              cancelCb(res)
            },
            fail: function (res) {
                //失败回调函数
                errorCb(res);
            }
          });
        });
        wx.error(function (res) {
          _this.$toast('调起支付失败')
        })
    },
    payAction(use_coin){
        let _this  = this
        let vip_id = this.vipList[this.radio]['id']
        let is_exp = this.vipList[this.radio]['is_exp']
        ajax.post('/pay/vipPay',{vip_id,use_coin}).then(res=>{
          console.log('then',res)
            if(res === true){
              return this.onRefresh()
            }
            const data = JSON.parse(res)
            
            this.wexinPay(data,function(){
              _this.$toast('升级成功')
              _this.onRefresh()
            },function(r){
              _this.$toast('支付失败')
              _this.$router.push({
                path:'/my/index'
              })
            },function(r){
              _this.$toast('您放弃了支付')
            })
        }).catch(err=>{
          console.log('err',err)
          if(err.code==1001)
          {
            this.$notify({ type: 'danger', message: err.msg});
          }else{
            this.$notify({ type: 'danger', message: '网络错误，请稍后再试！' });
          }
        })
    },
    startPay(){
      if(this.radio === null || typeof this.radio == 'null')
      {
        return this.$notify({ type: 'danger', message: '您还没选择套餐' });
      }
      let price = this.vipList[this.radio]['price']
      let desc = this.vipList[this.radio]['desc']
      let string = '您当前订购的是 [ '+desc+' ] 的乐乐一家VIP服务，当前优惠价 '+price+' 元，确认吗？'
      this.$dialog.confirm({
        title: '确认VIP套餐！',
        message: string,
        theme: 'round-button',
      }).then(() => {

        let coin = this.userInfo.score_info.coin

        if(coin>0 && !this.vipList[this.radio]['is_exp']){
            this.$dialog.confirm({
              title: '使用金币？',
              message: '发现您有 '+coin+' 个金币，要使用金币吗？',
              theme: 'default-button',
              confirmButtonText:'不使用金币',
              cancelButtonText:'使用金币'
            })
            .then(() => {
              this.payAction(false)
            }).catch(()=>{
              this.payAction(true)
            })
        }else{
          this.payAction(false)
        }
      })
      .catch(() => {
        this.$toast('您已经取消，请重新选择套餐')
      });
    }
  }
}
</script>

<style scoped>
.user{background:#07c160;padding:20px 0;color:#fff;}
.swipe img{width:100%;}
.showWay{position: fixed;z-index: 100;left: 0px;right:0px;bottom: 0px;top: 0px;background-color: rgba(0,0,0,.8);padding:0 40px;}
  .show_list{width:50%;height:auto;text-align:center;float:left;margin-top:160px;}
  .show_list_con{width:100px;height:100px;text-align:center;background-color:#fff;color:#333;margin:0 auto;border-radius:8px;}
  .show_list_con i{font-size:40px;line-height:40px;margin-top:20px;}
.news_list{background-color:#fff;padding:10px;border-bottom:1px solid #f3f3f3;position:relative;font-size:14px;}
.news_list .news_info{position:absolute;left:80px;right:10px;top:10px;bottom:10px;font-size:14px;color:#333;}
.news_time{position:absolute;left:80px;bottom:10px;color:#999;}
.about-item{
    margin: 0 12px 12px;
    overflow: hidden;
    background-color: #fff;
    border-radius: 8px;
    -webkit-box-shadow: 0 0 10px rgba(0,0,0,0.1);
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    padding: 5px 5px;
    text-indent: 2em;
    line-height: 1.5;
}
.vip-item-content
{
  text-align: center;
}
.van-coupon__condition{
  color: #FFD700;
}
.vip-head{
  border:2px solid #FFD700;box-sizing:border-box;
}
.vip-item{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0 12px 12px;
    overflow: hidden;
    background-color: #fff;
    border-radius: 8px;
    -webkit-box-shadow: 0 0 10px rgba(0,0,0,0.1);
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    justify-content: space-around;
    align-items: center;
    padding: 20px 5px;
}
.flex{
  display: flex;display: --webkit-flex;
  align-items: center;
}
.emphasize span
{
  color: red;
  font-weight: bold;
}
</style>
