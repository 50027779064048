<template>
  <div style="margin-top:20%;">
      <van-loading size="24px" vertical>登陆中...</van-loading>
  </div>
</template>

<script>
import util from '@/libs/util'
export default {
  name: 'WechatPage',
  data(){
    return {
      notInWx:true
    }
  },
  mounted () {
    this.init()
  },
  methods:{
    init () {
      let forward = util.cookies.get('redirect')
      let token = util.cookies.get('token')

      if (token && token !== 'undefined') {
        if(forward){
          this.$router.push(forward)
        }else{
          this.$router.push('/')
        }
        return
      }
  // return alert(token)
      let publicPath
      console.log(process.env)
      if(process.env.VUE_APP_CURRENTMODE=='production'){
        publicPath='http://wechat.lelehuanbao.com'
      }else if(process.env.VUE_APP_CURRENTMODE=='dev'){
        publicPath='http://wechat.demo.lelehuanbao.com'
      }else{
        publicPath='http://wechat.demo.lelehuanbao.com'
      }
      console.log(publicPath)
      window.location.href=publicPath+"/Wx/getAuth?forward="+forward
    }
  }
}
</script>

<style scoped>
.login{margin:10px 0;}
.login_bt{margin:10px 0;width:90%;}
</style>
