<template>
  <div>
    <van-nav-bar :title="'我关注的箱体'"
      left-text="返回"
      left-arrow
      sticky
      @click-left="$router.go(-1)"
    />
    <van-panel v-for="item in markers" :title="item.b_name" style="margin-bottom:20px!important;">
      <div class="orderCon">
        <div v-if="item.b_addressnote">位置：{{item.b_addressnote}}</div>
        <div >状态：
          <b style="color:red;" v-if="  get_data_ts-item.lasttime_real>600  ">维护中</b>
          <b style="color:red;" v-else-if=" item.b_state>0 || item.b_error>0  ">已满</b>
          <span style="color:green;" v-else>未满 {{parsePercent(item.weight)}}% </span>
        </div>
      </div>
      <div slot="footer">
        <van-button size="small" @click="cancle(item.b_id)">取消关注</van-button>
        <van-button size="small" style="margin-left: 10px;" type="primary" @click="gourl(item)">导航</van-button>
      </div>
    </van-panel>
    <div class="nodata" v-if="markers.length<1"><center>暂无关注的箱体哟~</center></div>
  </div>
</template>

<script>
  import * as ajax from "@/api/common"
  import wx from 'weixin-js-sdk';
  import { mapGetters,mapActions } from 'vuex'
  import {Toast} from 'vant';
  export default {
    name:'myBoxPage',
    data() {
      return {
        show:false,
        box_list:[],
        markers:[]
      }
    },
    mounted() {
      this.getBoxes();
    },
    methods: {
      ...mapGetters('lele/user', [
        'getWebUrl',
      ]),
      cancle(b_id){
        this.$dialog.confirm({
          title: '提示',
          message: '确定要取消关注吗'
        }).then(() => {
          ajax.get('/Old/addBox',{b_id:b_id,show_tips:true}).then(res=>{
            this.$toast('已取消关注')
            this.markers=[]
            this.getBoxes()
          })
        }).catch(() => {
          return
        })
      },
      parsePercent(weight){
          let percent = parseInt(weight/40*100);
          percent = percent > 95 ? 95 : percent;
          return percent;
        },
      gourl(item){
        console.log(item)
        let query={
          latitude: parseFloat(item.position[1]), // 纬度，浮点数，范围为90 ~ -90
          longitude: parseFloat(item.position[0]), // 经度，浮点数，范围为180 ~ -180。
          name: item.b_name,// 位置名
          address: item.b_addressnote, // 地址详情说明
          scale: 15, // 地图缩放级别,整形值,范围从1~28。默认为最大
          infoUrl: '' // 在查看位置界面底部显示的超链接,可点击跳转
        }
        wx.openLocation(query);
      },
      getBoxes(){
        //获取箱体列表
        ajax.get('/Old/getMyBox',{}).then(res => {
          this.box_list=res
          let tmp = [];
          let Things = res;
          this.boxes = res;
          this.get_data_ts = Math.round( new Date() / 1000 );
          let windows = [];
          for (var i = 0; i < Things.length; i++) {
            var cur = Things[i];
            if ( cur.b_lng && cur.b_lat ) {
              tmp.push({
                position:[cur.b_lng,cur.b_lat],
                b_id:cur.b_id,
                b_name:cur.b_name,
                b_addressnote:cur.b_addressnote,
                weight:cur.weight,
                b_state:cur.b_state,
                b_error:cur.b_error,
                lasttime_real:cur.lasttime_real,
                showdetial:false
              });
            }
          }
          this.markers = tmp;
        })
      },
    }
    
  }
</script>
<style>
.van-panel__content{
  font-size:14px;
  line-height:24px;
  padding:15px;
}
.nodata{line-height: 3rem;padding:20px;text-align: center;color: #666;font-size: 14px;}
.van-cell:not(:last-child)::after{left:0px!important;}
</style>
