<template>
  <div>
    <van-nav-bar
      title="商城订单"
      left-text="返回"
      left-arrow
      style="display:none"
      @click-left="goBack"
    />
  <van-dropdown-menu>
  <van-dropdown-item v-model="area_id" :options="points" @change="chooseItem"/>
</van-dropdown-menu>
<!-- 信息区域 -->
<div class="info-area van-panel__content" style="background: #fff;font-size: 12px">
  <div><van-tag type="success">姓名</van-tag> <span class="address">{{currentInfo.name}}</span></div>
  <div><van-tag type="success">手机</van-tag> <span class="address">{{currentInfo.phone}}</span></div>
</div>
<!-- 信息区域 -->
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
      <van-tabs sticky v-model="active" @click="onRefresh">
        <van-tab :title="type.title" :name="type.caigou_status" v-for="(type,idx) in status_arr" :info="type.count">
          <van-list
            v-if="idx==active"
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <van-panel v-for="item in list" :title="item.caigou_txt" style="margin-bottom:20px!important;font-size:12px;">
              <div><van-tag type="danger">{{item.area_title}}</van-tag></div>
              <div class="orderCon">
                {{item.goods_title}}<br/>{{item.sku_name}} * {{item.total_num}}
              </div>
              <div slot="footer" style="height: 30px;">
                  创建时间 {{item.create_time}}
                <van-button class="bt" size="small" type="primary" v-if="item.caigou_status ==1 || item.caigou_status ==2" @click='caigou(item)'>设置为 {{show_status(item.caigou_status)}}</van-button>
              </div>
            </van-panel>
          </van-list>
        </van-tab>
      </van-tabs>
    </van-pull-refresh>
    <van-popup v-model="infoVis">
    <van-steps direction="vertical" :active="logAct">
      <van-step v-for="item in logInfo">
        【{{item.type_txt}}】{{item.content}}<br/>
        <span v-if="item.user_info">By {{item.user_info.name}}<br/></span>
        {{item.create_time}}
      </van-step>
    </van-steps>
    </van-popup>
    
  </div>
</template>

<script>
import * as ajax from "@/api/common"
import util from '@/libs/util'
export default {
  name: 'infoPage',
  // props:[points],
  data() {
    return {
      area_id: 0,
      old_point:true,
      points: [],
      filter:{
      },
      page:{
        current_page:1,
        page_total:1,
        count:0,
        page_size:50,
      },
      list:[],
      logInfo:[],
      active:0,
      logAct:0,
      loading:false,
      finished:false,
      activeNames:1,
      userInfo:false,
      isLoading:false,
      infoVis:false,
      tmpArr:[],
      status_arr:[
        {
        caigou_status:0,
        title:"全部"
        }
      ],
      searchValue:'',
      currentInfo:{
      }
    }
  },
  mounted () {
    this.tmpArr=this.status_arr.filter((el, index) => {
      el.list=[]
      return el
    })
    this.active=0
    this.getOptionsAndStatus()
  },
  watch:
  {
    point_id(old,now){
      this.old_point = false
    }
  },
  methods:{
    getTitle(caigou_status)
    {
      let item = this.status_arr.filter(item=>{
        return item.caigou_status == (caigou_status + 1)
      })
      return item[0].title
    },
    show_status(caigou_status){
      return this.getTitle(caigou_status)
    },
    chooseItem(index){
      let thePoint = this.points.filter((v,k)=>{
        return v.value == index
      })
      this.currentInfo = thePoint[0]
      this.point_id = index
      this.onLoad()
    },
    goBack(){
      this.$router.go(-1)
    },
    viewOrder(row){
     this.$router.push({
      path:'/points/putIn',
      query:{
        del_no:row.del_no,
        act:2
      }
     })
    },
    caigou(item){
      this.$dialog.confirm({
        title: '确认',
        message: '确认 '+item.goods_title+' 设置为 "'+this.getTitle(item.caigou_status)+'"？'
      }).then(() => {
        let to_caigoustatus = item.caigou_status + 1
        ajax.post('/caigou/changeCaigouSumStatus',{area_id:item.area_id,to_caigoustatus,caigou_status:item.caigou_status,goods_id:item.goods_id,sku:item.sku}).then(res=>{
          let _this = this
          this.onRefresh()
        })
      }).catch(() => {
        this.$toast('放弃')
      });
    },
    getOptionsAndStatus(){
      this.loading = true
      ajax.get('/caigou/getOptionsAndStatus',{
        table:'DeliverySum',
        model:'caigou'
      }).then(res=>{
        this.loading = false
        this.points = res.area
        this.status_arr = this.status_arr.concat(res.tabs)
        this.currentInfo = res.area[0]
        this.onLoad()
      }).catch(e=>{
        this.$toast(e.msg)
        if(e.code==999){
          let _this=this
          setTimeout(a=>{
            this.$router.go(-1)
            return
          },1500)
        }
        this.loading = false
      })
    },
    makeList(){
      this.list = this.tmpArr[this.active].list
    },
    onLoad(){
      this.filter.caigou_status=this.active
      this.filter.del_no = this.searchValue
      this.filter.area_id = this.point_id
      this.loading = true
      this.page.current_page=parseInt(this.page.current_page) + 1
      ajax.getList({
        table:'caigouSum',
        scence:'mg_caigoulist',
        // page:this.page.current_page,
        filter:this.filter
      }).then(res=>{
        this.loading = false
        // this.page.page_total = 50
        // this.page.current_page = 1
        // this.page.count = res.total

        // for (var i = 0; i <res.data.length; i++) {
          
        //   if(this.old_point){
        //     this.tmpArr[this.active].list.push(res.data[i])
        //   }else{
        //     this.tmpArr[this.active].list = res.data.data
        //             this.old_point = true
        //   }
        // }
        this.tmpArr[this.active].list = res.data
        this.makeList()

        if(parseInt(res.last_page)<=parseInt(res.current_page)){
          this.finished = true;
        }
      }).catch(e=>{
        this.$toast(e.msg)
        if(e.code==999){
          let _this=this
          setTimeout(a=>{
            this.$router.go(-1)
            return
          },1500)
        }
        this.loading = false
        this.finished = true;
      })
    },
    onRefresh() {
      this.list=[]
      this.tmpArr=[]
      this.tmpArr=this.status_arr.filter((el, index) => {
        el.list=[]
        return el
      })
      this.isLoading = false;
      this.page.current_page = 0
      this.loading = true;
      this.finished = false;
      this.onLoad()
      this.$toast('刷新成功')
    },
    onSearch(val) {
      this.onRefresh()
    },
    onCancel() {
      this.onRefresh()
    },
  }
}
</script>

<style scoped>
.goods{padding:5px 0;}
.goods_title{
  font-size:12px;color:#000;display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.bt{
  float: right;
}
.van-panel__content{
  padding:10px 16px!important;
}
.van-panel__header-value>span{
    background: #07C160;
    color: #fff;
    padding: 3px;
    font-size: 12px;
    border-radius: 2px;
}
.van-cell__title span{
  color: red
}
</style>