<template>
  <div>
    <van-sticky>
    <van-nav-bar
      title="提现"
      left-text="返回"
      right-text="提现记录"
      left-arrow
      @click-left="goBack"
      @click-right="goList"
    />
    </van-sticky>
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
      <template v-if="userInfo">
      <van-cell-group style="height:100%;">
        <van-field
          v-model="userInfo.score_info.coin"
          clearable
          label="金币余额"
          disabled
          placeholder=""
        />
        <van-field
          v-model="userInfo.score_info.withdraw_coin"
          clearable
          label="可提现金额"
          disabled
          placeholder=""
        />
        <van-field
          label="提现金币"
          clickable
          disabled
          :show="show"
          :value="coin"
          :placeholder="place"
          @touchstart.stop="show = true"
        />
        <van-number-keyboard
          v-model="coin"
          :show="show"
          close-button-text="完成"
          @hide="checkScore"
          @blur="show = false"
        />
        <div style="text-align:center;padding:0 20px;margin-top:10px;"><van-button type="primary" round size="large"  :disabled="disabled" @click="toDo">确定提现</van-button></div>
        <div style="color: #666;font-size:14px;line-height: 25px;font-weight: 300;padding: 10px 20px;padding-bottom: 0px;">提现说明</div>
        <div class="tixian_rules" v-html="userInfo.withdraw_info.rules"></div>
      </van-cell-group>
      </template>
      <template v-else>
        <p><center>请稍后~</center></p>
      </template>
    </van-pull-refresh>
  </div>
</template>

<script>
import * as ajax from "@/api/common"
import util from '@/libs/util'
export default {
  name: 'withdrawalPage',
  data() {
    return {
      list:[],
      show:false,
      loading:false,
      finished:false,
      activeNames:1,
      userInfo:false,
      coin:'',
      isLoading:false,
      place:'',
      disabled:false
    }
  },
  mounted () {
    this.getUserInfo()
  },
  methods:{
    goBack(){
      this.$router.go(-1)
    },
    goList(){
      this.$router.push('/my/withdrawal_log')
    },
    toDo(){
      if(this.disabled)
      {
        return this.$toast('不要重复点击')
      }
      this.disabled=true
      if(parseInt(this.userInfo.withdraw_info.status) == 0){
        this.$toast('提现功能没有开启')
        return
      }
      if(this.checkScore()){
        let _this=this
        ajax.post('/score/withdraw',{pay:this.coin,showTips:true}).then(res=>{
          setTimeout(function(){
            _this.$router.push('/my/withdrawal_log')
          },1000)
        }).catch(e=>{
          this.$toast(e.msg)
        })
      }else{
        return
      }
    },
    checkScore(){
      let coin = parseInt(this.coin)
      let rules = this.userInfo.withdraw_info
      if(this.userInfo.score_info.withdraw_coin<parseInt(rules.min_pay)){
        this.$toast('金币数量低于最低提现额度')
        this.coin=''
        return false
      }
      if(coin>this.userInfo.score_info.withdraw_coin){
        this.$toast('提现金币不得超过账户余额')
        this.coin=''
        return false
      }
      if(coin<parseInt(rules.min_pay)){
        this.$toast('提现金额不得小于'+rules.min_pay)
        this.coin=''
        return false
      }
      if(coin>parseInt(rules.max_pay)){
        this.$toast('单次提现金额不得大于'+rules.max_pay)
        this.coin=''
        return false
      }
      return true
    },
    getUserInfo(){
      ajax.getUserInfo().then(async res => {
        util.cookies.set('uuid', res.id)
        util.cookies.set('token', res.token)
        this.$store.dispatch('lele/user/setUserInfo',res)
        this.userInfo = res
        this.place ='最低金额'+res.withdraw_info.min_pay
      })
    },
    onRefresh() {
      this.isLoading = false;
      this.loading = true;
      this.getUserInfo()
    }
  }
}
</script>

<style scoped>
.tinfo{color:#fff;}
.scoreList{border-bottom:1px solid #f3f3f3;background:#fff;padding:10px;}
.scoreList .number{font-size:20px;text-align:right;color:#ff6600;}
.tixian_rules{margin:10px 20px;line-height:1.5rem;min-height:300px;}
</style>
