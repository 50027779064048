<template>
  <div>
    <van-nav-bar
      title="我的积分"
      left-text="返回"
      right-text="提现"
      style="display:none;"
      left-arrow
      @click-left="goBack"
      @click-right="withdrawal"
      v-if="canWithdrawal"
    />
    <van-nav-bar
      title="我的积分"
      left-text="返回"
      style="display:none;"
      left-arrow
      @click-left="goBack"
      v-else
    />
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
      <van-sticky v-if="userInfo">
        <van-cell style="background:#07c160;border-bottom:1px solid #f3f3f3;">
            <van-row class="tinfo">
              <van-col span="24" style="font-size: 16px;">
                总积分 {{userInfo.score_info.score}}<br/>
                <small style="font-size:13px;" v-if="userInfo.score_info.clear>0">其中 {{userInfo.score_info.clear}} 积分于年底清零</small>
              </van-col>
            </van-row>
        </van-cell>
      </van-sticky>
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
      <van-row class="scoreList" v-for="(item,idx) in list" :key="idx">
        <van-col span="18" style="font-size: 12px;">
          <div style="height: 30px;line-height: 30px;color: #333;font-size: 14px;">{{item.remark}}</div>
          <span v-if="item.member_id>0">{{item.user_name}}</span><span v-else>系统</span>-{{item.create_time}}
        </van-col>
        <van-col span="6">
          <div class="number" style="line-height: 50px;">
            <span v-if='item.tag_str==1' class="blue">-{{item.score}}</span><span v-else class="red">+{{item.score}}</span>
          </div>
        </van-col>
      </van-row>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import * as ajax from "@/api/common"
import util from '@/libs/util'
export default {
  name: 'scoreListPage',
  data() {
    return {
      list:[],
      loading:false,
      finished:false,
      activeNames:1,
      userInfo:false,
      isLoading:false,
      canWithdrawal:false,
      filter:{

      },
      page:{
        current_page:0,
        page_total:1,
        count:0,
        page_size:10,
      },
    }
  },
  mounted () {
    this.getUserInfo()
  },
  methods:{
    goBack(){
      this.$router.go(-1)
    },
    withdrawal(){
      this.$router.push('withdrawal')
    },
    getUserInfo(){
      ajax.getUserInfo().then(async res => {
        util.cookies.set('uuid', res.id)
        util.cookies.set('token', res.token)
        this.$store.dispatch('lele/user/setUserInfo',res)
        this.userInfo = res
      })
    },
    onLoad(){
      this.page.current_page=parseInt(this.page.current_page) + 1
      ajax.getList({
        table:'ScoreList',
        scence:'mg_scorelist',
        page:this.page.current_page,
        page_size:this.page.page_size,
        filter:this.filter
      }).then(res=>{
        res.data.map(item=>{
          this.list.push(item)
        })
        if(parseInt(res.current_page)>=res.last_page){
          this.finished=true
        }
        this.loading=false
      }).catch(e=>{
        this.$toast(e.msg)
      })
    },
    onRefresh() {
      this.list=[]
      this.page={
        current_page:0,
        page_total:1,
        count:0,
        page_size:10,
      }
      this.isLoading = false;
      this.loading = true;
      this.finished = false;
      this.onLoad()
    }
  }
}
</script>

<style scoped>
.tinfo{color:#fff;}
.scoreList{border-bottom:1px solid #f3f3f3;background:#fff;padding:10px;}
.scoreList .number{font-size:20px;text-align:right;}
.red{
  color:#F73131
}
.blue{
  color: #3ECBAD;
}
</style>
