import {Toast} from 'vant'

let loading = {
}
let lastRequest = new Date('2018');
let ob=null

loading.show = function (config) {
    if (config && config.loading) {
        let now = new Date();
        let ms = now - lastRequest;
        lastRequest = now;
        if (ms > config.loading.interval || 2000) {//默认相隔超过两秒的请求才重新显示loading
            if (config.loading.type === "loading") {
                ob = Toast.loading({
                message: '加载中...',
                mask:true,
                forbidClick: true,
                duration:100000
              });
            } else if (config.loading.type === "nprogress") {

            }
        }
    }
}

loading.hide = function (config) {
    if (config && config.loading) {
        if (config.loading.type === "loading" && ob) {
            ob.clear()
        } else if (config.loading.type === "nprogress") {
            // NProgress.done()
        }
    }
}

export default loading;