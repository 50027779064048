<template>
  <div>
    <van-sticky>
    <van-nav-bar
      title="提现记录"
      left-text="返回"
      sticky
      left-arrow
      @click-left="goBack"
    />
  </van-sticky>
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
      <van-panel v-for="item in list" :title="item.wx_partner_trade_no" :status="item.status_txt" style="margin-bottom:20px!important;">
              <div class="orderCon">
                <div>提现金额：￥{{item.pay}}</div>
                <div>提交时间: {{item.create_time}}</div>
                <div v-if="item.status==2">原因：{{item.reason}}（{{item.act_time}}）</div>
                <div v-if="item.status==4">原因：{{item.wx_text}}</div>
                <div v-if="item.status==3||item.status==9">处理时间: {{item.act_time}}</div>
                <template v-if="item.status==9">
                  <div>流水号: {{item.wx_payment_no}}</div>
                  <div>结算时间: {{item.wx_payment_time}}</div>
                </template>
              </div>
              <div slot="footer" v-if="item.status==9">
                结算渠道: 微信零钱
              </div>
              <div slot="footer" v-if="item.status==4">
                不要着急，系统会自行处理~
              </div>
            </van-panel>
            
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import * as ajax from "@/api/common"
import util from '@/libs/util'
export default {
  name: 'withdrawalLogPage',
  data() {
    return {
      list:[],
      loading:false,
      finished:false,
      activeNames:1,
      userInfo:false,
      isLoading:false,
      filter:{

      },
      page:{
        current_page:0,
        page_total:1,
        count:0,
        page_size:10,
      },
    }
  },
  mounted () {
  },
  methods:{
    goBack(){
      this.$router.go(-1)
    },
    onLoad(){
      this.loading = true
      this.page.current_page=parseInt(this.page.current_page) + 1
      ajax.getList({
        table:'withdrawLog',
        scence:'mg_withdrawLog',
        page:this.page.current_page,
        page_size:this.page.page_size,
        filter:this.filter
      }).then(res=>{
        this.loading=false
        res.data.map(item=>{
          this.list.push(item)
        })
        if(parseInt(res.current_page)>=res.last_page){
          this.finished=true
        }
      }).catch(e=>{
        this.loading=false
        this.finished=true
        this.$toast(e.msg)
      })
    },
    onRefresh() {
      this.list=[]
      this.page={
        current_page:0,
        page_total:1,
        count:0,
        page_size:10,
      }
      this.isLoading = false;
      this.loading = true;
      this.finished = false;
      this.onLoad()
    }
  }
}
</script>

<style scoped>
.tinfo{color:#fff;}
.orderCon{padding:10px 20px;line-height: 20px;font-size: 12px;}
.coinlist{border-bottom:1px solid #f3f3f3;background:#fff;padding:10px;}
.coinlist .number{font-size:20px;text-align:right;color:#ff6600;}
</style>
